import React, { ReactNode } from 'react';
import { CellProps } from 'react-datasheet-grid';

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx';
import { cn } from '@/lib/utils.ts';

interface CustomCellProps<T> extends CellProps<T, unknown> {
    columnKey: string;
    options?: Array<{
        value: string;
        label: string;
    }>;
}

interface SelectorComponentProps<RowType> extends CustomCellProps<RowType> {
    rowData: RowType;
    setRowData: (rowData: RowType) => void;
}

const SelectorComponent = <RowType,>({
    rowData,
    setRowData,
    options,
    columnKey,
}: SelectorComponentProps<RowType>) => {
    const handleChange = (value: string) => {
        setRowData({ ...rowData, [columnKey]: value });
    };

    return (
        <Select
            value={rowData[columnKey] || ''}
            onValueChange={handleChange}
            name={columnKey}>
            <SelectTrigger
                className={cn(
                    'size-full flex-1 self-stretch border-none focus:ring-0 focus:ring-offset-0',
                    {
                        'italic text-newDesign-text-secondary':
                            !rowData[columnKey] || rowData[columnKey] === '',
                    },
                )}>
                <SelectValue placeholder='Select...' />
            </SelectTrigger>
            <SelectContent>
                {options.map(({ value, label }) => (
                    <SelectItem key={value} value={value}>
                        {label}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
};

const MemoizedSelectorComponent = React.memo(SelectorComponent) as <RowType>(
    props: SelectorComponentProps<RowType>,
) => ReactNode;

export default MemoizedSelectorComponent;
