import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { CpeSpecs } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { createCloneQueryParams, getDefaultSpecs } from './adapters.ts';

type CloneCpeParams = {
    modelId: string;
    type: 'model' | 'library';
    names: string[];
};

const keyMap = {
    name: 'cpeName',
};

const cloneCpe = async ({
    modelId,
    type,
    names,
}: CloneCpeParams): Promise<void> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/copy/cpe`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const dataArray = Array.isArray(names) ? names : [names];

    const mappedData = dataArray.map(getDefaultSpecs);

    const payload = createCloneQueryParams<CpeSpecs>(keyMap, mappedData);

    await AxiosAPIv2.axiosInstance.post(url, payload, { headers });
};

type UseCloneCpeOptions = {
    mutationConfig?: MutationConfig<typeof cloneCpe>;
};

export const useCloneCpe = ({ mutationConfig }: UseCloneCpeOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: cloneCpe,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
