import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ServerCashflow } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { serverCashFlowAdapter } from '../adapters.ts';

type EditServerCashFlowParams = {
    modelId: string;
    type: 'model' | 'library';
    prevData: ServerCashflow;
    nextData: ServerCashflow;
};

const editServerCashFlow = async ({
    modelId,
    type,
    prevData,
    nextData,
}: EditServerCashFlowParams): Promise<ServerCashflow> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/servercfconfig`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const params = {
        datacenter: prevData.datacenter,
        servername: prevData.serverName,
        flowname: prevData.flowName,
        model_id: modelId,
    };

    const config: AxiosRequestConfig = {
        headers,
        params,
    };

    const payload = serverCashFlowAdapter(nextData);

    await AxiosAPIv2.axiosInstance.patch(url, payload, config);

    return nextData;
};

type UseEditServerCashFlowOptions = {
    mutationConfig?: MutationConfig<typeof editServerCashFlow>;
};

export const useEditServerCashFlow = ({
    mutationConfig,
}: UseEditServerCashFlowOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: editServerCashFlow,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
