import React from 'react';

import { useCurrency } from '@bae/store';
import { Loading } from '@bae/ui';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table';

import { FinancialStatementData } from './types';
import { formatStatementCurrency, getRowTotal } from './utils';

const getValueColorLocal = (value: number, positive: boolean): string => {
    return positive
        ? value >= 0
            ? 'text-newDesign-primary'
            : 'text-newDesign-indicatorsLine'
        : value >= 0
          ? 'text-newDesign-indicatorsLine'
          : 'text-newDesign-primary';
};

const getIndentationClass = (indent: number): string => {
    switch (indent) {
        case 1:
            return 'pl-4';
        case 2:
            return 'pl-6';
        default:
            return 'pl-2';
    }
};

interface TableFinancialStatementProps {
    data: FinancialStatementData;
    loading: boolean;
}

const TableFinancialStatement = ({
    data,
    loading,
}: TableFinancialStatementProps) => {
    const { currency } = useCurrency();

    const columns = [
        {
            key: 'name',
            header: 'Periods',
            renderCell: (row: any) => (
                <div
                    className={`${
                        row.bold ? 'font-semibold' : 'font-normal'
                    } ${getIndentationClass(row.indent)} text-left`}>
                    {row.name}
                </div>
            ),
        },
        ...data.header.map((header, index) => ({
            key: header,
            header: header,
            renderCell: (row: any) => {
                const value = row.data[index];
                return (
                    <div
                        className={`${getValueColorLocal(value, row.positive)} text-right`}>
                        {formatStatementCurrency(value, row.positive, currency)}
                    </div>
                );
            },
        })),
        {
            key: 'total',
            header: 'Total',
            renderCell: (row: any) => {
                const total = getRowTotal(row.data);
                return (
                    <div
                        className={`font-semibold ${getValueColorLocal(total, row.positive)} text-right`}>
                        {formatStatementCurrency(total, row.positive, currency)}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            {loading ? (
                <Loading height={400} size={80} />
            ) : (
                <Table className='min-w-full'>
                    <TableHeader>
                        <TableRow>
                            {columns.map((column) => (
                                <TableHead
                                    key={column.key}
                                    className={`text-nowrap bg-newDesign-background text-sm font-medium text-newDesign-text-primary ${
                                        column.header === 'Periods'
                                            ? 'text-left'
                                            : 'text-right'
                                    }`}>
                                    {column.header}
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {data.body.map((row, rowIndex) => (
                            <TableRow
                                key={rowIndex}
                                className={`border-b ${
                                    row.highlight
                                        ? 'border-newDesign-border border-t'
                                        : 'border-white'
                                }`}>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.key}
                                        className={`bg-white py-1 pr-4 text-sm text-newDesign-text-primary ${
                                            column.key === 'name'
                                                ? getIndentationClass(
                                                      row.indent,
                                                  )
                                                : ''
                                        }`}>
                                        {column.renderCell(row)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </>
    );
};

export default TableFinancialStatement;
