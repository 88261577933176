import { useCurrency } from '@bae/store';
import { formatCurrencySymbol } from '@bae/utils';

import { Skeleton } from '@/components/ui/skeleton.tsx';

import { TableBodyRow, TableHeadRow } from '../../shared/TableComponents';
import {
    FloatNumberInputCell,
    TextInputCell,
    SelectCell,
} from '../../shared/TableComponents/CellComponents';
import { Expense, EXPENSE_TYPE, RECURRING } from '../../shared/types.ts';
import { getMetalExpenses, useCloudRebalancingStore } from '../store';

const getExpenseLabels = (currencySign: string = '$') => ({
    expenseName: { label: 'Expense', isEditable: true },
    recurring: { label: 'Recurring', isEditable: true },
    type: { label: 'Type', isEditable: true },
    value: { label: `Value (${currencySign})`, isEditable: true },
});

const MetalExpenses = () => {
    const { currency } = useCurrency();

    const metalExpenses = useCloudRebalancingStore(getMetalExpenses);
    const updateExpense = useCloudRebalancingStore(
        (state) => state.updateMetalExpense,
    );
    const loading = metalExpenses.length === 0;

    const currencySign = formatCurrencySymbol({
        currency,
        onlySymbol: true,
    });
    const expenseLabels = getExpenseLabels(currencySign);

    return (
        <div className='w-full overflow-x-auto'>
            <table className='w-full table-fixed border-separate border-spacing-2 text-sm'>
                <thead>
                    {loading ? (
                        <SkeletonHeader />
                    ) : (
                        <TableHeadRow>
                            {Object.keys(expenseLabels).map((key) => (
                                <th key={key} className='text-center'>
                                    <p className='whitespace-nowrap'>
                                        {
                                            expenseLabels[key as keyof Expense]
                                                .label
                                        }
                                    </p>
                                </th>
                            ))}
                        </TableHeadRow>
                    )}
                </thead>
                <tbody>
                    {loading ? (
                        <SkeletonRow />
                    ) : (
                        metalExpenses.map((expense, index) => {
                            const isServerExpense = index === 1 || index === 2;

                            return (
                                <TableBodyRow key={index}>
                                    <TextInputCell<Expense>
                                        value={expense.expenseName}
                                        isEditable={!isServerExpense}
                                        index={index}
                                        accessorKey={'expenseName'}
                                        onChange={updateExpense}
                                    />

                                    <SelectCell<Expense>
                                        value={expense.recurring}
                                        options={Object.values(RECURRING)}
                                        onChange={updateExpense}
                                        index={index}
                                        accessorKey={'recurring'}
                                    />
                                    <SelectCell<Expense>
                                        value={expense.type}
                                        options={Object.values(EXPENSE_TYPE)}
                                        onChange={updateExpense}
                                        index={index}
                                        accessorKey={'type'}
                                    />
                                    <FloatNumberInputCell<Expense>
                                        value={expense.value}
                                        index={index}
                                        accessorKey={'value'}
                                        onChange={updateExpense}
                                    />
                                </TableBodyRow>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
};

const SkeletonHeader = () => {
    return (
        <TableHeadRow>
            <th>Expense Name</th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
        </TableHeadRow>
    );
};

const SkeletonRow = () => {
    const arr = Array.from({ length: 4 }, (_, i) => i);
    return (
        <TableBodyRow>
            <td>
                <Skeleton />
            </td>
            {arr.map((col) => (
                <td key={col}>
                    <Skeleton />
                </td>
            ))}
        </TableBodyRow>
    );
};

export default MetalExpenses;
