import {
    COUNTRY_OPTIONS,
    AWS_REGION_OPTIONS,
    AZURE_ZONE_OPTIONS,
    GF_SERVICE_INTERCONNECTION_OPTIONS,
    PORT_TYPE_OPTIONS,
    VPN_TYPE_OPTIONS,
    GCP_REGION_OPTIONS,
    SERVICE_DISCOUNT_OPTIONS,
} from './constants.ts';
import type {
    IAwsCloudPortInput,
    IAzureCloudPortInput,
    IDiscounts,
    IFTE,
    IGcpCloudPortInput,
    INetworkConfigurationData,
    INetworkSiteInput,
} from './types.ts';
import { faker } from '@faker-js/faker';

export function getNetworkConfigurationMockData(): INetworkConfigurationData {
    return {
        networkSiteInput: Array.from(
            { length: 10 },
            createRandomNetworkSiteInput,
        ),
        awsCloudPortInput: Array.from(
            { length: 10 },
            createRandomAwsCloudPortInput,
        ),
        azureCloudPortInput: Array.from(
            { length: 10 },
            createRandomAzureCloudPortInput,
        ),
        gcpCloudPortInput: Array.from(
            { length: 10 },
            createRandomGcpCloudPortInput,
        ),
        discounts: Array.from({ length: 9 }, createRandomDiscounts),
        fte: Array.from({ length: 3 }, createRandomFte),
    };
}

function createRandomNetworkSiteInput(): INetworkSiteInput {
    return {
        id: faker.string.uuid(),
        siteName: faker.location.city(),
        quantity: faker.number.int({ min: 1, max: 100 }),
        portType: faker.helpers.arrayElement(
            PORT_TYPE_OPTIONS.map((type) => type.value),
        ),
        redundantAccess: faker.datatype.boolean() ? 'true' : 'false',
        country: faker.helpers.arrayElement(
            COUNTRY_OPTIONS.map((country) => country.value),
        ),
        accessCircuitMRC: Number(faker.finance.amount()),
        vpnType: faker.helpers.arrayElement(
            VPN_TYPE_OPTIONS.map((type) => type.value),
        ),
        vpnBWMbs: faker.number.float({ min: 1, max: 10000, fractionDigits: 2 }),
        vpnBWAnnualGrowthRate: faker.number.float({
            min: 0,
            max: 1,
            fractionDigits: 4,
        }),
        internetBWMbps: faker.number.float({
            min: 1,
            max: 10000,
            fractionDigits: 2,
        }),
        internetBWAnnualGrowthRate: faker.number.float({
            min: 0,
            max: 1,
            fractionDigits: 4,
        }),
        elanBWMbps: faker.number.float({
            min: 1,
            max: 10000,
            fractionDigits: 2,
        }),
        elanBWAnnualGrowthRate: faker.number.float({
            min: 0,
            max: 1,
            fractionDigits: 4,
        }),
        cpeExpense: Number(faker.finance.amount()),
    };
}

function createRandomAwsCloudPortInput(): IAwsCloudPortInput {
    return {
        id: faker.string.uuid(),
        siteName: faker.location.city(),
        dataEgressGBPerMonth: faker.number.float({
            min: 1,
            max: 10000,
            fractionDigits: 2,
        }),
        egressGrowthRate: faker.number.float({
            min: 0,
            max: 1,
            fractionDigits: 4,
        }),
        portType: faker.helpers.arrayElement(
            PORT_TYPE_OPTIONS.map((type) => type.value),
        ),
        portRedundant: faker.datatype.boolean() ? 'true' : 'false',
        country: faker.helpers.arrayElement(
            COUNTRY_OPTIONS.map((country) => country.value),
        ),
        gfServiceInterconnection: faker.helpers.arrayElement(
            GF_SERVICE_INTERCONNECTION_OPTIONS.map((type) => type.value),
        ),
        awsRegion: faker.helpers.arrayElement(
            AWS_REGION_OPTIONS.map((region) => region.value),
        ),
    };
}

function createRandomAzureCloudPortInput(): IAzureCloudPortInput {
    return {
        id: faker.string.uuid(),
        siteName: faker.location.city(),
        dataEgressGBPerMonth: faker.number.float({
            min: 1,
            max: 10000,
            fractionDigits: 2,
        }),
        egressGrowthRate: faker.number.float({
            min: 0,
            max: 1,
            fractionDigits: 4,
        }),
        portType: faker.helpers.arrayElement(
            PORT_TYPE_OPTIONS.map((type) => type.value),
        ),
        portRedundant: faker.datatype.boolean() ? 'true' : 'false',
        country: faker.helpers.arrayElement(
            COUNTRY_OPTIONS.map((country) => country.value),
        ),
        azureZone: faker.helpers.arrayElement(
            AZURE_ZONE_OPTIONS.map((zone) => zone.value),
        ),
        gfServiceInterconnection: faker.helpers.arrayElement(
            GF_SERVICE_INTERCONNECTION_OPTIONS.map((service) => service.value),
        ),
    };
}

function createRandomGcpCloudPortInput(): IGcpCloudPortInput {
    return {
        id: faker.string.uuid(),
        siteName: faker.location.city(),
        dataEgressGBPerMonth: faker.number.float({
            min: 1,
            max: 10000,
            fractionDigits: 2,
        }),
        egressGrowthRate: faker.number.float({
            min: 0,
            max: 1,
            fractionDigits: 4,
        }),
        portType: faker.helpers.arrayElement(
            PORT_TYPE_OPTIONS.map((type) => type.value),
        ),
        portRedundant: faker.datatype.boolean() ? 'true' : 'false',
        country: faker.helpers.arrayElement(
            COUNTRY_OPTIONS.map((country) => country.value),
        ),
        egressRegion: faker.helpers.arrayElement(
            GCP_REGION_OPTIONS.map((region) => region.value),
        ),
        gfServiceInterconnection: faker.helpers.arrayElement(
            GF_SERVICE_INTERCONNECTION_OPTIONS.map((service) => service.value),
        ),
    };
}

function createRandomDiscounts(): IDiscounts {
    return {
        id: faker.string.uuid(),
        service: faker.helpers.arrayElement(
            SERVICE_DISCOUNT_OPTIONS.map((service) => service.value),
        ),
        discount: faker.number.float({ min: 0, max: 1, fractionDigits: 4 }),
    };
}

function createRandomFte(): IFTE {
    return {
        id: faker.string.uuid(),
        fteName: faker.finance.accountName(),
        laborRate: Number(faker.finance.amount()),
        annualSalaryGrowth: faker.number.float({
            min: 0,
            max: 1,
            fractionDigits: 4,
        }),
        numberOfFTEs: faker.number.float({
            min: 1,
            max: 50,
            fractionDigits: 2,
        }),
    };
}
