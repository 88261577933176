import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx';

const SelectCell = <T,>({
    value,
    onChange,
    options,
    accessorKey,
    index,
}: {
    value: string;
    accessorKey: keyof T;
    index: number;
    options: string[];
    onChange: <K extends keyof T>(index: number, key: K, value: T[K]) => void;
}) => {
    const handleInputChange = (option: string) => {
        onChange(index, accessorKey, option as T[keyof T]);
    };

    return (
        <td className='h-[36px] rounded-md border border-newDesign-divider bg-white px-2 text-center'>
            <Select onValueChange={handleInputChange} defaultValue={value}>
                <SelectTrigger className=' h-6 w-full rounded-sm border-none p-0 focus:ring-offset-4 [&>span]:flex-1 [&>span]:text-center'>
                    <SelectValue placeholder={value} />
                </SelectTrigger>
                <SelectContent>
                    {options.map((option, idx) => (
                        <SelectItem key={idx} value={option}>
                            {option}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </td>
    );
};

export default SelectCell;
