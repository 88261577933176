import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { IScenarioData, scenarioData } from '@/features/scenario/mock-data.ts';

const getScenarios = async (): Promise<IScenarioData[]> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(scenarioData);
        }, 500);
    });
};

export const getScenariosQueryOptions = () => {
    return queryOptions({
        queryKey: ['scenarios'],
        queryFn: () => getScenarios(),
    });
};

type UseScenariosOptions = {
    queryConfig?: QueryConfig<typeof getScenarios>;
    refetchInterval?: number | false;
};

export const useScenarios = ({
    queryConfig,
    refetchInterval,
}: UseScenariosOptions) => {
    return useQuery({
        ...getScenariosQueryOptions(),
        ...queryConfig,
        refetchInterval,
    });
};
