import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { Server } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { createCloneQueryParams, getServerDefaultSpecs } from './adapters.ts';

type CloneServerParams = {
    modelId: string;
    type: 'model' | 'library';
    names: string | string[];
};

const keyMap = {
    name: 'serverName',
};

const cloneServer = async ({
    modelId,
    type,
    names,
}: CloneServerParams): Promise<void> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/copy/server`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const dataArray = Array.isArray(names) ? names : [names];

    const mappedData = dataArray.map(getServerDefaultSpecs);

    const payload = createCloneQueryParams<Server>(keyMap, mappedData);

    await AxiosAPIv2.axiosInstance.post(url, payload, { headers });
};

type UseCloneServerOptions = {
    mutationConfig?: MutationConfig<typeof cloneServer>;
};

export const useCloneServer = ({
    mutationConfig,
}: UseCloneServerOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: cloneServer,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
