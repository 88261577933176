import {
    ColumnDef,
    Table as TableDef,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
    SortingState,
    RowSelectionState,
    getSortedRowModel,
    getFilteredRowModel,
    ColumnFiltersState,
} from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa6';
import { HiMiniMagnifyingGlass } from 'react-icons/hi2';
import { IoIosClose } from 'react-icons/io';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { LuPlus } from 'react-icons/lu';
import { toast } from 'react-toastify';

import { Library } from '@bae/data-interface';

import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog.tsx';
import { Input } from '@/components/ui/input.tsx';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table.tsx';
import { useLibraries } from '@/features/library/api/get-libraries.ts';
import { cn } from '@/lib/utils.ts';
import DialogAddLibrary from '@/libs/modules/library-management/src/lib/DialogAddLibrary.tsx';

import LibraryManagementActions from './LibraryManagementActions.tsx';

const SkeletonRows = ({ length = 10 }: { length?: number }) => {
    return Array.from({ length }, (_, index) => (
        <TableRow key={index}>
            <TableCell>
                <Skeleton className='h-5 w-full bg-newDesign-divider' />
            </TableCell>
            <TableCell>
                <Skeleton className='h-5 w-full bg-newDesign-divider' />
            </TableCell>
            <TableCell>
                <Skeleton className='h-5 w-full bg-newDesign-divider' />
            </TableCell>
            <TableCell>
                <Skeleton className='h-5 w-full bg-newDesign-divider' />
            </TableCell>
            <TableCell>
                <Skeleton className='h-5 w-full bg-newDesign-divider' />
            </TableCell>
        </TableRow>
    ));
};

const DescriptionDialog = ({ description }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    return (
        <div className='ml-7'>
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogTrigger asChild>
                    <Button
                        variant='text'
                        size='sm'
                        withIcon
                        className='aspect-square px-0'
                        onClick={handleOpen}>
                        <IoDocumentTextOutline className='size-4 text-newDesign-primary' />
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Description</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>{description}</DialogDescription>
                    <DialogFooter>
                        <Button variant='outline' onClick={handleClose}>
                            Close
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const libraryTableColumns: ColumnDef<Library>[] = [
    {
        id: 'name',
        accessorKey: 'name',
        header: ({ column }) => (
            <div className='flex items-center gap-1 text-nowrap'>
                <p className='font-semibold text-newDesign-text-primary'>
                    Library Name
                </p>
                <Button
                    variant='text'
                    size='sm'
                    withIcon
                    className='aspect-square bg-newDesign-background px-0 text-newDesign-text-primary'
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === 'asc')
                    }>
                    <ArrowUpDown className='size-4' />
                </Button>
            </div>
        ),
        cell: ({ row }) => {
            const name = row.original.name;
            return <div>{name}</div>;
        },
    },
    {
        id: 'organization',
        accessorKey: 'organization',
        header: ({ column }) => (
            <div className='flex items-center gap-1'>
                <p className='font-semibold text-newDesign-text-primary'>
                    Owner
                </p>
                <Button
                    variant='text'
                    size='sm'
                    withIcon
                    className='aspect-square bg-newDesign-background px-0 text-newDesign-text-primary'
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === 'asc')
                    }>
                    <ArrowUpDown className='size-4' />
                </Button>
            </div>
        ),
        cell: ({ row }) => {
            const owner: string = row.getValue('organization');
            return <div>{owner}</div>;
        },
    },
    {
        id: 'description',
        accessorKey: 'description',
        header: () => (
            <p className='font-semibold text-newDesign-text-primary'>
                Description
            </p>
        ),
        cell: ({ row }) => {
            const description = row.getValue('description');
            return description ? (
                <DescriptionDialog description={description} />
            ) : (
                <></>
            );
        },
    },
    {
        id: 'readonly',
        accessorKey: 'readonly',
        sortUndefined: 'last',
        sortDescFirst: false,
        header: ({ column }) => (
            <div className='flex items-center gap-1'>
                <p className='whitespace-nowrap text-nowrap font-semibold text-newDesign-text-primary'>
                    Read Only
                </p>
                <Button
                    variant='text'
                    size='sm'
                    withIcon
                    className='aspect-square bg-newDesign-background px-0 text-newDesign-text-primary'
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === 'asc')
                    }>
                    <ArrowUpDown className='size-4' />
                </Button>
            </div>
        ),
        cell: ({ row }) => {
            const value = row.getValue('readonly');

            return value ? (
                <div className='mr-10 flex justify-center'>
                    <FaCheck className='size-4 text-newDesign-primary' />
                </div>
            ) : undefined;
        },
    },
    {
        id: 'actions',
        header: () => <p className='text-newDesign-text-primary'>Actions</p>,
        cell: ({ row }) => {
            const readOnly = row.getValue('readonly');
            return readOnly ? null : (
                <div className='ml-3 flex'>
                    <LibraryManagementActions
                        owner={row.original.owner}
                        libraryId={row.original.id}
                        libraryName={row.getValue('name')}
                        organization={row.getValue('organization')}
                        readOnly={row.getValue('readonly')}
                    />
                </div>
            );
        },
    },
];

const LibraryTable = ({
    searchTerm,
    data,
    loading,
    rowSelection,
    onRowSelectionChange,
}: {
    searchTerm: string;
    data: Library[];
    loading: boolean;
    rowSelection: RowSelectionState;
    onRowSelectionChange: React.Dispatch<
        React.SetStateAction<RowSelectionState>
    >;
}) => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    useEffect(() => {
        setColumnFilters([
            {
                id: 'name',
                value: searchTerm,
            },
        ]);
    }, [searchTerm]);

    const table = useReactTable<Library>({
        data,
        columns: libraryTableColumns,
        state: { sorting, columnFilters, rowSelection },
        onSortingChange: setSorting,
        onRowSelectionChange,
        getRowId: (row) => row.id,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: { pagination: { pageSize: 10 } },
        enableMultiRowSelection: true,
    });

    return (
        <div>
            <Table>
                <TableHeader className='bg-newDesign-background'>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id} className='text-base'>
                            {headerGroup.headers.map((header) => (
                                <TableHead
                                    key={header.id}
                                    className={
                                        header.column.id === 'name'
                                            ? 'w-full'
                                            : 'w-fit text-nowrap'
                                    }>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </TableHead>
                            ))}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {loading ? (
                        <SkeletonRows length={data?.length} />
                    ) : table.getRowModel().rows.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell
                                        key={cell.id}
                                        className={
                                            cell.column.id === 'name'
                                                ? 'w-full'
                                                : 'w-fit text-nowrap'
                                        }>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={table.getAllColumns().length}>
                                No results.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <div className='flex items-center justify-end space-x-2 py-4'>
                <div className='text-muted-foreground flex-1 text-sm'>
                    {table.getFilteredSelectedRowModel().rows.length} of{' '}
                    {table.getFilteredRowModel().rows.length} library(ies)
                    selected.
                </div>
                <div className='flex items-center space-x-2'>
                    <p className='text-sm'>
                        {getShowingResultsPaginationText(table)}
                    </p>

                    <Select
                        value={table.getState().pagination.pageSize?.toString()}
                        onValueChange={(pageSize) => {
                            table.setPageSize(Number(pageSize));
                        }}>
                        <SelectTrigger className='h-8 w-[100px]'>
                            <SelectValue defaultValue='10' />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Page Size</SelectLabel>
                                {['10', '20', '30', '40', '50'].map(
                                    (pageSize) => (
                                        <SelectItem
                                            key={pageSize}
                                            value={pageSize}>
                                            Show {pageSize}
                                        </SelectItem>
                                    ),
                                )}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                    <Button
                        variant='outline'
                        size='sm'
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}>
                        Previous
                    </Button>
                    <Button
                        variant='outline'
                        size='sm'
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
};

const getShowingResultsPaginationText = <T,>(table: TableDef<T>) => {
    const { pageIndex, pageSize } = table.getState().pagination;
    const totalItems = table.getFilteredRowModel().rows.length;

    if (totalItems === 0) {
        return null;
    }

    const firstItemIndex = pageIndex * pageSize + 1;
    const lastItemIndex = Math.min(totalItems, (pageIndex + 1) * pageSize);

    return `Showing ${firstItemIndex} - ${lastItemIndex} of ${totalItems} results`;
};

const LibraryManagement = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [openAddDialog, setOpenAddDialog] = useState(false);

    const libraries = useLibraries({});

    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    useEffect(() => {
        if (libraries.isError) {
            toast.error('Failed to load libraries');
        }
    }, [libraries.isError]);

    return (
        <div className='w-full'>
            <p className='text-2xl font-bold '>Libraries</p>
            <DialogAddLibrary
                isModalOpen={openAddDialog}
                closeModal={() => setOpenAddDialog(false)}
            />

            <p className='mt-4'>
                BAE Libraries empower you to piece together components as
                seamlessly as connecting Lego bricks, forming complex economic
                structures tailored to your strategic needs.
            </p>
            <ul className='mt-2 list-disc pl-4 text-newDesign-primary-active'>
                <li>
                    Build and Assemble:{' '}
                    <span className='text-newDesign-text-primary'>
                        {' '}
                        reusable model components and assemble them into your
                        network as needed.
                    </span>
                </li>
                <li>
                    Seamless Integration:{' '}
                    <span className='text-newDesign-text-primary'>
                        Effortlessly incorporate data, whether starting a new or
                        leveraging Excel inputs.
                    </span>
                </li>
                <li>
                    Customize & Control:{' '}
                    <span className='text-newDesign-text-primary'>
                        Drive the evolution of your library toward targeted
                        results, with full command over customizations.
                    </span>
                </li>
            </ul>
            <div className='mt-4 inline-flex h-10 w-full gap-x-4'>
                <span className='relative w-full'>
                    <HiMiniMagnifyingGlass className='absolute left-4 top-1/2 size-5 translate-y-[-50%] text-newDesign-text-secondary' />
                    <Input
                        name='search'
                        placeholder='Search libraries'
                        value={searchTerm}
                        onChange={(event) => setSearchTerm(event.target.value)}
                        className='h-10 pl-12'
                    />
                    <IoIosClose
                        role='button'
                        tabIndex={0}
                        className={cn(
                            'absolute right-2 top-1/2 aspect-square size-7 translate-y-[-50%] cursor-pointer rounded-md text-newDesign-text-primary hover:bg-newDesign-text-hover',
                            {
                                'pointer-events-none opacity-0':
                                    searchTerm === '',
                            },
                        )}
                        onClick={() => setSearchTerm('')}
                    />
                </span>

                <Button onClick={() => setOpenAddDialog(true)} withIcon>
                    <LuPlus className='mr-2 size-6' />
                    Create New
                </Button>
            </div>
            <div className='mt-4'>
                <LibraryTable
                    data={libraries.data || []}
                    loading={libraries.isFetching || libraries.isLoading}
                    rowSelection={rowSelection}
                    onRowSelectionChange={setRowSelection}
                    searchTerm={searchTerm}
                />
            </div>
        </div>
    );
};

export default LibraryManagement;
