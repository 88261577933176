// get-input.ts
import { QueryConfig } from '@/libs/api/src/lib/react-query';
import { useQuery } from '@tanstack/react-query';
import { queryOptions } from '@tanstack/react-query';

import {
    adaptModelInput,
    AxiosAPIv2,
    getToken,
    ModelInputsResponse,
} from '@bae/api';
import { ModelInput } from '@bae/data-interface';

export type InputType = 'model' | 'library';

const modelParams = [
    'tenants',

    'endpoints',
    'endpoint_timeseries',

    'servicespecs',
    'serviceplans',
    'servicecfconfig',

    'service_connections',
    'service_connection_timeseries',

    'service_demand',
    'service_demand_timeseries',

    'appspecs',
    'appmodels',
    'appcfconfig',

    'appavailability',
    'datacenters',
    'dclabels',

    'registry',

    'serverdeployment',
    'servercfconfig',
    'servers',

    'cpecfconfig',
    'cpeconfig',
    'cpehardware',

    'fte',
    'fte_fixed_deployment',
    'fte_dynamic_deployment',

    'dirty',
];

const libraryParams = [
    'appmodels',
    'appspecs',
    'cpecfconfig',
    'cpehardware',
    'cpeconfig',
    'appcfconfig',
    'servercfconfig',
    'servers',
    'servicecfconfig',
    'servicespecs',
    'serviceplans',
    'service_connections',
    'service_connection_timeseries',
    'service_demand',
    'service_demand_timeseries',
    'fte',
    'fte_fixed_deployment',
    'fte_dynamic_deployment',
];

export const getInput = async (
    id: string,
    type: InputType,
    params?: string[],
): Promise<ModelInput> => {
    const typeConfig = {
        model: {
            getUrl: (fetchParams: string) =>
                `/models/${id}/inputs${fetchParams}`,
            params: params ? params : modelParams,
        },
        library: {
            getUrl: (fetchParams: string) =>
                `/libraries/${id}/inputs${fetchParams}`,
            params: params ? params : libraryParams,
        },
    };

    const selectedType = typeConfig[type];

    const fetchParams = `?fetch=${selectedType.params.join('&fetch=')}`;
    const url = selectedType.getUrl(fetchParams);

    const response = await AxiosAPIv2.axiosInstance.get<ModelInputsResponse>(
        url,
        { headers: { Authorization: 'Bearer ' + getToken() } },
    );

    return adaptModelInput(response.data);
};

export const getQueryKey = (id: string, type: InputType, params?: string[]) => {
    if (!params) {
        if (type === 'model') {
            return [type, 'inputs', id, modelParams];
        }

        if (type === 'library') {
            return [type, 'inputs', id, libraryParams];
        }
    } else {
        return [type, 'inputs', id, params];
    }
};

export const getInputQueryOptions = (
    id: string,
    type: InputType,
    params?: string[],
) => {
    return queryOptions({
        queryKey: getQueryKey(id, type, params),
        queryFn: () => getInput(id, type, params),
    });
};

type UseInputOptions = {
    id: string;
    type: InputType;
    params?: string[];
    queryConfig?: QueryConfig<typeof getInput>;
    refetchInterval?: number | false;
    enabled?: boolean;
};

export const useInput = ({
    id,
    type,
    params,
    queryConfig,
    refetchInterval,
    enabled,
}: UseInputOptions) => {
    return useQuery({
        ...getInputQueryOptions(id, type, params),
        ...queryConfig,
        refetchInterval,
        enabled,
    });
};
