import {
    FteDynamicDeploymentInput,
    FteFixedDeploymentInput,
    FteInput,
} from '@bae/api';
import {
    FteFixedDeployment,
    FteSpecs,
    FteVariableDeployment,
} from '@bae/data-interface';

export const getDefaultSpecs = (fteName: string): FteSpecs => ({
    fteName,
    annualExpense: 0,
    annualExpenseCagr: 0,
    description: '',
});

export const fteAdapter = (data: FteSpecs): FteInput => {
    return {
        fte_name: data.fteName,
        annual_expense: data.annualExpense,
        annual_expense_cagr: data.annualExpenseCagr,
        description: data.description,
    };
};

export const fixedDeploymentAdapter = (
    data: FteFixedDeployment,
): FteFixedDeploymentInput => {
    return {
        fte_name: data.fteName,
        flow_name: data.flowName,
        quantity: data.quantity,
        quantity_cagr: data.quantityGrowth,
        flow_type: data.flowType,
        recurring: data.recurring,
    };
};

export const dynamicDeploymentAdapter = (
    data: FteVariableDeployment,
): FteDynamicDeploymentInput => {
    return {
        fte_name: data.fteName,
        flow_name: data.flowName,
        quantity: data.fteQuantity,
        flow_type: data.flowType,
        recurring: data.recurring,
        log_base: undefined,
        driver_name: data.driverName,
        driver_type: data.driverType,
    };
};

export const createDeleteParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
): Record<string, string>[] => {
    return data.map((datum) => {
        const datumParams: Record<string, string> = {};

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams[apiKey] = datum[moduleKey];
        });

        return datumParams;
    });
};

export const createCloneQueryParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
) =>
    data.map((datum) => {
        const datumParams = {
            source: {},
            target: {},
        };

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams.source[apiKey] = datum[moduleKey];
            datumParams.target[apiKey] = `Copy of ${datum[moduleKey]}`;
        });

        return datumParams;
    });
