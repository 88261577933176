import { queryClient } from '@/libs/api/src/lib/react-query.ts';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { AuthProvider } from '@bae/auth';
import { CurrencyProvider } from '@bae/store';

import { store } from '@/store';

export const AppProvider = ({ children }) => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    {import.meta.env.DEV && <ReactQueryDevtools />}
                    <AuthProvider>
                        <CurrencyProvider>
                            <ToastContainer
                                position='top-right'
                                autoClose={2000}
                                closeOnClick
                                pauseOnFocusLoss
                                draggable={false}
                                pauseOnHover
                                theme='light'
                            />
                            {children}
                        </CurrencyProvider>
                    </AuthProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </Provider>
    );
};
