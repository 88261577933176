export const SITE_OPTIONS = [
    {
        value: 'Small Branch',
        label: 'Small Branch',
    },
    {
        value: 'Medium Site No HA',
        label: 'Medium Site No HA',
    },
    {
        value: 'Medium Site HA',
        label: 'Medium Site HA',
    },
    {
        value: 'Large Corporate Site',
        label: 'Large Corporate Site',
    },
    {
        value: 'Data Center',
        label: 'Data Center',
    },
    {
        value: 'Azure East/West',
        label: 'Azure East/West',
    },
    {
        value: 'Micro Branch',
        label: 'Micro Branch',
    },
    {
        value: 'UK Site',
        label: 'UK Site',
    },
] as const;
