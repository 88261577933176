import type {
    Expense,
    FTE,
    Instance,
    InternetEgress,
    MiscellaneousCosts,
    Server,
} from '../shared/types';
import { EXPENSE_TYPE, RECURRING } from '../shared/types.ts';
import type { CloudRebalancingTemplate } from './types.ts';

export const instancesData: Instance[] = [
    {
        type: 'App Server Instances', // fixed, can't be renamed
        quantity: 6,
        growth: 0,
        vcpu: 16,
        memory: 64,
        dataEgress: 5000,
        storage: 3800,
    },
    {
        type: 'Database Instances', // fixed, can't be renamed
        quantity: 2,
        growth: 0,
        vcpu: 64,
        memory: 512,
        dataEgress: 5000,
        storage: 3800,
    },
];

const internetEgressData: InternetEgress[] = [
    {
        expenseName: '0-99TB', // fixed, can't be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        dataEgressCost: 0.05, // can be modified
        notes: null, // can be modified
    },
    {
        expenseName: '100-249TB', // fixed, can't be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        dataEgressCost: 0.03, // can be modified
        notes: null, // can be modified
    },
    {
        expenseName: '1PB+', // fixed, can't be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        dataEgressCost: 0.01, // can be modified
        notes: null, // can be modified
    },
    {
        expenseName: '250-499TB', // fixed, can't be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        dataEgressCost: 0.02, // can be modified
        notes: null, // can be modified
    },
    {
        expenseName: '500-999TB', // fixed, can't be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        dataEgressCost: 0.01, // can be modified
        notes: null, // can be modified
    },
];

const miscellaneousCosts: MiscellaneousCosts[] = [
    {
        expenseName: 'Misc Expense 1', // fixed, can't be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 0, // can be modified
        notes: null, // can be modified
    },
    {
        expenseName: 'Misc Expense 2', // fixed, can't be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 0, // can be modified
        notes: null, // can be modified
    },
    {
        expenseName: 'Misc Expense 3', // fixed, can't be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 0, // can be modified
        notes: null, // can be modified
    },
];

const metalExpensesData: Expense[] = [
    {
        expenseName: 'Application Storage', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 1, // can be modified
    },
    {
        expenseName: 'm3.small.x86', // not fixed, but can't be renamed here. This name will come from the server list
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 613.2, // can be modified
    },
    {
        expenseName: 'n3.xlarge.x86', // not fixed, but can't be renamed here. This name will come from the server list
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 2299, // can be modified
    },
    {
        expenseName: 'Cloud Software', // can be renamed
        recurring: RECURRING.YEARLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 1200, // can be modified
    },
    {
        expenseName: 'Database License', // can be renamed
        recurring: RECURRING.YEARLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 3333, // can be modified
    },
];

const metalServersData: Server[] = [
    {
        serverName: 'm3.small.x86', // can be modified
        vcpu: 16, // can be modified
        memory: 64, // can be modified
    },
    {
        serverName: 'n3.xlarge.x86', // can be modified
        vcpu: 64, // can be modified
        memory: 512, // can be modified
    },
];

const metalFtesData: FTE[] = [
    {
        fteName: 'Maintenance FTEs', // can be renamed
        annualExpense: 175000, // can be modified
        annualGrowth: 0, // can be modified
        quantity: 1.27, // can be modified
    },
];

const coloExpensesData: Expense[] = [
    {
        expenseName: 'Colo MRC', // can be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 1749, // can be modified
    },
    {
        expenseName: 'Colo Cabinet Install', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 1500, // can be modified
    },
    {
        expenseName: 'Colo Circuit Install', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 500, // can be modified
    },
    {
        expenseName: 'App Server Acquisition', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 15000, // can be modified
    },
    {
        expenseName: 'App Server Vendor Support', // can be renamed
        recurring: RECURRING.YEARLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 1000, // can be modified
    },
    {
        expenseName: 'App Server Installation', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 500, // can be modified
    },
    {
        expenseName: 'DB Server Acquisition', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 17000, // can be modified
    },
    {
        expenseName: 'DB Server Installation', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 500, // can be modified
    },
    {
        expenseName: 'DB Server Vendor Support', // can be renamed
        recurring: RECURRING.YEARLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 1200, // can be modified
    },
    {
        expenseName: 'Application Storage', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 1, // can be modified
    },
    {
        expenseName: 'Cloud Software', // can be renamed
        recurring: RECURRING.YEARLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 1200, // can be modified
    },
    {
        expenseName: 'Database License', // can be renamed
        recurring: RECURRING.YEARLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 3333, // can be modified
    },
    {
        expenseName: 'ToR Switch 1', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 21000, // can be modified
    },
    {
        expenseName: 'ToR Switch 2', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: 21000, // can be modified
    },
];

const coloServersData: Server[] = [
    {
        serverName: 'App Server', // fixed, can't be renamed
        vcpu: 64, // can be modified
        memory: 512, // can be modified
    },
    {
        serverName: 'DB Server', // fixed, can't be renamed
        vcpu: 64, // can be modified
        memory: 1024, // can be modified
    },
    {
        serverName: 'Colo Cabinet', // fixed, can't be renamed
        vcpu: 5120, // can be modified
        memory: null, // fixed, can't be modified
    },
];

const coloFtesData: FTE[] = [
    {
        fteName: 'Maintenance FTEs', // can be renamed
        annualExpense: 175000, // can be modified
        annualGrowth: 0, // can be modified
        quantity: 0.83, // can be modified
    },
];

const cloudExpensesData: Expense[] = [
    {
        expenseName: 'Cloud Storage', // can be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 0.1, // can be modified
    },
    {
        expenseName: 'm5.4xlarge', // not fixed, but can't be renamed here. This name will come from the VMs list
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 276, // can be modified
    },
    {
        expenseName: 'db.r5.16xlarge', // can be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 6531.31, // can be modified
    },
    {
        expenseName: 'BYO DB License', // can be renamed
        recurring: RECURRING.YEARLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: 3333, // can be modified
    },
];

const cloudVMsData: Server[] = [
    {
        serverName: 'm5.4xlarge', // can be modified
        vcpu: 16, // can be modified
        memory: 64, // can be modified
    },
];

const cloudFtesData: FTE[] = [
    {
        fteName: 'Maintenance FTEs', // can be renamed
        annualExpense: 175000, // can be modified
        annualGrowth: 0, // can be modified
        quantity: 1.27, // can be modified
    },
];

// EXPORTS

export const initialData: CloudRebalancingTemplate = {
    metal: {
        expenses: metalExpensesData,
        servers: metalServersData,
        ftes: metalFtesData,
        internetEgress: internetEgressData,
        miscellaneousCosts: miscellaneousCosts,
    },
    colo: {
        expenses: coloExpensesData,
        servers: coloServersData,
        ftes: coloFtesData,
        internetEgress: internetEgressData,
        miscellaneousCosts: miscellaneousCosts,
    },
    cloud: {
        expenses: cloudExpensesData,
        vms: cloudVMsData,
        ftes: cloudFtesData,
        miscellaneousCosts: miscellaneousCosts,
    },
};
