import React from 'react';

import { Skeleton } from '@/components/ui/skeleton.tsx';
import Scenario from '@/features/scenario/components/Scenario.tsx';
import { IScenarioData } from '@/features/scenario/mock-data.ts';

const ScenariosContent = ({
    loading,
    scenarios,
}: {
    loading: boolean;
    scenarios: IScenarioData[];
}) => {
    if (loading)
        return (
            <>
                <Skeleton className='h-10 w-full rounded-md bg-newDesign-divider' />
                <Skeleton className='h-10 w-full rounded-md bg-newDesign-divider' />
                <Skeleton className='h-10 w-full rounded-md bg-newDesign-divider' />
            </>
        );

    if (!scenarios)
        return (
            <div className='flex h-[135px] w-full items-center justify-center'>
                <p className='text-2xl text-newDesign-black'>
                    No scenario fork created yet
                </p>
            </div>
        );

    return scenarios.map((scenario) => (
        <Scenario key={scenario.id} data={scenario} />
    ));
};

export default ScenariosContent;
