import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { Organization } from '@bae/data-interface';

interface GetOrganizationApi {
    organizations: Array<{
        organization_id: string;
        organization_name: string;
    }>;
}

const getOrganizations = async (): Promise<Organization[]> => {
    const response = await AxiosAPIv2.axiosInstance.get<GetOrganizationApi>(
        '/organizations',
        { headers: { Authorization: 'Bearer ' + getToken() } },
    );

    return response.data.organizations.map((d) => ({
        id: d.organization_id,
        name: d.organization_name,
    }));
};

export const getOrganizationsQueryOptions = () => {
    return queryOptions({
        queryKey: ['organizations'],
        queryFn: () => getOrganizations(),
    });
};

type UseOrganizationsOptions = {
    queryConfig?: QueryConfig<typeof getOrganizationsQueryOptions>;
    refetchInterval?: number | false;
    enabled?: boolean;
};

export const useOrganizations = ({
    queryConfig,
    refetchInterval,
    enabled = true,
}: UseOrganizationsOptions) => {
    return useQuery({
        ...getOrganizationsQueryOptions(),
        ...queryConfig,
        refetchInterval,
        enabled,
    });
};
