import { ChangeEvent, ComponentProps } from 'react';

import { Input } from '@/components/ui/input.tsx';
import { cn } from '@/lib/utils.ts';

const NumberInputCell = <T,>({
    value,
    onChange,
    accessorKey,
    index,
    className,
}: {
    value: number;
    onChange: <K extends keyof T>(index: number, key: K, value: T[K]) => void;
    accessorKey: keyof T;
    index: number;
    className?: ComponentProps<'div'>['className'];
}) => {
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            onChange(index, accessorKey, 0 as T[keyof T]);
            return;
        }

        onChange(index, accessorKey, parseInt(e.target.value) as T[keyof T]);
    };

    return (
        <td className='h-[36px] rounded-md border bg-white p-2 text-center'>
            {value === null ? (
                <p>N/A</p>
            ) : (
                <Input
                    onChange={handleInputChange}
                    value={value}
                    className={cn(
                        'h-6 w-full rounded-sm border-none text-center',
                        className,
                    )}
                    type='text'
                />
            )}
        </td>
    );
};

export default NumberInputCell;
