import React from 'react';
import {
    Column,
    DataSheetGrid,
    intColumn,
    keyColumn,
    textColumn,
} from 'react-datasheet-grid';

import { Button } from '@/components/ui/button.tsx';

import { selectSitesInput, useNetworkStore } from '../store.ts';
import type { ISiteInput } from '../types.ts';

const columns: Column<ISiteInput>[] = [
    {
        ...keyColumn<ISiteInput, 'site'>('site', textColumn),
        title: 'Site',
        minWidth: 200,
    },
    {
        ...keyColumn<ISiteInput, 'quantity'>('quantity', intColumn),
        title: 'Quantity',
        minWidth: 100,
    },
    {
        ...keyColumn<ISiteInput, 'country'>('country', textColumn),
        title: 'Country',
        minWidth: 150,
    },
    {
        ...keyColumn<ISiteInput, 'address'>('address', textColumn),
        title: 'Address',
        minWidth: 200,
    },
    {
        ...keyColumn<ISiteInput, 'timeZone'>('timeZone', textColumn),
        title: 'Time Zone',
        minWidth: 150,
    },
    {
        ...keyColumn<ISiteInput, 'siteGroups'>('siteGroups', textColumn),
        title: 'Site Groups',
        minWidth: 150,
    },
];

const createEmptyNewRows = (numberOfRows: number): ISiteInput[] => {
    const arr: ISiteInput[] = [];

    for (let i = 0; i < numberOfRows; i++) {
        arr.push({
            id: '',
            site: null,
            quantity: null,
            country: null,
            address: null,
            timeZone: null,
            siteGroups: null,
        });
    }

    return arr;
};

const SiteDataGrid = () => {
    const setData = useNetworkStore((state) => state.setSitesInput);
    const data = useNetworkStore(selectSitesInput);

    return (
        <div className='relative'>
            <DataSheetGrid
                headerRowHeight={50}
                createRow={() => createEmptyNewRows(1)[0]}
                value={data}
                columns={columns}
                onChange={setData}
                rowKey='id'
            />
            {(!data || data.length === 0) && (
                <div className='absolute bottom-0 left-0 right-0 top-0 z-[55] flex h-[74dvh] w-full flex-col items-center justify-center gap-4 rounded-md bg-newDesign-black bg-opacity-20 text-xl font-bold'>
                    No Sites available
                    <Button
                        onClick={() => {
                            setData(createEmptyNewRows(1));
                        }}>
                        Create
                    </Button>
                </div>
            )}
        </div>
    );
};

export default SiteDataGrid;
