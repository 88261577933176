import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ServiceCashflow } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { createDeleteParams } from '../adapters.ts';

type DeleteServiceCashFlowParams = {
    modelId: string;
    type: 'model' | 'library';
    data: ServiceCashflow | ServiceCashflow[];
};

const keyMap = {
    svcname: 'serviceName',
    svcplan: 'plan',
    flowname: 'flowName',
};

const deleteServiceCashFlow = async ({
    modelId,
    type,
    data,
}: DeleteServiceCashFlowParams): Promise<void> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/servicecfconfig`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const dataArray = Array.isArray(data) ? data : [data];

    const payload = createDeleteParams<ServiceCashflow>(keyMap, dataArray);

    const config: AxiosRequestConfig = {
        headers,
        data: payload,
    };

    await AxiosAPIv2.axiosInstance.delete(url, config);
};

type UseDeleteServiceCashFlowOptions = {
    mutationConfig?: MutationConfig<typeof deleteServiceCashFlow>;
};

export const useDeleteServiceCashFlow = ({
    mutationConfig,
}: UseDeleteServiceCashFlowOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: deleteServiceCashFlow,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
