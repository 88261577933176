import { create } from 'zustand';

import { getNetworkConfigurationMockData } from './mock-data.ts';
import type {
    INetworkSiteInput,
    IAwsCloudPortInput,
    IAzureCloudPortInput,
    IGcpCloudPortInput,
    IDiscounts,
    IFTE,
    INetworkConfigurationData,
} from './types.ts';

interface INetworkConfigurationStore extends INetworkConfigurationData {
    setData: () => void;
    setNetworkSiteInput: (networkSiteInput: INetworkSiteInput[]) => void;
    setAwsCloudPortInput: (awsCloudPortInput: IAwsCloudPortInput[]) => void;
    setAzureCloudPortInput: (
        azureCloudPortInput: IAzureCloudPortInput[],
    ) => void;
    setGcpCloudPortInput: (gcpCloudPortInput: IGcpCloudPortInput[]) => void;
    setDiscounts: (discounts: IDiscounts[]) => void;
    setFte: (fte: IFTE[]) => void;
}

export const useNetworkStore = create<INetworkConfigurationStore>()((set) => ({
    networkSiteInput: [],
    awsCloudPortInput: [],
    azureCloudPortInput: [],
    gcpCloudPortInput: [],
    discounts: [],
    fte: [],

    setData: () => {
        const mockData = getNetworkConfigurationMockData();
        set(mockData);
    },

    setNetworkSiteInput: (networkSiteInput: INetworkSiteInput[]) =>
        set({ networkSiteInput }),
    setAwsCloudPortInput: (awsCloudPortInput: IAwsCloudPortInput[]) =>
        set({ awsCloudPortInput }),
    setAzureCloudPortInput: (azureCloudPortInput: IAzureCloudPortInput[]) =>
        set({ azureCloudPortInput }),
    setGcpCloudPortInput: (gcpCloudPortInput: IGcpCloudPortInput[]) =>
        set({ gcpCloudPortInput }),
    setDiscounts: (discounts: IDiscounts[]) => set({ discounts }),
    setFte: (fte: IFTE[]) => set({ fte }),
}));

export const selectNetworkSiteInput = (state: INetworkConfigurationStore) =>
    state.networkSiteInput;

export const selectAwsCloudPortInput = (state: INetworkConfigurationStore) =>
    state.awsCloudPortInput;

export const selectAzureCloudPortInput = (state: INetworkConfigurationStore) =>
    state.azureCloudPortInput;

export const selectGcpCloudPortInput = (state: INetworkConfigurationStore) =>
    state.gcpCloudPortInput;

export const selectDiscounts = (state: INetworkConfigurationStore) =>
    state.discounts;

export const selectFte = (state: INetworkConfigurationStore) => state.fte;
