import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { FteSpecs } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { createCloneQueryParams, getDefaultSpecs } from './adapters.ts';

type CloneFteParams = {
    modelId: string;
    type: 'model' | 'library';
    data: string | string[];
};

const keyMap = {
    fte_name: 'fteName',
};

const cloneFte = async ({
    modelId,
    type,
    data,
}: CloneFteParams): Promise<void> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/copy/fte`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const dataArray = Array.isArray(data) ? data : [data];

    const mappedData = dataArray.map(getDefaultSpecs);

    const payload = createCloneQueryParams<FteSpecs>(keyMap, mappedData);

    await AxiosAPIv2.axiosInstance.post(url, payload, { headers });
};

type UseCloneFteOptions = {
    mutationConfig?: MutationConfig<typeof cloneFte>;
};

export const useCloneFte = ({ mutationConfig }: UseCloneFteOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: cloneFte,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
