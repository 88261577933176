import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { Endpoint, EndpointDeployment } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { endpointAdapter } from './adapters.ts';
import { useEditEndpointTimeSeries } from './delayed-deployment/edit-endpoint-timeseries.ts';

type EditEndpointParams = {
    modelId: string;
    type: 'model' | 'library';
    prevData: Endpoint;
    nextData: Endpoint;
    deployment?: {
        prevData: EndpointDeployment;
        nextData: EndpointDeployment;
    };
};

const editEndpoint = async ({
    modelId,
    type,
    prevData,
    nextData,
}: EditEndpointParams): Promise<Endpoint> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/endpoints`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const params = {
        tenantname: prevData.tenantName,
        endpointname: prevData.endpointName,
        model_id: modelId,
    };

    const config: AxiosRequestConfig = {
        headers,
        params,
    };

    const payload = endpointAdapter(nextData);

    await AxiosAPIv2.axiosInstance.patch(url, payload, config);

    return nextData;
};

type UseEditEndpointOptions = {
    mutationConfig?: MutationConfig<typeof editEndpoint>;
};

export const useEditEndpoint = ({
    mutationConfig,
}: UseEditEndpointOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    const editEndpointTimeSeriesQuery = useEditEndpointTimeSeries({
        invalidate: false,
    });

    return useMutation({
        mutationFn: editEndpoint,
        onSuccess: async (data, variables, context) => {
            const { modelId, type, deployment } = variables;

            if (deployment) {
                // Update tenantName and endpointName in deployment data
                const updatedPrevData = {
                    ...deployment.prevData,
                    tenantName: data.tenantName,
                    endpointName: data.endpointName,
                };
                const updatedNextData = {
                    ...deployment.nextData,
                    tenantName: data.tenantName,
                    endpointName: data.endpointName,
                };

                await editEndpointTimeSeriesQuery.mutateAsync({
                    modelId,
                    type,
                    prevData: updatedPrevData,
                    nextData: updatedNextData,
                });
            }

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
