import { AnimatePresence, motion } from 'framer-motion';

import React from 'react';

import { TabsContent } from '@/components/ui/tabs.tsx';

const TabContent = ({ tabValue, children }) => {
    return (
        <TabsContent value={tabValue}>
            <AnimatePresence>
                <motion.div
                    transition={{ duration: 0 }}
                    initial={{
                        scale: 0,
                        originX: 0,
                        originY: 0,
                        overflow: 'hidden',
                    }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    className='size-full'>
                    {children}
                </motion.div>
            </AnimatePresence>
        </TabsContent>
    );
};

export default TabContent;
