import { z } from 'zod';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getScenariosQueryOptions } from '@/features/scenario/api/get-scenarios.ts';

const cloneScenarioSchema = z.object({
    id: z.string(),
    name: z.string(),
});

export const cloneScenariosSchema = z.object({
    data: z.array(cloneScenarioSchema),
});

export type CloneScenarioInput = z.infer<typeof cloneScenariosSchema>;

const cloneScenario = async ({ data }: CloneScenarioInput) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({ data });
        }, 500);
    });
};

type UseCloneScenarioOptions = {
    mutationConfig?: MutationConfig<typeof cloneScenario>;
};

export const useCloneScenario = ({
    mutationConfig,
}: UseCloneScenarioOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: (...args) => {
            queryClient.invalidateQueries({
                queryKey: getScenariosQueryOptions().queryKey,
            });
            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: cloneScenario,
    });
};
