import {
    IAccessPointInput,
    INetworkConfigurationData,
    ISiteInput,
    ISwitchInput,
    IWANEdgeInput,
} from './types.ts';
import { faker } from '@faker-js/faker';

export function getNetworkConfigurationMockData(): INetworkConfigurationData {
    return {
        accessPointsInput: createDemoAccessPointsInput(),
        switchesInput: createDemoSwitchesInput(),
        mistEdgeInput: [],
        wanEdgeInput: createDemoWANEdgeInput(),
        sitesInput: createDemoSiteInput(),
    };
}

function createDemoAccessPointsInput(): IAccessPointInput[] {
    return [
        {
            id: faker.string.uuid(),
            site: 'Small Branch',
            name: 'Small Branch AP24',
            sku: 'AP24-1Y',
            quantity: 1,
        },
        {
            id: faker.string.uuid(),
            site: 'Medium Site No HA',
            name: 'Medium no HA AP45-2S',
            sku: 'AP45-2S-1Y',
            quantity: 8,
        },
        {
            id: faker.string.uuid(),
            site: 'Medium Site HA',
            name: 'Medium HA AP45-2S',
            sku: 'AP45-2S-1Y',
            quantity: 8,
        },
        {
            id: faker.string.uuid(),
            site: 'Large Corporate Site',
            name: 'Large AP 45-2S',
            sku: 'AP45-2S-1Y',
            quantity: 27,
        },
        {
            id: faker.string.uuid(),
            site: 'Micro Branch',
            name: 'Micro Branch AP12-2S-1Y',
            sku: 'AP12-2S-1Y',
            quantity: 1,
        },
        {
            id: faker.string.uuid(),
            site: 'UK Site',
            name: 'UK AP45-2S',
            sku: 'AP45-2S-1Y',
            quantity: 5,
        },
    ];
}

function createDemoSwitchesInput(): ISwitchInput[] {
    return [
        {
            id: faker.string.uuid(),
            site: 'Small Branch',
            name: 'Small Branch EX 4100',
            sku: 'EX 4100-F-12P-1Y',
            quantity: 2,
        },
        {
            id: faker.string.uuid(),
            site: 'Medium Site No HA',
            name: 'Medium No HA EX 4100-48MP',
            sku: 'EX 4100-48MP-1Y',
            quantity: 2,
        },
        {
            id: faker.string.uuid(),
            site: 'Medium Site HA',
            name: 'Medium HA EX 4100-48MP',
            sku: 'EX 4100-48MP-1Y',
            quantity: 2,
        },
        {
            id: faker.string.uuid(),
            site: 'Large Corporate Site',
            name: 'Large EX 4100-48MP',
            sku: 'EX 4100-48MP-1Y',
            quantity: 8,
        },
        {
            id: faker.string.uuid(),
            site: 'UK Site',
            name: 'UK EX4100-48MP',
            sku: 'EX 4100-48MP-1Y',
            quantity: 2,
        },
    ];
}

function createDemoWANEdgeInput(): IWANEdgeInput[] {
    return [
        {
            id: faker.string.uuid(),
            site: 'Small Branch',
            name: 'Small Branch SSR 120-AE',
            sku: 'SSR 120-AE-1Y',
            bandwidthMbps: 50,
            annualBWGrowth: 0.05,
            quantity: 1,
        },
        {
            id: faker.string.uuid(),
            site: 'Medium Site No HA',
            name: 'Medium No HA SSR 130-AE',
            sku: 'SSR 130-AE-1Y',
            bandwidthMbps: 500,
            annualBWGrowth: 0.05,
            quantity: 1,
        },
        {
            id: faker.string.uuid(),
            site: 'Medium Site HA',
            name: 'Medium HA SSR 140-AE',
            sku: 'SSR 130-AE-1Y',
            bandwidthMbps: 500,
            annualBWGrowth: 0.05,
            quantity: 2,
        },
        {
            id: faker.string.uuid(),
            site: 'Large Corporate Site',
            name: 'Large SSR 1300',
            sku: 'SSR 1300-1Y',
            bandwidthMbps: 2500,
            annualBWGrowth: 0.05,
            quantity: 2,
        },
        {
            id: faker.string.uuid(),
            site: 'Data Center',
            name: 'DC SSR 1300',
            sku: 'SSR 1300-1Y',
            bandwidthMbps: 5000,
            annualBWGrowth: 0.05,
            quantity: 2,
        },
        {
            id: faker.string.uuid(),
            site: 'Azure East/West',
            name: 'Azure AIWAN',
            sku: 'AIWAN-P1-2500-1-1Y',
            bandwidthMbps: 2500,
            annualBWGrowth: 0.05,
            quantity: 2,
        },
        {
            id: faker.string.uuid(),
            site: 'Micro Branch',
            name: 'Micro Branch SSR 120-AE',
            sku: 'SSR 120-AE-1Y',
            bandwidthMbps: 10,
            annualBWGrowth: 0.05,
            quantity: 1,
        },
        {
            id: faker.string.uuid(),
            site: 'UK Site',
            name: 'UK SSR 130-AE',
            sku: 'SSR 130-AE-1Y',
            bandwidthMbps: 500,
            annualBWGrowth: 0.05,
            quantity: 1,
        },
    ];
}

function createDemoSiteInput(): ISiteInput[] {
    return [
        {
            id: faker.string.uuid(),
            site: 'Small Branch',
            quantity: 1,
            country: 'USA',
            address: null,
            timeZone: null,
            siteGroups: null,
        },
        {
            id: faker.string.uuid(),
            site: 'Medium Site No HA',
            quantity: 1,
            country: 'USA',
            address: null,
            timeZone: null,
            siteGroups: null,
        },
        {
            id: faker.string.uuid(),
            site: 'Medium Site HA',
            quantity: 1,
            country: 'USA',
            address: null,
            timeZone: null,
            siteGroups: null,
        },
        {
            id: faker.string.uuid(),
            site: 'Large Corporate Site',
            quantity: 1,
            country: 'USA',
            address: null,
            timeZone: null,
            siteGroups: null,
        },
        {
            id: faker.string.uuid(),
            site: 'Data Center',
            quantity: 1,
            country: 'USA',
            address: null,
            timeZone: null,
            siteGroups: null,
        },
        {
            id: faker.string.uuid(),
            site: 'Azure East/West',
            quantity: 1,
            country: 'USA',
            address: null,
            timeZone: null,
            siteGroups: null,
        },
        {
            id: faker.string.uuid(),
            site: 'Micro Branch',
            quantity: 1,
            country: 'USA',
            address: null,
            timeZone: null,
            siteGroups: null,
        },
        {
            id: faker.string.uuid(),
            site: 'UK Site',
            quantity: 5,
            country: 'UK',
            address: null,
            timeZone: null,
            siteGroups: null,
        },
    ];
}
