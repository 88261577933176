import { AppProvider } from '../main-provider.tsx';
import { DigitalTwinRouter } from './routes.tsx';

const DigitalTwinApp = () => {
    return (
        <AppProvider>
            <DigitalTwinRouter />
        </AppProvider>
    );
};

export default DigitalTwinApp;
