import { ChangeEvent, ComponentProps } from 'react';

import { removeLeadingZeros } from '@bae/utils';

import { Input } from '@/components/ui/input.tsx';
import { cn } from '@/lib/utils.ts';

const FloatNumberInputCell = <T,>({
    value,
    onChange,
    accessorKey,
    index,
    className,
}: {
    value: number;
    onChange: <K extends keyof T>(index: number, key: K, value: T[K]) => void;
    accessorKey: keyof T;
    index: number;
    className?: ComponentProps<'div'>['className'];
}) => {
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            onChange(index, accessorKey, 0 as T[keyof T]);
            return;
        }

        onChange(index, accessorKey, parseFloat(e.target.value) as T[keyof T]);
    };

    return (
        <td className='h-[36px] rounded-md border bg-white p-2 text-center'>
            <Input
                onChange={handleInputChange}
                value={removeLeadingZeros(value)}
                className={cn(
                    'h-6 w-full rounded-sm border-none text-center',
                    className,
                    '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none',
                    '[&::-webkit-inner-spin-button]:appearance-none',
                )}
                type='number'
            />
        </td>
    );
};

export default FloatNumberInputCell;
