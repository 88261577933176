import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ResourceAvailability } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { resourceAvailabilityAdapter } from '../adapters.ts';

type AddResourceAvailabilityParams = {
    modelId: string;
    type: 'model' | 'library';
    data: ResourceAvailability;
};

const addResourceAvailability = async ({
    modelId,
    type,
    data,
}: AddResourceAvailabilityParams): Promise<ResourceAvailability> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/appavailability`;

    const payload = resourceAvailabilityAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return data;
};

type UseAddResourceAvailabilityOptions = {
    mutationConfig?: MutationConfig<typeof addResourceAvailability>;
};

export const useAddResourceAvailability = ({
    mutationConfig,
}: UseAddResourceAvailabilityOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: addResourceAvailability,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
