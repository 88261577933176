import { useCurrency } from '@bae/store';
import { formatCurrencySymbol } from '@bae/utils';

import { Skeleton } from '@/components/ui/skeleton.tsx';

import { TableBodyRow, TableHeadRow } from '../../shared/TableComponents';
import {
    SelectCell,
    TextInputCell,
    FloatNumberInputCell,
} from '../../shared/TableComponents/CellComponents';
import { Expense, EXPENSE_TYPE, RECURRING } from '../../shared/types.ts';
import { getCloudExpenses, useCloudRebalancingStore } from '../store';

const getExpenseLabels = (currencySign: string = '$') => ({
    expenseName: { label: 'Expense', isEditable: true },
    recurring: { label: 'Recurring', isEditable: true },
    type: { label: 'Type', isEditable: true },
    value: { label: `Value (${currencySign})`, isEditable: true },
});

const CloudExpenses = () => {
    const { currency } = useCurrency();

    const cloudExpenses = useCloudRebalancingStore(getCloudExpenses);
    const updateCloudExpenses = useCloudRebalancingStore(
        (state) => state.updateCloudExpense,
    );
    const loading = cloudExpenses.length === 0;

    const currencySign = formatCurrencySymbol({
        currency,
        onlySymbol: true,
    });
    const expenseLabels = getExpenseLabels(currencySign);

    return (
        <div className='w-full overflow-x-auto'>
            <table className='w-full table-fixed border-separate border-spacing-2 text-sm'>
                <thead>
                    {loading ? (
                        <SkeletonHeader />
                    ) : (
                        <TableHeadRow>
                            {Object.keys(expenseLabels).map((key) => (
                                <th key={key} className='text-center'>
                                    <p className='whitespace-nowrap'>
                                        {
                                            expenseLabels[key as keyof Expense]
                                                .label
                                        }
                                    </p>
                                </th>
                            ))}
                        </TableHeadRow>
                    )}
                </thead>
                <tbody>
                    {loading ? (
                        <SkeletonRow />
                    ) : (
                        cloudExpenses.map((expense, index) => {
                            const isServerExpense = index === 1;
                            return (
                                <TableBodyRow key={index}>
                                    <TextInputCell<Expense>
                                        value={expense.expenseName}
                                        onChange={updateCloudExpenses}
                                        accessorKey={'expenseName'}
                                        index={index}
                                        isEditable={!isServerExpense}
                                        className='min-w-[200px]'
                                    />
                                    <SelectCell<Expense>
                                        value={expense.recurring}
                                        options={Object.values(RECURRING)}
                                        index={index}
                                        accessorKey={'recurring'}
                                        onChange={updateCloudExpenses}
                                    />
                                    <SelectCell<Expense>
                                        value={expense.type}
                                        options={Object.values(EXPENSE_TYPE)}
                                        index={index}
                                        accessorKey={'type'}
                                        onChange={updateCloudExpenses}
                                    />
                                    <FloatNumberInputCell<Expense>
                                        value={expense.value}
                                        onChange={updateCloudExpenses}
                                        accessorKey={'value'}
                                        index={index}
                                    />
                                </TableBodyRow>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
};

const SkeletonHeader = () => {
    return (
        <TableHeadRow>
            <th>Expense Name</th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
        </TableHeadRow>
    );
};

const SkeletonRow = () => {
    const arr = Array.from({ length: 4 }, (_, i) => i);
    return (
        <TableBodyRow>
            <td>
                <Skeleton />
            </td>
            {arr.map((col) => (
                <td key={col}>
                    <Skeleton />
                </td>
            ))}
        </TableBodyRow>
    );
};

export default CloudExpenses;
