import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { CpeSpecs } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { cpeAdapter, getDefaultSpecs } from './adapters.ts';

type AddCpeParams = {
    modelId: string;
    type: 'model' | 'library';
    cpeName: string;
};

const addCpe = async ({
    modelId,
    type,
    cpeName,
}: AddCpeParams): Promise<CpeSpecs> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/cpeconfig`;

    const data = getDefaultSpecs(cpeName);

    const payload = cpeAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return data;
};

type UseAddCpeOptions = {
    mutationConfig?: MutationConfig<typeof addCpe>;
};

export const useAddCpe = ({ mutationConfig }: UseAddCpeOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: addCpe,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
