import {
    ServerCashflowInput,
    ServerDeploymentInput,
    ServerInput,
} from '@bae/api';
import {
    Server,
    ServerCashflow,
    ServersDeployment,
    ServerType,
} from '@bae/data-interface';
import { isNullOrUndefined } from '@bae/utils';

export const getServerDefaultSpecs = (serverName: string): Server => ({
    serverName,
    type: ServerType.compute,
    vcpu: 16,
    memory: 64,
    power: 1,
    ru: 2,
    decommissionTime: 0,
    description: '',
});

export const serverAdapter = (data: Server): ServerInput => {
    return {
        name: data.serverName,
        servertype: data.type,
        vcpu: data.vcpu,
        memory: data.memory,
        kwatt: data.power,
        ru: data.ru,
        decommissiontime: data.decommissionTime,
        description: data.description,
    };
};

export const serverDeploymentAdapter = (
    data: ServersDeployment,
): ServerDeploymentInput => {
    return {
        dcname: data.datacenter,
        servername: data.serverName,
        quantity: data.quantity,
        cagr: isNullOrUndefined(data.quantityGrowth) ? 0 : data.quantityGrowth,
    };
};

export const serverCashFlowAdapter = (
    data: ServerCashflow,
): ServerCashflowInput => {
    const validValue = isNullOrUndefined(data.value) ? 0 : data.value;

    const mapDrivers: Record<string, 'server' | 'kwh' | 'ru'> = {
        unit: 'server',
        power: 'kwh',
        ru: 'ru',
    };

    return {
        datacenter: data.datacenter,
        flowname: data.flowName,
        flowtype: data.flowType,
        recurring: data.recurring,
        servername: data.serverName,
        value_cagr: data.valueGrowth,
        value: validValue,
        per: mapDrivers[data.driver],
        active: true,
        discount: isNullOrUndefined(data.discount) ? 0 : data.discount,
    };
};

export const createDeleteParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
): Record<string, string>[] => {
    return data.map((datum) => {
        const datumParams: Record<string, string> = {};

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams[apiKey] = datum[moduleKey];
        });

        return datumParams;
    });
};

export const createCloneQueryParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
) =>
    data.map((datum) => {
        const datumParams = {
            source: {},
            target: {},
        };

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams.source[apiKey] = datum[moduleKey];
            datumParams.target[apiKey] = `Copy of ${datum[moduleKey]}`;
        });

        return datumParams;
    });
