export const MICRODATA_TABS: { text: string; to: string }[] = [
    {
        text: 'Tenants',
        to: 'microdata/tenants',
    },
    {
        text: 'Datacenters',
        to: 'microdata/datacenters',
    },
    {
        text: 'Endpoints',
        to: 'microdata/endpoints',
    },
    {
        text: 'Services',
        to: 'microdata/svcusage',
    },
    {
        text: 'Service Cash Flows',
        to: 'microdata/servicecf',
    },
    {
        text: 'DC App Availability',
        to: 'microdata/appavail',
    },
    {
        text: 'DC App Connections',
        to: 'microdata/appconnections',
    },
    {
        text: 'DC App Demand',
        to: 'microdata/appdemand',
    },
    {
        text: 'DC App Licenses',
        to: 'microdata/applicenses',
    },
    {
        text: 'DC App Cash Flows',
        to: 'microdata/dcappcf',
    },
    {
        text: 'CPE Hardware',
        to: 'microdata/cpehw',
    },
    {
        text: 'CPE Cash Flows',
        to: 'microdata/cpecf',
    },
    {
        text: 'CPE Licenses',
        to: 'microdata/cpeapps',
    },
    {
        text: 'CPE App Cash Flows',
        to: 'microdata/cpeappcf',
    },
    {
        text: 'Servers',
        to: 'microdata/serverusage',
    },
    {
        text: 'Server Cash Flows',
        to: 'microdata/servercf',
    },
    {
        text: 'Fixed FTE Cash Flows',
        to: 'microdata/fixedftecashflows',
    },
    {
        text: 'Dynamic FTE Cash Flows',
        to: 'microdata/dynamicftecashflows',
    },
] as const;
