import {
    ServiceCashFlowConfigInput,
    ServiceConnectionsInput,
    ServiceConnectionsTimeseriesInput,
    ServiceDemandInput,
    ServiceDemandTimeseriesInput,
    ServicePlansInput,
    ServiceSpecsInput,
} from '@bae/api';
import {
    ServiceCashflow,
    ServiceConnection,
    ServiceConnectionTimeseries,
    ServiceDemand,
    ServiceDemandTimeseries,
    ServicePlan,
    ServiceSpecs,
} from '@bae/data-interface';

export const getDefaultSpecs = (serviceName: string) => ({
    serviceName,
    datavolumeUtilization: 1,
    datarateUtilization: 1,
    usersUtilization: 1,
    sessionsUtilization: 1,
    description: '',
});

export const getStandardPlan = (serviceName: string) => ({
    serviceName,
    name: 'standard',
    datavolume: null,
    datarate: null,
    users: null,
    sessions: null,
});

export const getDemandZeroTimeseries = (
    data: ServiceDemand,
): ServiceDemandTimeseries => {
    const zeroDeployment = data.deployment[0];

    return {
        serviceName: data.serviceName,
        tenantName: data.tenantName,
        endpointName: data.endpointName,
        datavolume: zeroDeployment.datavolume,
        datavolumeGrowth: zeroDeployment.datavolumeGrowth,
        datarate: zeroDeployment.datarate,
        datarateGrowth: zeroDeployment.datarateGrowth,
        users: zeroDeployment.users,
        usersGrowth: zeroDeployment.usersGrowth,
        sessions: zeroDeployment.sessions,
        sessionsGrowth: zeroDeployment.sessionsGrowth,
        delay: 0,
    };
};

export const getResourceZeroTimeseries = (
    data: ServiceConnection,
): ServiceConnectionTimeseries => {
    const zeroDeployment = data.deployment[0];

    return {
        serviceName: data.serviceName,
        applicationName: data.applicationName,
        location: data.location,
        datavolume: zeroDeployment.datavolume,
        datavolumeGrowth: zeroDeployment.datavolumeGrowth,
        datarate: zeroDeployment.datarate,
        datarateGrowth: zeroDeployment.datarateGrowth,
        users: zeroDeployment.users,
        usersGrowth: zeroDeployment.usersGrowth,
        sessions: zeroDeployment.sessions,
        sessionsGrowth: zeroDeployment.sessionsGrowth,
        delay: 0,
    };
};

export const serviceSpecAdapter = (data: ServiceSpecs): ServiceSpecsInput => {
    return {
        name: data.serviceName,
        datavolume_utilization: data.datavolumeUtilization,
        datarate_utilization: data.datarateUtilization,
        users_utilization: data.usersUtilization,
        sessions_utilization: data.sessionsUtilization,
        description: data.description,
    };
};

export const serviceResourceAdapter = (
    data: ServiceConnection,
): ServiceConnectionsInput => {
    return {
        svcname: data.serviceName,
        appname: data.applicationName,
        location: data.location,
        multitenant: data.multitenant,
    };
};

export const serviceResourceDeploymentAdapter = (
    data: ServiceConnectionTimeseries,
): ServiceConnectionsTimeseriesInput => {
    return {
        appname: data.applicationName,
        svcname: data.serviceName,
        location: data.location,
        datavolume: data.datavolume,
        datavolume_growth: data.datavolumeGrowth,
        datarate: data.datarate,
        datarate_growth: data.datarateGrowth,
        users: data.users,
        users_growth: data.usersGrowth,
        sessions: data.sessions,
        sessions_growth: data.sessionsGrowth,
        delay: data.delay,
    };
};

export const servicePlanAdapter = (data: ServicePlan): ServicePlansInput => {
    return {
        ...data,
        svcname: data.serviceName,
        planname: data.name,
    };
};

export const serviceDemandAdapter = (
    data: ServiceDemand,
): ServiceDemandInput => {
    return {
        tenantname: data.tenantName,
        endpointname: data.endpointName,
        svcname: data.serviceName,
        svcplan: data.servicePlan,
    };
};

export const serviceDemandDeploymentAdapter = (
    data: ServiceDemandTimeseries,
): ServiceDemandTimeseriesInput => {
    return {
        tenantname: data.tenantName,
        endpointname: data.endpointName,
        svcname: data.serviceName,
        datavolume: data.datavolume,
        datavolume_growth: data.datavolumeGrowth,
        datarate: data.datarate,
        datarate_growth: data.datarateGrowth,
        users: data.users,
        users_growth: data.usersGrowth,
        sessions: data.sessions,
        sessions_growth: data.sessionsGrowth,
        delay: data.delay,
    };
};

export const serviceCashflowAdapter = (
    data: ServiceCashflow,
): ServiceCashFlowConfigInput => {
    return {
        svcname: data.serviceName,
        svcplan: data.plan,
        flowname: data.flowName,
        recurring: data.recurring,
        flowtype: data.flowType,
        value: data.value,
        per: data.driver,
        value_cagr: data.valueGrowth,
        discount: data.discount,
        active: true,
    };
};

export const createDeleteParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
): Record<string, string>[] => {
    return data.map((datum) => {
        const datumParams: Record<string, string> = {};

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams[apiKey] = datum[moduleKey];
        });

        return datumParams;
    });
};

export const createCloneQueryParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
) =>
    data.map((datum) => {
        const datumParams = {
            source: {},
            target: {},
        };

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams.source[apiKey] = datum[moduleKey];
            datumParams.target[apiKey] = `Copy of ${datum[moduleKey]}`;
        });

        return datumParams;
    });
