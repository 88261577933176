export const PORT_TYPE_OPTIONS = [
    {
        value: 'dedicated',
        label: 'Dedicated',
    },
    {
        value: 'shared',
        label: 'Shared',
    },
] as const;

export const VPN_TYPE_OPTIONS = [
    {
        value: 'international',
        label: 'International',
    },
    {
        value: 'domestic',
        label: 'Domestic',
    },
] as const;

export const COUNTRY_OPTIONS = [
    {
        value: 'argentina',
        label: 'Argentina',
    },
    {
        value: 'australia',
        label: 'Australia',
    },
    {
        value: 'austria',
        label: 'Austria',
    },
    {
        value: 'belgium',
        label: 'Belgium',
    },
    {
        value: 'brazil',
        label: 'Brazil',
    },
    {
        value: 'canada',
        label: 'Canada',
    },
    {
        value: 'china',
        label: 'China',
    },
    {
        value: 'colombia',
        label: 'Colombia',
    },
    {
        value: 'czech_republic',
        label: 'Czech Republic',
    },
    {
        value: 'denmark',
        label: 'Denmark',
    },
    {
        value: 'finland',
        label: 'Finland',
    },
    {
        value: 'france',
        label: 'France',
    },
    {
        value: 'germany',
        label: 'Germany',
    },
    {
        value: 'greece',
        label: 'Greece',
    },
    {
        value: 'hong_kong',
        label: 'Hong Kong',
    },
    {
        value: 'hungary',
        label: 'Hungary',
    },
    {
        value: 'india',
        label: 'India',
    },
    {
        value: 'indonesia',
        label: 'Indonesia',
    },
    {
        value: 'ireland',
        label: 'Ireland',
    },
    {
        value: 'italy',
        label: 'Italy',
    },
    {
        value: 'japan',
        label: 'Japan',
    },
    {
        value: 'korea',
        label: 'Korea',
    },
    {
        value: 'luxembourg',
        label: 'Luxembourg',
    },
    {
        value: 'malaysia',
        label: 'Malaysia',
    },
    {
        value: 'mexico',
        label: 'Mexico',
    },
    {
        value: 'netherlands',
        label: 'Netherlands',
    },
    {
        value: 'new_zealand',
        label: 'New Zealand',
    },
    {
        value: 'norway',
        label: 'Norway',
    },
    {
        value: 'philippines',
        label: 'Philippines',
    },
    {
        value: 'poland',
        label: 'Poland',
    },
    {
        value: 'portugal',
        label: 'Portugal',
    },
    {
        value: 'romania',
        label: 'Romania',
    },
    {
        value: 'russian_federation',
        label: 'Russian Federation',
    },
    {
        value: 'singapore',
        label: 'Singapore',
    },
    {
        value: 'south_africa',
        label: 'South Africa',
    },
    {
        value: 'spain',
        label: 'Spain',
    },
    {
        value: 'sweden',
        label: 'Sweden',
    },
    {
        value: 'switzerland',
        label: 'Switzerland',
    },
    {
        value: 'taiwan',
        label: 'Taiwan',
    },
    {
        value: 'thailand',
        label: 'Thailand',
    },
    {
        value: 'turkey',
        label: 'Turkey',
    },
    {
        value: 'united_arab_emirates',
        label: 'United Arab Emirates',
    },
    {
        value: 'united_kingdom',
        label: 'United Kingdom',
    },
    {
        value: 'united_states',
        label: 'United States',
    },
] as const;

export const AWS_REGION_OPTIONS = [
    {
        value: 'usa_aws_egress',
        label: 'USA AWS Egress',
    },
    {
        value: 'canada_aws_egress',
        label: 'Canada AWS Egress',
    },
    {
        value: 'europe_aws_egress',
        label: 'Europe AWS Egress',
    },
    {
        value: 'apac_tokyo_osaka_aws_egress',
        label: 'APAC (Tokyo, Osaka) AWS Egress',
    },
    {
        value: 'apac_seoul_singapore_hong_kong_bangkok_aws_egress',
        label: 'APAC (Seoul, Singapore, Hong Kong, Bangkok) AWS Egress',
    },
    {
        value: 'apac_indonesia_aws_egress',
        label: 'APAC (Indonesia) AWS Egress',
    },
    {
        value: 'apac_manila_aws_egress',
        label: 'APAC (Manila) AWS Egress',
    },
    {
        value: 'apac_india_aws_egress',
        label: 'APAC (India) AWS Egress',
    },
    {
        value: 'south_america_sao_paulo_mexico_aws_egress',
        label: 'South America (Sao Paulo,  Mexico) AWS Egress',
    },
    {
        value: 'apac_australia_auckland_aws_egress',
        label: 'APAC (Australia, Auckland) AWS Egress',
    },
    {
        value: 'middle_east_aws_egress',
        label: 'Middle East (Bahrain, Israel, UAE) AWS Egress',
    },
    {
        value: 'africa_aws_egress',
        label: 'Africa (Cape Town, Lagos) AWS Egress',
    },
] as const;

export const GCP_REGION_OPTIONS = [
    {
        value: 'north_america',
        label: 'North America',
    },
    {
        value: 'mexico',
        label: 'Mexico',
    },
    {
        value: 'europe',
        label: 'Europe',
    },
    {
        value: 'middle_east',
        label: 'Middle East',
    },
    {
        value: 'africa',
        label: 'Africa',
    },
    {
        value: 'asia',
        label: 'Asia',
    },
    {
        value: 'indonesia',
        label: 'Indonesia',
    },
    {
        value: 'india',
        label: 'India',
    },
    {
        value: 'australia',
        label: 'Australia',
    },
    {
        value: 'south_america',
        label: 'South America',
    },
] as const;

export const AZURE_ZONE_OPTIONS = [
    {
        value: 'zone_1_dto',
        label: 'Zone 1 DTO',
    },
    {
        value: 'zone_2_dto',
        label: 'Zone 2 DTO',
    },
    {
        value: 'zone_3_dto',
        label: 'Zone 3 DTO',
    },
    {
        value: 'zone_4_dto',
        label: 'Zone 4 DTO',
    },
] as const;

export const GF_SERVICE_INTERCONNECTION_OPTIONS = [
    {
        value: 'vpn',
        label: 'VPN',
    },
    {
        value: 'elan',
        label: 'ELAN',
    },
] as const;

export const SERVICE_DISCOUNT_OPTIONS = [
    {
        value: 'dedicated_port',
        label: 'Dedicated Port',
    },
    {
        value: 'shared_port',
        label: 'Shared Port',
    },
    {
        value: 'ip_vpn',
        label: 'IP VPN',
    },
    {
        value: 'internet',
        label: 'Internet',
    },
    {
        value: 'eline',
        label: 'Eline',
    },
    {
        value: 'elan',
        label: 'ELAN',
    },
    {
        value: 'aws',
        label: 'AWS',
    },
    {
        value: 'azure',
        label: 'Azure',
    },
    {
        value: 'gcp',
        label: 'GCP',
    },
] as const;
