import { z } from 'zod';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation } from '@tanstack/react-query';

import { AuthAPIRoute, AxiosAPI, getToken } from '@bae/api';

const editUserNameSchema = z.object({
    name: z.string(),
});

export type EditUserNameInput = z.infer<typeof editUserNameSchema>;

const editUserName = (payload: EditUserNameInput): Promise<void> => {
    const options = {
        headers: { Authorization: 'Bearer ' + getToken(true) },
    };

    return AxiosAPI.axiosInstance.patch(
        AuthAPIRoute.cognitoUser(),
        payload,
        options,
    );
};

type UseEditUserNameOptions = {
    mutationConfig?: MutationConfig<typeof editUserName>;
};

export const useEditUserName = ({ mutationConfig }: UseEditUserNameOptions) => {
    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess,
        ...restConfig,
        mutationFn: editUserName,
    });
};
