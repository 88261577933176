import { DatacenterInput, DataCenterLabelInput } from '@bae/api';
import { Datacenter, DatacenterLabel } from '@bae/data-interface';

export const dataCenterAdapter = (data: Datacenter): DatacenterInput => {
    return {
        name: data.name,
        latitude: data.latitude,
        longitude: data.longitude,
        kind: 'physical',
        quantity: data.quantityInitial,
        terminal_quantity: data.quantityTerminal,
        quantity_cagr: data.quantityCagr,
        delay: data.delay,

        default_power_cost: data.powerCost,
        default_power_cost_cagr: data.powerCostCagr,
        default_floorspace_cost: data.floorspaceCost,
        default_floorspace_cost_cagr: data.floorspaceCostCagr,
    };
};

export const labelAdapter = (data: DatacenterLabel): DataCenterLabelInput => {
    return {
        dcname: data.datacenter,
        labelname: data.label,
    };
};

export const createDeleteParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
): Record<string, string>[] => {
    return data.map((datum) => {
        const datumParams: Record<string, string> = {};

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams[apiKey] = datum[moduleKey];
        });

        return datumParams;
    });
};
