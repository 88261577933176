export enum RECURRING {
    NONE = 'None',
    MONTHLY = 'Monthly',
    YEARLY = 'Yearly',
}

export enum EXPENSE_TYPE {
    CAPEX = 'CapEx',
    OPEX = 'OpEx',
}

export interface Expense {
    expenseName: string;
    recurring: RECURRING;
    type: EXPENSE_TYPE;
    value: number;
}

export interface EgressExpense {
    expenseName: string;
    size: number;
    value: number;
}

export interface Server {
    serverName: string;
    vcpu: number;
    memory: number;
}

export interface FTE {
    fteName: string;
    annualExpense: number;
    annualGrowth: number;
    quantity: number;
}

export interface InternetEgress {
    expenseName: string;
    recurring: RECURRING;
    type: EXPENSE_TYPE;
    dataEgressCost: number;
    notes: string | null;
}

export interface MiscellaneousCosts {
    expenseName: string;
    recurring: RECURRING;
    type: EXPENSE_TYPE;
    value: number;
    notes: string | null;
}

export interface InputParameters {
    dateStart: string; // YYYY-MM-DD
    periods: number; // months
    capitalCost: number; // float between 0 and 1
    depreciationTime: number; // months
}

export interface Instance {
    type: string;
    quantity: number;
    growth: number;
    vcpu: number;
    memory: number;
    dataEgress: number;
    storage: number;
}
