import React from 'react';
import {
    Column,
    DataSheetGrid,
    intColumn,
    keyColumn,
    textColumn,
} from 'react-datasheet-grid';

import { Button } from '@/components/ui/button.tsx';

import MemoizedSelectorComponent from '../components/MemoizedSelectorComponent.tsx';
import { SITE_OPTIONS } from '../constants.ts';
import { selectAccessPointsInput, useNetworkStore } from '../store.ts';
import type { IAccessPointInput } from '../types.ts';

const columns: Column<IAccessPointInput>[] = [
    {
        ...keyColumn<IAccessPointInput, 'site'>('site', textColumn),
        title: 'Site',
        minWidth: 200,
        maxWidth: 300,
        component: (props) => (
            <MemoizedSelectorComponent<IAccessPointInput>
                {...props}
                columnKey='site'
                options={
                    SITE_OPTIONS as unknown as Array<{
                        value: string;
                        label: string;
                    }>
                }
            />
        ),
    },
    {
        ...keyColumn<IAccessPointInput, 'name'>('name', textColumn),
        title: 'Name',
        minWidth: 200,
    },
    {
        ...keyColumn<IAccessPointInput, 'sku'>('sku', textColumn),
        title: 'SKU',
        minWidth: 120,
        maxWidth: 180,
    },
    {
        ...keyColumn<IAccessPointInput, 'quantity'>('quantity', intColumn),
        title: 'Quantity',
        minWidth: 100,
        maxWidth: 100,
    },
];

const createEmptyNewRows = (numberOfRows: number): IAccessPointInput[] => {
    const arr: IAccessPointInput[] = [];

    for (let i = 0; i < numberOfRows; i++) {
        arr.push({
            id: '',
            site: null,
            name: null,
            sku: null,
            quantity: null,
        });
    }

    return arr;
};

const AccessPointsDataGrid = () => {
    const setData = useNetworkStore((state) => state.setAccessPointInput);
    const data = useNetworkStore(selectAccessPointsInput);

    return (
        <div className='relative'>
            <DataSheetGrid
                headerRowHeight={50}
                createRow={() => createEmptyNewRows(1)[0]}
                value={data}
                columns={columns}
                onChange={setData}
                rowKey='id'
            />
            {(!data || data.length === 0) && (
                <div className='absolute bottom-0 left-0 right-0 top-0 z-[55] flex h-[74dvh] w-full flex-col items-center justify-center gap-4 rounded-md bg-newDesign-black bg-opacity-20 text-xl font-bold'>
                    No Access Points available
                    <Button
                        onClick={() => {
                            setData(createEmptyNewRows(1));
                        }}>
                        Create
                    </Button>
                </div>
            )}
        </div>
    );
};

export default AccessPointsDataGrid;
