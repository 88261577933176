import { z } from 'zod';

import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { Library, LibraryComponent } from '@bae/data-interface';

import { getComponentAdapter } from '@/features/library/api/adapters.ts';

type IComponent = 'services' | 'applications' | 'servers' | 'ftes' | 'cpes';

const getLibrarySchema = z.object({
    libraries: z.array(
        z.object({
            library_id: z.string(),
            library_name: z.string(),
            organization_name: z.string(),
            library_description: z.string(),
            readonly: z.boolean(),
            owner: z.boolean(),
        }),
    ),
});

export type IGetLibraryApi = z.infer<typeof getLibrarySchema>;

const getLibraries = async (): Promise<Library[]> => {
    const response = await AxiosAPIv2.axiosInstance.get<IGetLibraryApi>(
        '/libraries',
        { headers: { Authorization: 'Bearer ' + getToken() } },
    );

    return response.data.libraries.map((d) => ({
        id: d.library_id,
        name: d.library_name,
        organization: d.organization_name,
        description: d.library_description,
        readonly: d.readonly,
        owner: d.owner,
    }));
};

const getLibraryComponent = async (
    component: IComponent,
): Promise<LibraryComponent[]> => {
    const adapter = getComponentAdapter(component);

    const response = await AxiosAPIv2.axiosInstance.get(
        `/libraries/${component}`,
        { headers: { Authorization: 'Bearer ' + getToken() } },
    );

    return adapter(response.data);
};

export const getLibrariesQueryOptions = () => {
    return queryOptions({
        queryKey: ['libraries'],
        queryFn: () => getLibraries(),
    });
};

export const getLibraryComponentQueryOptions = (component: IComponent) => {
    return queryOptions({
        queryKey: ['libraries', component],
        queryFn: () => getLibraryComponent(component),
    });
};

type UseLibrariesOptions = {
    queryConfig?: QueryConfig<typeof getLibraries>;
    refetchInterval?: number | false;
    component: IComponent;
    enabled?: boolean;
};

export const useLibraryComponent = ({
    queryConfig,
    refetchInterval,
    component,
    enabled = true,
}: UseLibrariesOptions) => {
    return useQuery({
        ...getLibraryComponentQueryOptions(component),
        ...queryConfig,
        refetchInterval,
        enabled,
    });
};

export const useLibraries = ({
    queryConfig,
    refetchInterval,
}: Omit<UseLibrariesOptions, 'component'>) => {
    return useQuery({
        ...getLibrariesQueryOptions(),
        ...queryConfig,
        refetchInterval,
    });
};
