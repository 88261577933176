import { LibraryComponent } from '@bae/data-interface';

interface GetServicesApi {
    services: Array<{
        library_id: string;
        service_name: string;
        service_description: string;
    }>;
}

interface GetResourcesApi {
    applications: Array<{
        library_id: string;
        application_name: string;
        application_description: string;
    }>;
}

interface GetServersApi {
    servers: Array<{
        library_id: string;
        server_name: string;
        server_description: string;
    }>;
}

interface GetCpesApi {
    cpes: Array<{
        library_id: string;
        cpe_name: string;
        cpe_description: string;
    }>;
}

interface GetFtesApi {
    ftes: Array<{
        library_id: string;
        fte_name: string;
        fte_description: string;
    }>;
}

const servicesLibraryAdapter = (data: GetServicesApi): LibraryComponent[] =>
    data.services.map((d) => ({
        libraryId: d.library_id,
        name: d.service_name,
        description: d.service_description || '',
    }));

const resourcesLibraryAdapter = (data: GetResourcesApi): LibraryComponent[] =>
    data.applications.map((d) => ({
        libraryId: d.library_id,
        name: d.application_name,
        description: d.application_description || '',
    }));

const serversLibraryAdapter = (data: GetServersApi): LibraryComponent[] =>
    data.servers.map((d) => ({
        libraryId: d.library_id,
        name: d.server_name,
        description: d.server_description || '',
    }));

const cpesLibraryAdapter = (data: GetCpesApi): LibraryComponent[] =>
    data.cpes.map((d) => ({
        libraryId: d.library_id,
        name: d.cpe_name,
        description: d.cpe_description || '',
    }));

const ftesLibraryAdapter = (data: GetFtesApi): LibraryComponent[] =>
    data.ftes.map((d) => ({
        libraryId: d.library_id,
        name: d.fte_name,
        description: d.fte_description || '',
    }));

export const getComponentAdapter = (
    component: 'services' | 'applications' | 'servers' | 'cpes' | 'ftes',
) => {
    switch (component) {
        case 'services':
            return servicesLibraryAdapter;
        case 'applications':
            return resourcesLibraryAdapter;
        case 'servers':
            return serversLibraryAdapter;
        case 'cpes':
            return cpesLibraryAdapter;
        case 'ftes':
            return ftesLibraryAdapter;
    }
};
