import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { FteVariableDeployment } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { dynamicDeploymentAdapter } from '../adapters.ts';

type EditDynamicDeploymentParams = {
    modelId: string;
    type: 'model' | 'library';
    prevData: FteVariableDeployment;
    nextData: FteVariableDeployment;
};

const editDynamicDeployment = async ({
    modelId,
    type,
    prevData,
    nextData,
}: EditDynamicDeploymentParams): Promise<FteVariableDeployment> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/fte_dynamic_deployment`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const params = {
        fte_name: prevData.fteName,
        flow_name: prevData.flowName,
        model_id: modelId,
    };

    const config: AxiosRequestConfig = {
        headers,
        params,
    };

    const payload = dynamicDeploymentAdapter(nextData);

    await AxiosAPIv2.axiosInstance.patch(url, payload, config);

    return nextData;
};

type UseEditDynamicDeploymentOptions = {
    mutationConfig?: MutationConfig<typeof editDynamicDeployment>;
};

export const useEditDynamicFteDeployment = ({
    mutationConfig,
}: UseEditDynamicDeploymentOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: editDynamicDeployment,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
