import { MutationConfig } from '@/libs/api/src/lib/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { Endpoint, EndpointDeployment } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { endpointAdapter } from './adapters.ts';
import { useAddEndpointDeployment } from './delayed-deployment/add-endpoint-timeseries.ts';

const getDefaultDeployment = (endpoint: Endpoint): EndpointDeployment => ({
    tenantName: endpoint.tenantName,
    endpointName: endpoint.endpointName,
    quantityInitial: 1,
    quantityTerminal: 1,
    quantityGrowth: null,
    delay: 0,
});

type AddEndpointParams = {
    modelId: string;
    type: 'model' | 'library';
    data: Endpoint;
};

const addEndpoint = async ({
    modelId,
    type,
    data,
}: AddEndpointParams): Promise<Endpoint> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/endpoints`;

    const payload = endpointAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return data;
};

type UseAddEndpointOptions = {
    mutationConfig?: MutationConfig<typeof addEndpoint>;
};

export const useAddEndpoint = ({
    mutationConfig,
}: UseAddEndpointOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    const addEndpointDeploymentQuery = useAddEndpointDeployment({
        invalidate: false,
    });

    return useMutation({
        mutationFn: addEndpoint,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await addEndpointDeploymentQuery.mutateAsync({
                modelId,
                type,
                data: getDefaultDeployment(data),
            });

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
