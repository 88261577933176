import { z } from 'zod';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import { getLibrariesQueryOptions } from './get-libraries.ts';

export const addLibrarySchema = z.object({
    name: z.string(),
    file: z.custom<File>().optional(),
});

export type IAddLibraryInput = z.infer<typeof addLibrarySchema>;

const addLibrary = async ({
    name,
    file,
}: IAddLibraryInput): Promise<string> => {
    const formData: FormData = new FormData();
    if (file) formData.append('inputfile', file, file.name);

    formData.append('library_name', name);

    const response = await AxiosAPIv2.axiosInstance.post<{
        library_id: string;
    }>(`/libraries`, formData, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return response.data.library_id;
};

type UseAddLibraryOptions = {
    mutationConfig?: MutationConfig<typeof addLibrary>;
};

export const useAddLibrary = ({ mutationConfig }: UseAddLibraryOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: (...args) => {
            queryClient.invalidateQueries({
                queryKey: getLibrariesQueryOptions().queryKey,
            });
            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: addLibrary,
    });
};
