import { z } from 'zod';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import { getLibrariesQueryOptions } from './get-libraries.ts';

export const deleteLibrarySchema = z.object({
    id: z.string(),
});

export type IDeleteLibraryInput = z.infer<typeof deleteLibrarySchema>;

const deleteLibrary = async ({ id }: IDeleteLibraryInput) => {
    return AxiosAPIv2.axiosInstance.delete(`/libraries/${id}`, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });
};

type UseDeleteLibraryOptions = {
    mutationConfig?: MutationConfig<typeof deleteLibrary>;
};

export const useDeleteLibrary = ({
    mutationConfig,
}: UseDeleteLibraryOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: async (...args) => {
            await queryClient.invalidateQueries({
                queryKey: getLibrariesQueryOptions().queryKey,
            });
            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: deleteLibrary,
    });
};
