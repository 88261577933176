import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { Tenant, TenantDeployment } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { tenantAdapter } from './adapters.ts';
import { useEditTenantTimeSeries } from './delayed-deployment/edit-tenant-deployment.ts';

type EditTenantParams = {
    modelId: string;
    type: 'model' | 'library';
    prevData: Tenant;
    nextData: Tenant;
    deployment?: {
        prevData: TenantDeployment;
        nextData: TenantDeployment;
    };
};

const editTenant = async ({
    modelId,
    type,
    prevData,
    nextData,
}: EditTenantParams): Promise<Tenant> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/tenants`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const params = {
        name: prevData.name,
        model_id: modelId,
    };

    const config: AxiosRequestConfig = {
        headers,
        params,
    };

    const payload = tenantAdapter(nextData);

    await AxiosAPIv2.axiosInstance.patch(url, payload, config);

    return nextData;
};

type UseEditTenantOptions = {
    mutationConfig?: MutationConfig<typeof editTenant>;
};

export const useEditTenant = ({
    mutationConfig,
}: UseEditTenantOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    const editTenantTimeSeriesQuery = useEditTenantTimeSeries({
        invalidate: false,
    });

    return useMutation({
        mutationFn: editTenant,
        onSuccess: async (data, variables, context) => {
            const { modelId, type, deployment } = variables;

            if (deployment) {
                const updatedPrevData = {
                    ...deployment.prevData,
                    name: data.name,
                };
                const updatedNextData = {
                    ...deployment.nextData,
                    name: data.name,
                };

                await editTenantTimeSeriesQuery.mutateAsync({
                    modelId,
                    type,
                    prevData: updatedPrevData,
                    nextData: updatedNextData,
                });
            }

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
