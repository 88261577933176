import React from 'react';
import {
    Column,
    DataSheetGrid,
    floatColumn,
    keyColumn,
    percentColumn,
    textColumn,
} from 'react-datasheet-grid';

import MemoizedSelectorComponent from '../components/MemoizedSelectorComponent.tsx';
import {
    AZURE_ZONE_OPTIONS,
    COUNTRY_OPTIONS,
    GF_SERVICE_INTERCONNECTION_OPTIONS,
    PORT_TYPE_OPTIONS,
} from '../constants.ts';
import { selectAzureCloudPortInput, useNetworkStore } from '../store.ts';
import type { IAzureCloudPortInput } from '../types.ts';

const columns: Column<IAzureCloudPortInput>[] = [
    {
        ...keyColumn<IAzureCloudPortInput, 'siteName'>('siteName', textColumn),
        title: 'Site Name',
        minWidth: 200,
    },
    {
        ...keyColumn<IAzureCloudPortInput, 'dataEgressGBPerMonth'>(
            'dataEgressGBPerMonth',
            floatColumn,
        ),
        title: 'Data Egress (GB/Month)',
        minWidth: 120,
    },
    {
        ...keyColumn<IAzureCloudPortInput, 'egressGrowthRate'>(
            'egressGrowthRate',
            percentColumn,
        ),
        title: 'Egress Growth Rate',
        minWidth: 160,
        maxWidth: 160,
    },
    {
        ...keyColumn<IAzureCloudPortInput, 'portType'>('portType', textColumn),
        title: 'Port Type',
        minWidth: 120,
        maxWidth: 120,
        disableKeys: true,
        component: (props) => (
            <MemoizedSelectorComponent<IAzureCloudPortInput>
                {...props}
                columnKey='portType'
                options={
                    PORT_TYPE_OPTIONS as unknown as Array<{
                        value: string;
                        label: string;
                    }>
                }
            />
        ),
    },
    {
        ...keyColumn<IAzureCloudPortInput, 'portRedundant'>(
            'portRedundant',
            textColumn,
        ),
        title: 'Port Redundant',
        minWidth: 160,
        maxWidth: 160,
        component: (props) => (
            <MemoizedSelectorComponent<IAzureCloudPortInput>
                {...props}
                columnKey='portRedundant'
                options={[
                    { value: 'true', label: 'TRUE' },
                    { value: 'false', label: 'FALSE' },
                ]}
            />
        ),
    },
    {
        ...keyColumn<IAzureCloudPortInput, 'country'>('country', textColumn),
        title: 'Country',
        minWidth: 200,
        component: (props) => (
            <MemoizedSelectorComponent<IAzureCloudPortInput>
                {...props}
                columnKey='country'
                options={
                    COUNTRY_OPTIONS as unknown as Array<{
                        value: string;
                        label: string;
                    }>
                }
            />
        ),
    },
    {
        ...keyColumn<IAzureCloudPortInput, 'azureZone'>(
            'azureZone',
            textColumn,
        ),
        title: 'Azure Zone',
        minWidth: 120,
        component: (props) => (
            <MemoizedSelectorComponent<IAzureCloudPortInput>
                {...props}
                columnKey='azureZone'
                options={
                    AZURE_ZONE_OPTIONS as unknown as Array<{
                        value: string;
                        label: string;
                    }>
                }
            />
        ),
    },
    {
        ...keyColumn<IAzureCloudPortInput, 'gfServiceInterconnection'>(
            'gfServiceInterconnection',
            textColumn,
        ),
        title: 'GF Service Interconnection',
        minWidth: 120,
        maxWidth: 120,
        component: (props) => (
            <MemoizedSelectorComponent<IAzureCloudPortInput>
                {...props}
                columnKey='gfServiceInterconnection'
                options={
                    GF_SERVICE_INTERCONNECTION_OPTIONS as unknown as Array<{
                        value: string;
                        label: string;
                    }>
                }
            />
        ),
    },
];

const createEmptyNewRows = (numberOfRows: number): IAzureCloudPortInput[] => {
    const arr: IAzureCloudPortInput[] = [];

    for (let i = 0; i < numberOfRows; i++) {
        arr.push({
            id: '',
            siteName: null,
            portType: null,
            portRedundant: null,
            country: null,
            gfServiceInterconnection: null,
            azureZone: null,
        });
    }
    return arr;
};

const AzureCloudPortInputDataGrid = () => {
    const setData = useNetworkStore((state) => state.setAzureCloudPortInput);
    const data = useNetworkStore(selectAzureCloudPortInput);

    return (
        <DataSheetGrid
            headerRowHeight={50}
            createRow={() => createEmptyNewRows(1)[0]}
            value={data}
            columns={columns}
            onChange={setData}
            rowKey='id'
        />
    );
};

export default AzureCloudPortInputDataGrid;
