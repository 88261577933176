import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ResourceSpecs } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import {
    resourceAdapter,
    getResourceDefaultSpecs,
    getResourceStandardModel,
} from './adapters.ts';
import { useAddResourceModel } from './models/add-resource-model.ts';

type AddResourceParams = {
    modelId: string;
    type: 'model' | 'library';
    applicationName: string;
};

const addResource = async ({
    modelId,
    type,
    applicationName,
}: AddResourceParams): Promise<ResourceSpecs> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/appspecs`;

    const data = getResourceDefaultSpecs(applicationName);

    const payload = resourceAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return data;
};

type UseAddResourceOptions = {
    mutationConfig?: MutationConfig<typeof addResource>;
};

export const useAddResource = ({
    mutationConfig,
}: UseAddResourceOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    const addResourceModelQuery = useAddResourceModel({
        invalidate: false,
    });

    return useMutation({
        mutationFn: addResource,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await addResourceModelQuery.mutateAsync({
                modelId,
                type,
                data: getResourceStandardModel(data.applicationName),
            });

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
