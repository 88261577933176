/* eslint-disable camelcase */
import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import { Currency } from '../index.ts';
import { getOrganizationSettingsQueryOptions } from './get-organization-settings.ts';

const editOrganizationSettings = async ({
    currency,
}: {
    currency: Currency;
}): Promise<void> => {
    await AxiosAPIv2.axiosInstance.patch(
        '/organizations/current/settings',
        { currency },
        { headers: { Authorization: 'Bearer ' + getToken() } },
    );
};

type UseEditOrganizationSettingsOptions = {
    mutationConfig?: MutationConfig<typeof editOrganizationSettings>;
};

export const useEditOrganizationSettings = ({
    mutationConfig,
}: UseEditOrganizationSettingsOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: async (data, ...args) => {
            await queryClient.refetchQueries({
                queryKey: getOrganizationSettingsQueryOptions().queryKey,
            });

            if (onSuccess) {
                onSuccess(data, ...args);
            }
        },
        ...restConfig,
        mutationFn: editOrganizationSettings,
    });
};
