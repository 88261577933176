import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ServiceDemand } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { createDeleteParams } from '../adapters.ts';

type DeleteServiceDemandParams = {
    modelId: string;
    type: 'model' | 'library';
    data: ServiceDemand | ServiceDemand[];
};

const keyMap = {
    svcname: 'serviceName',
    tenantname: 'tenantName',
    endpointname: 'endpointName',
    svcplan: 'servicePlan',
};

const deleteServiceDemand = async ({
    modelId,
    type,
    data,
}: DeleteServiceDemandParams): Promise<void> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/service_demand`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const dataArray = Array.isArray(data) ? data : [data];

    const payload = createDeleteParams<ServiceDemand>(keyMap, dataArray);

    const config: AxiosRequestConfig = {
        headers,
        data: payload,
    };

    await AxiosAPIv2.axiosInstance.delete(url, config);
};

type UseDeleteServiceDemandOptions = {
    mutationConfig?: MutationConfig<typeof deleteServiceDemand>;
};

export const useDeleteServiceDemand = ({
    mutationConfig,
}: UseDeleteServiceDemandOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: deleteServiceDemand,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
