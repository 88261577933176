import { z } from 'zod';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getScenariosQueryOptions } from '@/features/scenario/api/get-scenarios.ts';

export const editScenarioSchema = z.object({
    scenarioName: z.string().min(1, 'Required'),
    id: z.string().min(1, 'Required'),
});

export type EditScenarioInput = z.infer<typeof editScenarioSchema>;

const editScenario = async ({ scenarioName, id }: EditScenarioInput) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({ scenarioName, id });
        }, 500);
    });
};

type UseEditScenarioOptions = {
    mutationConfig?: MutationConfig<typeof editScenario>;
};

export const useEditScenario = ({ mutationConfig }: UseEditScenarioOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: (...args) => {
            queryClient.invalidateQueries({
                queryKey: getScenariosQueryOptions().queryKey,
            });
            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: editScenario,
    });
};
