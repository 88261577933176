import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { TenantDeployment } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { tenantTimeseriesAdapter } from '../adapters.ts';

type EditTenantTimeSeriesParams = {
    modelId: string;
    type: 'model' | 'library';
    prevData: TenantDeployment;
    nextData: TenantDeployment;
};

const editTenantTimeSeries = async ({
    modelId,
    type,
    prevData,
    nextData,
}: EditTenantTimeSeriesParams): Promise<TenantDeployment> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/tenant_timeseries`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const params = {
        name: prevData.name,
        delay: prevData.delay,
        model_id: modelId,
    };

    const config: AxiosRequestConfig = {
        headers,
        params,
    };

    const payload = tenantTimeseriesAdapter(nextData);

    await AxiosAPIv2.axiosInstance.patch(url, payload, config);

    return nextData;
};

type UseEditTenantTimeSeriesOptions = {
    mutationConfig?: MutationConfig<typeof editTenantTimeSeries>;
    invalidate?: boolean;
};

export const useEditTenantTimeSeries = ({
    mutationConfig,
    invalidate = true,
}: UseEditTenantTimeSeriesOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: editTenantTimeSeries,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            if (invalidate) {
                await queryClient.invalidateQueries({
                    queryKey: getQueryKey(modelId, type),
                });
            }

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
