import React, { ComponentProps } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown.tsx';
import { cn } from '@/lib/utils.ts';

const inactiveClassName: ComponentProps<'div'>['className'] =
    'border-transparent text-newDesign-text-secondary hover:bg-white';

const activeClassName: ComponentProps<'div'>['className'] =
    'bg-white border-newDesign-secondary text-newDesign-primary cursor-default';

const NavBar = ({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) => {
    return (
        <nav
            className={cn(
                'h-12 w-full min-w-fit rounded-md border border-solid border-newDesign-divider bg-newDesign-background p-2',
                className,
            )}
            aria-label='Dashboard navigation bar'>
            <ul className='flex h-full w-full items-center justify-start gap-4'>
                {children}
            </ul>
        </nav>
    );
};

const Tab = ({
    to,
    text,
    className,
    exact,
}: {
    to: string;
    text: string;
    className?: string;
    exact?: boolean;
}) => {
    return (
        <li className='flex h-full items-center'>
            <NavLink
                exact={exact}
                to={to}
                className={(isActive) =>
                    cn(
                        `flex h-full items-center text-nowrap rounded-md border border-solid px-2 py-0.5 text-center text-sm ring-offset-white transition-colors`,
                        `focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-newDesign-primary focus-visible:ring-offset-2`,
                        `${isActive ? activeClassName : inactiveClassName}`,
                        className,
                    )
                }>
                {text}
            </NavLink>
        </li>
    );
};

const DropdownTab = ({
    text,
    className,
    matchString,
    children,
}: {
    text: string;
    className?: string;
    matchString?: string;
    children: React.ReactNode;
}) => {
    const location = useLocation();
    const match = location.pathname.includes(matchString);
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <button
                    className={cn(
                        `relative h-full text-nowrap rounded-md border border-solid px-2 py-0.5 pr-6 text-center text-sm ring-offset-white transition-colors `,
                        `focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-newDesign-primary focus-visible:ring-offset-2`,
                        ` ${match ? activeClassName : inactiveClassName}`,
                        className,
                    )}>
                    {text}
                    <IoIosArrowDown className='absolute bottom-[47%] right-1 size-3 translate-y-[50%]' />
                </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                className='max-h-[500px] w-[250px] overflow-y-auto bg-white p-2'
                align='start'
                sideOffset={12}>
                {children}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

const DropDownTabItem = ({
    to,
    text,
    className,
    onClick,
}: {
    to: string;
    text: string;
    className?: string;
    onClick?: () => void;
}) => {
    const history = useHistory();
    const location = useLocation();
    const match = location.pathname.includes(to);

    const handleNavigate = () => {
        if (match) return;

        if (onClick) {
            onClick();
        } else {
            history.push(to);
        }
    };
    return (
        <DropdownMenuItem
            onClick={handleNavigate}
            className={cn(
                `h-10 cursor-pointer text-nowrap rounded-md border border-solid border-transparent px-2 py-1.5 text-center text-sm`,
                `ring-offset-white transition-colors focus:bg-newDesign-background`,
                `${match ? 'cursor-default border-newDesign-secondary text-newDesign-primary' : ''}`,
                className,
            )}>
            {text}
        </DropdownMenuItem>
    );
};

NavBar.Tab = Tab;
NavBar.DropdownTab = DropdownTab;
NavBar.DropDownTabItem = DropDownTabItem;

export default NavBar;
