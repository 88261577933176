import { cva, type VariantProps } from 'class-variance-authority';

import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap rounded-md text-base font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-newDesign-primary focus-visible:ring-offset-2 disabled:border-newDesign-divider disabled:pointer-events-none disabled:bg-newDesign-divider disabled:bg-opacity-80 disabled:text-newDesign-text-secondary dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300',
    {
        variants: {
            variant: {
                fill: 'bg-newDesign-primary text-white hover:bg-newDesign-primary-hover active:bg-newDesign-primary-active',
                outline:
                    'border border-newDesign-primary text-newDesign-primary bg-white hover:bg-newDesign-outline-hover active:bg-newDesign-outline-active',
                text: 'bg-white text-newDesign-primary hover:bg-newDesign-text-hover active:bg-newDesign-text-active outline-0',
            },
            variantColor: {
                secondary:
                    'bg-newDesign-secondary hover:bg-newDesign-secondary-hover active:bg-newDesign-secondary-active',
                warning:
                    'bg-newDesign-warning hover:bg-newDesign-warning-light active:bg-newDesign-warning-dark',
                alert: 'bg-newDesign-error hover:bg-newDesign-error-light active:bg-newDesign-error-dark',
            },
            size: {
                default: 'h-10 px-4 py-2',
                sm: 'h-8 rounded px-4 py-2 text-sm',
                icon: 'h-10 w-10 px-2 py-2 hover:bg-newDesign-secondary active:bg-newDesign-secondary-active hover:text-white active:text-white',
            },
            withIcon: {
                true: 'pl-3 pr-4',
            },
        },
        defaultVariants: {
            variant: 'fill',
            size: 'default',
        },
    },
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            variant,
            variantColor,
            size,
            withIcon,
            asChild = false,
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : 'button';
        return (
            <Comp
                className={cn(
                    buttonVariants({
                        variant,
                        size,
                        withIcon,
                        variantColor,
                    }),
                    className,
                )}
                ref={ref}
                {...props}
            />
        );
    },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
