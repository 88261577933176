import { AxiosResponse } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, CreateModelResponse, getToken } from '@bae/api';

interface ForkModelResponse {
    model_name: string;
    model_id: string;
}

const forkModel = async ({
    copyModelId,
    modelName,
}: {
    copyModelId: string;
    modelName: string;
}): Promise<CreateModelResponse> => {
    const formData: FormData = new FormData();
    formData.append('copy_model_id', copyModelId);
    formData.append('model_name', modelName);

    const response: AxiosResponse<ForkModelResponse> =
        await AxiosAPIv2.axiosInstance.post('/models', formData, {
            headers: { Authorization: 'Bearer ' + getToken() },
        });

    return {
        id: response.data.model_id,
        name: response.data.model_name,
    };
};

type UseForkModelOptions = {
    mutationConfig?: MutationConfig<typeof forkModel>;
};

export const useForkModel = ({ mutationConfig }: UseForkModelOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: async (...args) => {
            await queryClient.invalidateQueries({
                queryKey: ['model_groups'],
            });

            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: forkModel,
    });
};
