import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { CpeSpecs } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { createDeleteParams } from './adapters.ts';
import { getDefaultSpecs } from './adapters.ts';

const keyMap = {
    name: 'cpeName',
};

type DeleteCpeParams = {
    modelId: string;
    type: 'model' | 'library';
    names: string[];
};

const deleteCpe = async ({
    modelId,
    type,
    names,
}: DeleteCpeParams): Promise<void> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/cpeconfig`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const dataArray = Array.isArray(names) ? names : [names];

    const payload = createDeleteParams<CpeSpecs>(
        keyMap,
        dataArray.map(getDefaultSpecs),
    );

    const config: AxiosRequestConfig = {
        headers,
        data: payload,
    };

    await AxiosAPIv2.axiosInstance.delete(url, config);
};

type UseDeleteCpeOptions = {
    mutationConfig?: MutationConfig<typeof deleteCpe>;
};

export const useDeleteCpe = ({ mutationConfig }: UseDeleteCpeOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: deleteCpe,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
