import { useCurrency } from '@bae/store';
import { formatCurrencySymbol } from '@bae/utils';

import { Skeleton } from '@/components/ui/skeleton.tsx';

import { TableBodyRow, TableHeadRow } from '../../shared/TableComponents';
import {
    SelectCell,
    TextInputCell,
    FloatNumberInputCell,
    NotesCell,
} from '../../shared/TableComponents/CellComponents';
import { InternetEgress, EXPENSE_TYPE, RECURRING } from '../../shared/types.ts';
import {
    getColoInternetEgress,
    useVirtualMachineComparisonStore,
} from '../store';

const getEgressLabels = (currencySign: string = '$') => ({
    expenseName: { label: 'Expense', isEditable: false },
    recurring: { label: 'Recurring', isEditable: true },
    type: { label: 'Type', isEditable: true },
    dataEgressCost: {
        label: `Data Egress Cost per GB (${currencySign})`,
        isEditable: true,
    },
    notes: { label: 'Notes', isEditable: true },
});

const ColoInternetEgress = () => {
    const { currency } = useCurrency();

    const coloInternetEgress = useVirtualMachineComparisonStore(
        getColoInternetEgress,
    );
    const updateColoInternetEgress = useVirtualMachineComparisonStore(
        (state) => state.updateColoInternetEgress,
    );
    const loading = coloInternetEgress.length === 0;

    const currencySign = formatCurrencySymbol({
        currency,
        onlySymbol: true,
    });
    const egressLabels = getEgressLabels(currencySign);

    return (
        <div className='w-full overflow-x-auto'>
            <table className='w-full table-fixed border-separate border-spacing-2 text-sm'>
                <thead>
                    {loading ? (
                        <SkeletonHeader />
                    ) : (
                        <TableHeadRow>
                            {Object.keys(egressLabels).map((key) => (
                                <th
                                    key={key}
                                    className={`text-center ${
                                        key === 'notes' ? 'w-20' : ''
                                    }`}>
                                    <p className='whitespace-nowrap'>
                                        {
                                            egressLabels[
                                                key as keyof InternetEgress
                                            ].label
                                        }
                                    </p>
                                </th>
                            ))}
                        </TableHeadRow>
                    )}
                </thead>
                <tbody>
                    {loading ? (
                        <SkeletonRow />
                    ) : (
                        coloInternetEgress.map((egress, index) => {
                            return (
                                <TableBodyRow key={index}>
                                    <TextInputCell<InternetEgress>
                                        value={egress.expenseName}
                                        onChange={updateColoInternetEgress}
                                        accessorKey={'expenseName'}
                                        index={index}
                                        isEditable={false}
                                        className='min-w-[200px]'
                                    />
                                    <SelectCell<InternetEgress>
                                        value={egress.recurring}
                                        options={Object.values(RECURRING)}
                                        onChange={updateColoInternetEgress}
                                        accessorKey={'recurring'}
                                        index={index}
                                    />
                                    <SelectCell<InternetEgress>
                                        value={egress.type}
                                        options={Object.values(EXPENSE_TYPE)}
                                        onChange={updateColoInternetEgress}
                                        accessorKey={'type'}
                                        index={index}
                                    />
                                    <FloatNumberInputCell<InternetEgress>
                                        value={egress.dataEgressCost}
                                        accessorKey={'dataEgressCost'}
                                        index={index}
                                        onChange={updateColoInternetEgress}
                                    />
                                    <NotesCell<InternetEgress>
                                        value={egress.notes}
                                        onChange={updateColoInternetEgress}
                                        accessorKey={'notes'}
                                        index={index}
                                    />
                                </TableBodyRow>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
};

const SkeletonHeader = () => {
    return (
        <TableHeadRow>
            <th>Expense Name</th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
        </TableHeadRow>
    );
};

const SkeletonRow = () => {
    const arr = Array.from({ length: 4 }, (_, i) => i);
    return (
        <TableBodyRow>
            <td>
                <Skeleton />
            </td>
            {arr.map((col) => (
                <td key={col}>
                    <Skeleton />
                </td>
            ))}
        </TableBodyRow>
    );
};

export default ColoInternetEgress;
