import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ResourceCashflow } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { createDeleteParams } from '../adapters.ts';

type DeleteResourceCashflowParams = {
    modelId: string;
    type: 'model' | 'library';
    data: ResourceCashflow | ResourceCashflow[];
};

const keyMap = {
    appname: 'applicationName',
    appmodel: 'applicationModel',
    flowname: 'flowName',
};

const deleteResourceCashflow = async ({
    modelId,
    type,
    data,
}: DeleteResourceCashflowParams): Promise<void> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/appcfconfig`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const dataArray = Array.isArray(data) ? data : [data];

    const payload = createDeleteParams<ResourceCashflow>(keyMap, dataArray);

    const config: AxiosRequestConfig = {
        headers,
        data: payload,
    };

    await AxiosAPIv2.axiosInstance.delete(url, config);
};

type UseDeleteResourceCashflowOptions = {
    mutationConfig?: MutationConfig<typeof deleteResourceCashflow>;
};

export const useDeleteResourceCashflow = ({
    mutationConfig,
}: UseDeleteResourceCashflowOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: deleteResourceCashflow,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
