import { create } from 'zustand';

import {
    Expense,
    Server,
    FTE,
    InternetEgress,
    MiscellaneousCosts,
} from '../../shared/types.ts';
import { initialData } from '../initialData.ts';
import { CloudRebalancingTemplate } from '../types.ts';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

// Adjust the import path accordingly

interface CloudRebalancingStore {
    data: CloudRebalancingTemplate;

    // Metal
    updateMetalExpense: <K extends keyof Expense>(
        index: number,
        key: K,
        value: Expense[K],
    ) => void;
    updateMetalServer: <K extends keyof Server>(
        index: number,
        key: K,
        value: Server[K],
    ) => void;
    updateMetalFte: <K extends keyof FTE>(
        index: number,
        key: K,
        value: FTE[K],
    ) => void;
    // Metal Internet Egress and Miscellaneous Costs are shared types
    updateMetalInternetEgress: <K extends keyof InternetEgress>(
        index: number,
        key: K,
        value: InternetEgress[K],
    ) => void;
    updateMetalMiscellaneousCost: <K extends keyof MiscellaneousCosts>(
        index: number,
        key: K,
        value: MiscellaneousCosts[K],
    ) => void;

    // Similar update functions for Colo and Cloud...
    // Colo
    updateColoExpense: <K extends keyof Expense>(
        index: number,
        key: K,
        value: Expense[K],
    ) => void;
    updateColoServer: <K extends keyof Server>(
        index: number,
        key: K,
        value: Server[K],
    ) => void;
    updateColoFte: <K extends keyof FTE>(
        index: number,
        key: K,
        value: FTE[K],
    ) => void;
    updateColoInternetEgress: <K extends keyof InternetEgress>(
        index: number,
        key: K,
        value: InternetEgress[K],
    ) => void;
    updateColoMiscellaneousCost: <K extends keyof MiscellaneousCosts>(
        index: number,
        key: K,
        value: MiscellaneousCosts[K],
    ) => void;

    // Cloud
    updateCloudExpense: <K extends keyof Expense>(
        index: number,
        key: K,
        value: Expense[K],
    ) => void;
    updateCloudVM: <K extends keyof Server>(
        index: number,
        key: K,
        value: Server[K],
    ) => void;
    updateCloudFte: <K extends keyof FTE>(
        index: number,
        key: K,
        value: FTE[K],
    ) => void;
    updateCloudMiscellaneousCost: <K extends keyof MiscellaneousCosts>(
        index: number,
        key: K,
        value: MiscellaneousCosts[K],
    ) => void;

    // Reset
    resetData: () => void;
}

const useCloudRebalancingStore = create<CloudRebalancingStore>()(
    devtools(
        immer((set) => ({
            data: initialData,

            // Metal
            updateMetalExpense: (index, key, value) =>
                set((state) => {
                    const expense = state.data.metal.expenses[index];
                    if (key === 'expenseName' && (index === 1 || index === 2)) {
                        // Cannot rename fixed expense names
                        return;
                    }

                    expense[key] = value;
                }),

            updateMetalServer: (index, key, value) =>
                set((state) => {
                    state.data.metal.servers[index][key] = value;

                    const serverName =
                        state.data.metal.servers[index].serverName;

                    // Update expenses that depend on serverName
                    if (index === 0 && key === 'serverName') {
                        state.data.metal.expenses[1].expenseName = serverName;
                    }

                    if (index === 1 && key === 'serverName') {
                        state.data.metal.expenses[2].expenseName = serverName;
                    }
                }),

            updateMetalFte: (index, key, value) =>
                set((state) => {
                    state.data.metal.ftes[index][key] = value;
                }),

            updateMetalInternetEgress: (index, key, value) =>
                set((state) => {
                    const egress = state.data.metal.internetEgress[index];
                    if (key === 'expenseName') {
                        // Cannot rename fixed expense names
                        return;
                    }
                    egress[key] = value;
                }),

            updateMetalMiscellaneousCost: (index, key, value) =>
                set((state) => {
                    const miscCost = state.data.metal.miscellaneousCosts[index];
                    if (key === 'expenseName') {
                        // Cannot rename fixed expense names
                        return;
                    }
                    miscCost[key] = value;
                }),

            // Colo
            updateColoExpense: (index, key, value) =>
                set((state) => {
                    const expense = state.data.colo.expenses[index];
                    expense[key] = value;
                }),

            updateColoServer: (index, key, value) =>
                set((state) => {
                    const server = state.data.colo.servers[index];
                    if (key === 'serverName') {
                        // Cannot rename fixed server names
                        return;
                    }
                    server[key] = value;
                }),

            updateColoFte: (index, key, value) =>
                set((state) => {
                    state.data.colo.ftes[index][key] = value;
                }),

            updateColoInternetEgress: (index, key, value) =>
                set((state) => {
                    const egress = state.data.colo.internetEgress[index];
                    if (key === 'expenseName') {
                        // Cannot rename fixed expense names
                        return;
                    }
                    egress[key] = value;
                }),

            updateColoMiscellaneousCost: (index, key, value) =>
                set((state) => {
                    const miscCost = state.data.colo.miscellaneousCosts[index];
                    if (key === 'expenseName') {
                        // Cannot rename fixed expense names
                        return;
                    }
                    miscCost[key] = value;
                }),

            // Cloud
            updateCloudExpense: (index, key, value) =>
                set((state) => {
                    const expense = state.data.cloud.expenses[index];
                    if (key === 'expenseName' && index === 1) {
                        // Cannot rename fixed expense names
                        return;
                    }
                    expense[key] = value;
                }),

            updateCloudVM: (index, key, value) =>
                set((state) => {
                    const vm = state.data.cloud.vms[index];
                    vm[key] = value;

                    const vmName = vm.serverName;

                    // Update expenses that depend on VM name
                    if (index === 0 && key === 'serverName') {
                        state.data.cloud.expenses[1].expenseName = vmName;
                    }
                }),

            updateCloudFte: (index, key, value) =>
                set((state) => {
                    state.data.cloud.ftes[index][key] = value;
                }),

            updateCloudMiscellaneousCost: (index, key, value) =>
                set((state) => {
                    const miscCost = state.data.cloud.miscellaneousCosts[index];
                    if (key === 'expenseName') {
                        // Cannot rename fixed expense names
                        return;
                    }
                    miscCost[key] = value;
                }),

            // Reset
            resetData: () => set({ data: initialData }),
        })),
        {
            name: 'CloudRebalancingStore',
        },
    ),
);

const getMetalExpenses = (state: CloudRebalancingStore) => {
    return state.data.metal.expenses;
};

const getMetalServers = (state: CloudRebalancingStore) => {
    return state.data.metal.servers;
};

const getMetalFtes = (state: CloudRebalancingStore) => {
    return state.data.metal.ftes;
};

const getMetalInternetEgress = (state: CloudRebalancingStore) => {
    return state.data.metal.internetEgress;
};

const getMetalMiscellaneousCosts = (state: CloudRebalancingStore) => {
    return state.data.metal.miscellaneousCosts;
};

const getColoExpenses = (state: CloudRebalancingStore) => {
    return state.data.colo.expenses;
};

const getColoServers = (state: CloudRebalancingStore) => {
    return state.data.colo.servers;
};

const getColoFtes = (state: CloudRebalancingStore) => {
    return state.data.colo.ftes;
};

const getColoInternetEgress = (state: CloudRebalancingStore) => {
    return state.data.colo.internetEgress;
};

const getColoMiscellaneousCosts = (state: CloudRebalancingStore) => {
    return state.data.colo.miscellaneousCosts;
};

const getCloudExpenses = (state: CloudRebalancingStore) => {
    return state.data.cloud.expenses;
};

const getCloudVMs = (state: CloudRebalancingStore) => {
    return state.data.cloud.vms;
};

const getCloudFtes = (state: CloudRebalancingStore) => {
    return state.data.cloud.ftes;
};

const getCloudMiscellaneousCosts = (state: CloudRebalancingStore) => {
    return state.data.cloud.miscellaneousCosts;
};

export {
    useCloudRebalancingStore,
    getMetalExpenses,
    getMetalServers,
    getMetalFtes,
    getMetalInternetEgress,
    getMetalMiscellaneousCosts,
    getColoExpenses,
    getColoServers,
    getColoFtes,
    getColoInternetEgress,
    getColoMiscellaneousCosts,
    getCloudExpenses,
    getCloudVMs,
    getCloudFtes,
    getCloudMiscellaneousCosts,
};
