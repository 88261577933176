export enum KpiSections {
    financialIndicators = 'financialIndicators',
    cashFlow = 'cashFlow',
    datacenterExpenditures = 'datacenterExpenditures',
    datacenterCosts = 'datacenterCosts',
    indicatorsEvolution = 'indicatorsEvolution',
    operatingIncome = 'operatingIncome',
}

export enum FinancialSections {
    cashFlow = 'cashFlow',
    operatingIncome = 'operatingIncome',
    averagesEndPoint = 'averagesEndPoint',
    averagesTenant = 'averagesTenant',
    averagesDataRate = 'averagesDataRate',
    averagesDataVolume = 'averagesDataVolume',
}

export enum InventorySections {
    serversEvolution = 'serversEvolution',
    serversInventory = 'serversInventory',
    inventoryResources = 'inventoryResources',
    inventoryFTE = 'inventoryFTE',
}

export enum ComparisonSections {
    comparisonDescription = 'comparisonDescription',
    financialIndicators = 'financialIndicators',
    newInvestmentAnalysis = 'newInvestmentAnalysis',
    cashFlowComparison = 'cashFlowComparison',
    indicatorsEvolution = 'indicatorsEvolution',
    fteComparisonTable = 'fteComparisonTable',
    fteComparisonDashboard = 'fteComparisonDashboard',
    comparisonNpv = 'comparisonNpv',
    operatingIncome = 'operatingIncome',
}
