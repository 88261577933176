import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { FteFixedDeployment } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { fixedDeploymentAdapter } from '../adapters.ts';

type EditFixedDeploymentParams = {
    modelId: string;
    type: 'model' | 'library';
    prevData: FteFixedDeployment;
    nextData: FteFixedDeployment;
};

const editFixedDeployment = async ({
    modelId,
    type,
    prevData,
    nextData,
}: EditFixedDeploymentParams): Promise<FteFixedDeployment> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/fte_fixed_deployment`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const params = {
        fte_name: prevData.fteName,
        flow_name: prevData.flowName,
        model_id: modelId,
    };

    const config: AxiosRequestConfig = {
        headers,
        params,
    };

    const payload = fixedDeploymentAdapter(nextData);

    await AxiosAPIv2.axiosInstance.patch(url, payload, config);

    return nextData;
};

type UseEditFixedDeploymentOptions = {
    mutationConfig?: MutationConfig<typeof editFixedDeployment>;
};

export const useEditFixedFteDeployment = ({
    mutationConfig,
}: UseEditFixedDeploymentOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: editFixedDeployment,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
