import { z } from 'zod';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation } from '@tanstack/react-query';

import { AuthAPIRoute, AxiosAPI, getToken } from '@bae/api';

const editUserPasswordSchema = z.object({
    previousPassword: z.string(),
    proposedPassword: z.string(),
});

export type EditUserPasswordInput = z.infer<typeof editUserPasswordSchema>;

const editUserPassword = (payload: EditUserPasswordInput): Promise<void> => {
    const options = {
        headers: { Authorization: 'Bearer ' + getToken(true) },
    };

    return AxiosAPI.axiosInstance.patch(
        AuthAPIRoute.cognitoUser(),
        payload,
        options,
    );
};

type UseEditUserPasswordOptions = {
    mutationConfig?: MutationConfig<typeof editUserPassword>;
};

export const useEditUserPassword = ({
    mutationConfig,
}: UseEditUserPasswordOptions) => {
    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess,
        ...restConfig,
        mutationFn: editUserPassword,
    });
};
