import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ServiceConnectionTimeseries } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { serviceResourceDeploymentAdapter } from '../../adapters.ts';

type EditServiceResourceDeploymentParams = {
    modelId: string;
    type: 'model' | 'library';
    prevData: ServiceConnectionTimeseries;
    nextData: ServiceConnectionTimeseries;
};

const editServiceResourceDeployment = async ({
    modelId,
    type,
    prevData,
    nextData,
}: EditServiceResourceDeploymentParams): Promise<ServiceConnectionTimeseries> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/service_connection_timeseries`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const params = {
        svcname: prevData.serviceName,
        appname: prevData.applicationName,
        location: prevData.location,
        delay: prevData.delay,
        modelId: modelId,
    };

    const config: AxiosRequestConfig = {
        headers,
        params,
    };

    const payload = serviceResourceDeploymentAdapter(nextData);

    await AxiosAPIv2.axiosInstance.patch(url, payload, config);

    return nextData;
};

type UseEditServiceResourceDeploymentOptions = {
    mutationConfig?: MutationConfig<typeof editServiceResourceDeployment>;
    invalidate?: boolean;
};

export const useEditServiceResourceDeployment = ({
    mutationConfig,
    invalidate = true,
}: UseEditServiceResourceDeploymentOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: editServiceResourceDeployment,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            if (invalidate) {
                await queryClient.invalidateQueries({
                    queryKey: getQueryKey(modelId, type),
                });
            }

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
