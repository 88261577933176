import { AppTypes } from '@/app-config/appTypes.ts';
import { getAppType } from '@/app-config/featureConfig.ts';
import BAEApp from '@/app/bae';

import DigitalTwinApp from 'src/app/digital-twin';

const App = () => {
    // Initialize the app type based on the environment or the stored value
    const appType = getAppType();

    function isDigitalTwin() {
        return appType === AppTypes.GLOBAL_FABRIC || appType === AppTypes.MIST;
    }

    if (isDigitalTwin()) {
        return <DigitalTwinApp />;
    }

    return <BAEApp />;
};

export default App;
