import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ServiceCashflow } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { serviceCashflowAdapter } from '../adapters.ts';

type EditServiceCashFlowParams = {
    modelId: string;
    type: 'model' | 'library';
    prevData: ServiceCashflow;
    nextData: ServiceCashflow;
};

const editServiceCashFlow = async ({
    modelId,
    type,
    prevData,
    nextData,
}: EditServiceCashFlowParams): Promise<ServiceCashflow> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/servicecfconfig`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const params = {
        svcname: prevData.serviceName,
        svcplan: prevData.plan,
        flowname: prevData.flowName,
        model_id: modelId,
    };

    const config: AxiosRequestConfig = {
        headers,
        params,
    };

    const payload = serviceCashflowAdapter(nextData);

    await AxiosAPIv2.axiosInstance.patch(url, payload, config);

    return nextData;
};

type UseEditServiceCashFlowOptions = {
    mutationConfig?: MutationConfig<typeof editServiceCashFlow>;
};

export const useEditServiceCashFlow = ({
    mutationConfig,
}: UseEditServiceCashFlowOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: editServiceCashFlow,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
