import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import { getQueryKey } from '@/features/input/api/get-input.ts';

enum ModelNodeTypes {
    'tenant',
    'endpoint',
    'service',
    'application',
    'datacenter',
    'server',
    'fte',
    'cpe',
}

interface IDeployToModelApi {
    libraryId: string;
    modelId: string;
    name: string;
    type: ModelNodeTypes;
}

const deployToModel = ({
    libraryId,
    modelId,
    name,
    type,
}: IDeployToModelApi): Promise<void> => {
    const validComponents = [
        ModelNodeTypes.application,
        ModelNodeTypes.service,
        ModelNodeTypes.server,
        ModelNodeTypes.cpe,
        ModelNodeTypes.fte,
    ];

    if (!validComponents.includes(type)) {
        throw new Error('Invalid Model Type');
    }

    const nameKey = `${ModelNodeTypes[type]}_name`;

    const body = {
        model_id: modelId,
        [nameKey]: name,
    };

    return AxiosAPIv2.axiosInstance.post(
        `/libraries/${libraryId}/deploy/${ModelNodeTypes[type]}`,
        body,
        { headers: { Authorization: 'Bearer ' + getToken() } },
    );
};

type UseDeployToModelOptions = {
    mutationConfig?: MutationConfig<typeof deployToModel>;
};

export const useDeployToModel = ({
    mutationConfig,
}: UseDeployToModelOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: deployToModel,
        onSuccess: async (data, variables, context) => {
            const { modelId } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, 'model'),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
