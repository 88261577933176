import React, { memo, useMemo, useState } from 'react';
import { HiMiniMagnifyingGlass } from 'react-icons/hi2';
import { TbArrowsDiff } from 'react-icons/tb';
import { useHistory } from 'react-router-dom';

import { RouteMap } from '@bae/routes';

import { Button } from '@/components/ui/button.tsx';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import {
    IDigitalTwinData,
    useDigitalTwin,
} from '@/features/digital-twin/api/get-digital-twin.ts';
import DigitalTwin from '@/features/digital-twin/components/DigitalTwin.tsx';
import { useScenarios } from '@/features/scenario/api/get-scenarios.ts';
import Scenario from '@/features/scenario/components/Scenario.tsx';
import { IScenarioData } from '@/features/scenario/mock-data.ts';

const ScenariosList = memo(
    ({
        loading,
        scenariosList,
        selectedScenarios,
        setSelectedScenarios,
    }: {
        loading: boolean;
        scenariosList: IScenarioData[];
        selectedScenarios: string[];
        setSelectedScenarios: React.Dispatch<React.SetStateAction<string[]>>;
    }) => {
        const digitalTwinQuery = useDigitalTwin({});
        const digitalTwinData: IDigitalTwinData | undefined =
            digitalTwinQuery.data;

        if (loading || digitalTwinQuery.isLoading) {
            return (
                <div className='flex w-full flex-col gap-2 pr-4'>
                    <Skeleton className='h-10 w-full rounded-md bg-newDesign-divider' />
                    <Skeleton className='h-10 w-full rounded-md bg-newDesign-divider' />
                    <Skeleton className='h-10 w-full rounded-md bg-newDesign-divider' />
                </div>
            );
        }

        if ((!scenariosList || scenariosList.length === 0) && !digitalTwinData)
            return (
                <div className='flex h-[135px] w-full items-center justify-center'>
                    <p className='text-2xl text-newDesign-black'>
                        No scenario forks created yet
                    </p>
                </div>
            );

        return (
            <div className='flex w-full flex-col gap-2 pr-4'>
                {digitalTwinData && (
                    <div
                        key={digitalTwinData.id}
                        className='flex items-center gap-3'>
                        <Checkbox
                            id={digitalTwinData.id}
                            checked={selectedScenarios?.includes(
                                digitalTwinData.id,
                            )}
                            onCheckedChange={(checked) => {
                                return checked
                                    ? setSelectedScenarios([
                                          ...selectedScenarios,
                                          digitalTwinData.id,
                                      ])
                                    : setSelectedScenarios(
                                          selectedScenarios?.filter(
                                              (value) =>
                                                  value !== digitalTwinData.id,
                                          ),
                                      );
                            }}
                        />
                        <DigitalTwin data={digitalTwinData} />
                    </div>
                )}
                <Separator className='my-1' />
                {scenariosList.map((scenario: IScenarioData) => (
                    <div key={scenario.id} className='flex items-center gap-3'>
                        <Checkbox
                            id={scenario.id}
                            checked={selectedScenarios?.includes(scenario.id)}
                            onCheckedChange={(checked) => {
                                return checked
                                    ? setSelectedScenarios([
                                          ...selectedScenarios,
                                          scenario.id,
                                      ])
                                    : setSelectedScenarios(
                                          selectedScenarios?.filter(
                                              (value) => value !== scenario.id,
                                          ),
                                      );
                            }}
                        />
                        <Scenario data={scenario} />
                    </div>
                ))}
            </div>
        );
    },
);

ScenariosList.displayName = 'ScenariosList';

export function CompareDialog({
    isModalOpen,
    closeModal,
    initialSelected = [],
}: {
    isModalOpen: boolean;
    closeModal: () => void;
    initialSelected?: string[];
}) {
    return (
        <Dialog open={isModalOpen} onOpenChange={closeModal}>
            <DialogContent className='max-w-xl'>
                <CompareDialogContent
                    initialSelected={initialSelected}
                    closeModal={closeModal}
                />
            </DialogContent>
        </Dialog>
    );
}

const CompareDialogContent = ({
    closeModal,
    initialSelected,
}: {
    closeModal: () => void;
    initialSelected: string[];
}) => {
    const history = useHistory();
    const scenarioQuery = useScenarios({});
    const [selected, setSelected] = useState<string[]>(initialSelected);
    const [searchValue, setSearchValue] = useState('');
    const { isLoading, data } = scenarioQuery;

    const handleInputSearch = (e) => {
        setSearchValue(e.target.value);
    };

    const filteredScenarios = useMemo(
        () =>
            data.filter((item) =>
                item.name.toLowerCase().includes(searchValue.toLowerCase()),
            ),
        [data, searchValue],
    );

    const navigateToComparison = () => {
        const paramsPairs = selected.map((id) => ['m', id]);
        const searchParams = new URLSearchParams(paramsPairs);

        history.push({
            pathname: RouteMap.comparison.path,
            search: `?${searchParams}`,
        });
    };

    return (
        <>
            <DialogHeader>
                <DialogTitle className='flex items-center'>
                    <TbArrowsDiff className='mr-2 size-6 text-newDesign-primary' />
                    Compare
                </DialogTitle>
            </DialogHeader>
            <div className='flex items-center'>
                <h1 className='flex-1 text-base text-newDesign-text-primary'>
                    Select scenarios to compare
                </h1>
                <SelectedCount selected={selected} setSelected={setSelected} />
            </div>

            <div className='relative'>
                <HiMiniMagnifyingGlass className='absolute right-4 top-1/2 size-5 translate-y-[-50%]' />
                <Input
                    onChange={handleInputSearch}
                    className='pr-12'
                    placeholder='Search for a scenario'
                />
            </div>

            <div className='flex h-[300px] max-h-[300px] overflow-y-auto'>
                <ScenariosList
                    loading={isLoading}
                    scenariosList={filteredScenarios}
                    selectedScenarios={selected}
                    setSelectedScenarios={setSelected}
                />
            </div>

            <Separator orientation='horizontal' />

            <DialogFooter className='items-center'>
                <Button
                    variant='text'
                    className='text-newDesign-text-secondary'
                    onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    onClick={navigateToComparison}
                    withIcon
                    disabled={selected.length < 2}>
                    <TbArrowsDiff className='mr-2 size-6' />
                    Compare
                </Button>
            </DialogFooter>
        </>
    );
};

const SelectedCount = memo(
    ({
        selected,
        setSelected,
    }: {
        selected: string[];
        setSelected: React.Dispatch<React.SetStateAction<string[]>>;
    }) => {
        const handleClear = () => setSelected([]);
        return (
            selected.length > 0 && (
                <>
                    <p className='text-end text-sm text-newDesign-secondary'>{`${selected.length} selected`}</p>
                    <button
                        onClick={handleClear}
                        className='ml-2 text-end text-sm text-newDesign-text-secondary'>
                        clear
                    </button>
                </>
            )
        );
    },
);

SelectedCount.displayName = 'SelectedCount';

export default CompareDialog;
