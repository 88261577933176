import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ResourceCashflow } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { resourceCashFlowAdapter } from '../adapters.ts';

type AddResourceCashflowParams = {
    modelId: string;
    type: 'model' | 'library';
    data: ResourceCashflow;
};

const addResourceCashflow = async ({
    modelId,
    type,
    data,
}: AddResourceCashflowParams): Promise<ResourceCashflow> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/appcfconfig`;

    const payload = resourceCashFlowAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return data;
};

type UseAddResourceCashflowOptions = {
    mutationConfig?: MutationConfig<typeof addResourceCashflow>;
};

export const useAddResourceCashflow = ({
    mutationConfig,
}: UseAddResourceCashflowOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: addResourceCashflow,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
