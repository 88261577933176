import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { CpeCashflow } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { cpeCashFlowAdapter } from '../adapters.ts';

type AddCpeCashFlowParams = {
    modelId: string;
    type: 'model' | 'library';
    data: CpeCashflow;
};

const addCpeCashFlow = async ({
    modelId,
    type,
    data,
}: AddCpeCashFlowParams): Promise<CpeCashflow> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/cpecfconfig`;

    const payload = cpeCashFlowAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return data;
};

type UseAddCpeCashFlowOptions = {
    mutationConfig?: MutationConfig<typeof addCpeCashFlow>;
};

export const useAddCpeCashFlow = ({
    mutationConfig,
}: UseAddCpeCashFlowOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: addCpeCashFlow,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
