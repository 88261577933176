import { z } from 'zod';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getScenariosQueryOptions } from '@/features/scenario/api/get-scenarios.ts';

export const deleteScenarioSchema = z.object({
    id: z.string().array().min(1, 'Required'),
});

export type DeleteScenarioInput = z.infer<typeof deleteScenarioSchema>;

const deleteScenario = async ({ id }: DeleteScenarioInput) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({ id });
        }, 500);
    });
};

type UseDeleteScenarioOptions = {
    mutationConfig?: MutationConfig<typeof deleteScenario>;
};

export const useDeleteScenario = ({
    mutationConfig,
}: UseDeleteScenarioOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: (...args) => {
            queryClient.invalidateQueries({
                queryKey: getScenariosQueryOptions().queryKey,
            });
            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: deleteScenario,
    });
};
