import { MutationConfig } from '@/libs/api/src/lib/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { FteVariableDeployment } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { dynamicDeploymentAdapter } from '../adapters.ts';

type AddDynamicDeploymentParams = {
    modelId: string;
    type: 'model' | 'library';
    data: FteVariableDeployment;
};

const addDynamicDeployment = async ({
    modelId,
    type,
    data,
}: AddDynamicDeploymentParams): Promise<FteVariableDeployment> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/fte_dynamic_deployment`;
    const headers = { Authorization: `Bearer ${getToken()}` };

    const payload = dynamicDeploymentAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, { headers });

    return data;
};

type UseAddDynamicDeploymentOptions = {
    mutationConfig?: MutationConfig<typeof addDynamicDeployment>;
};

export const useAddDynamicFteDeployment = ({
    mutationConfig,
}: UseAddDynamicDeploymentOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: addDynamicDeployment,

        onSuccess: async (data, variables, context) => {
            await queryClient.invalidateQueries({
                queryKey: getQueryKey(variables.modelId, variables.type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
