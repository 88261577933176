import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ResourceModel } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { resourceModelAdapter } from '../adapters.ts';

type AddResourceModelParams = {
    modelId: string;
    type: 'model' | 'library';
    data: ResourceModel;
};

const addResourceModel = async ({
    modelId,
    type,
    data,
}: AddResourceModelParams): Promise<ResourceModel> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/appmodels`;

    const payload = resourceModelAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return data;
};

type UseAddResourceModelOptions = {
    mutationConfig?: MutationConfig<typeof addResourceModel>;
    invalidate?: boolean;
};

export const useAddResourceModel = ({
    mutationConfig,
    invalidate = true,
}: UseAddResourceModelOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: addResourceModel,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            if (invalidate) {
                await queryClient.invalidateQueries({
                    queryKey: getQueryKey(modelId, type),
                });
            }

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
