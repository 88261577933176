import React from 'react';
import {
    Column,
    DataSheetGrid,
    floatColumn,
    keyColumn,
    percentColumn,
    textColumn,
} from 'react-datasheet-grid';

import { selectFte, useNetworkStore } from '../store.ts';
import type { IFTE } from '../types.ts';

const columns: Column<IFTE>[] = [
    {
        ...keyColumn<IFTE, 'fteName'>('fteName', textColumn),
        title: 'FTE Name',
        minWidth: 500,
    },
    {
        ...keyColumn<IFTE, 'laborRate'>('laborRate', floatColumn),
        title: 'Labor Rate',
        minWidth: 120,
        maxWidth: 180,
    },
    {
        ...keyColumn<IFTE, 'annualSalaryGrowth'>(
            'annualSalaryGrowth',
            percentColumn,
        ),
        title: 'Annual Salary Growth',
        minWidth: 160,
        maxWidth: 160,
    },
    {
        ...keyColumn<IFTE, 'numberOfFTEs'>('numberOfFTEs', floatColumn),
        title: 'Number of FTEs',
        minWidth: 120,
        maxWidth: 120,
    },
];

const createEmptyNewRows = (numberOfRows: number): IFTE[] => {
    const arr: IFTE[] = [];

    for (let i = 0; i < numberOfRows; i++) {
        arr.push({
            id: '',
            fteName: null,
            laborRate: null,
            annualSalaryGrowth: null,
            numberOfFTEs: null,
        });
    }
    return arr;
};

const FteInputDataGrid = () => {
    const setData = useNetworkStore((state) => state.setFte);
    const data = useNetworkStore(selectFte);

    return (
        <DataSheetGrid
            headerRowHeight={50}
            createRow={() => createEmptyNewRows(1)[0]}
            value={data}
            columns={columns}
            onChange={setData}
            rowKey='id'
        />
    );
};

export default FteInputDataGrid;
