import { ChangeEvent, ComponentProps } from 'react';

import { Input } from '@/components/ui/input.tsx';
import { cn } from '@/lib/utils.ts';

const TextInputCell = <T,>({
    value,
    onChange,
    accessorKey,
    index,
    isEditable,
    className,
}: {
    value: string;
    onChange: <K extends keyof T>(index: number, key: K, value: T[K]) => void;
    accessorKey: keyof T;
    index: number;
    isEditable: boolean;
    className?: ComponentProps<'div'>['className'];
}) => {
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(index, accessorKey, e.target.value as T[keyof T]);
    };

    return (
        <td
            className={cn(
                'h-[36px] rounded-md border bg-white p-2 text-center',
                className,
                {
                    'border-newDesign-primary': !isEditable,
                    'border-newDesign-divider': isEditable,
                },
            )}>
            {isEditable ? (
                <Input
                    className='h-6 w-full border-none text-center'
                    value={String(value)}
                    onChange={handleInputChange}
                />
            ) : (
                <p className='overflow-hidden text-ellipsis whitespace-nowrap text-newDesign-primary'>
                    {value}
                </p>
            )}
        </td>
    );
};

export default TextInputCell;
