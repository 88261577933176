import { ReactNode } from 'react';
import { IoMdOpen } from 'react-icons/io';
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io';
import { useHistory } from 'react-router-dom';

import { RouteMap } from '@bae/routes';
import { useCurrency } from '@bae/store';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import { cn } from '@/lib/utils.ts';
import { formatData } from '@/libs/components/src/lib/FinancialIndicators/helpers';
import { getColumns } from '@/libs/components/src/lib/FinancialIndicators/helpers/financialTableColumns.ts';
import { IndicatorsTableData } from '@/libs/components/src/lib/FinancialIndicators/types.ts';

const { scenarios, kpis } = RouteMap;

const IndicatorsTable = ({
    tableData,
    data,
    compareToModelId,
    comparisonMode,
    showFullTable,
    disableLinks,
}: {
    tableData: IndicatorsTableData;
    data: IndicatorsTableData;
    compareToModelId?: string;
    comparisonMode?: boolean;
    showFullTable?: boolean;
    disableLinks: boolean;
}) => {
    const history = useHistory();
    const { currency } = useCurrency();

    const comparisonData = data.find((d) => d.id === compareToModelId);
    const filteredTableData = comparisonData
        ? tableData.filter((d) => d.id !== compareToModelId)
        : tableData;

    const modelHasRevenue = !data.some((data) => data.revenue === null);

    const allowedColumns = getColumns(
        showFullTable,
        modelHasRevenue,
        comparisonMode,
        data,
    );

    const navigateToModel = (id: string) => {
        history.push(`${scenarios.path}/${id}/${kpis.path}`);
    };

    const loading =
        tableData.some((d) => d.loading) ||
        !data.length ||
        !allowedColumns.length;

    return (
        <div className='w-full overflow-x-auto'>
            <table className='w-full table-auto border-separate border-spacing-2 text-sm'>
                <thead>
                    {loading ? (
                        <SkeletonHeader />
                    ) : (
                        <TableHeadRow>
                            <th>
                                <p>Scenario</p>
                            </th>
                            {allowedColumns.map((col) => (
                                <th key={col.column}>
                                    <p>{col.label}</p>
                                    {comparisonMode && (
                                        <p className='text-xs font-light'>
                                            {col.caption}
                                        </p>
                                    )}
                                </th>
                            ))}
                        </TableHeadRow>
                    )}
                </thead>
                <tbody>
                    {comparisonData && (
                        <>
                            <TableBodyRow isHighlighted>
                                <td
                                    onClick={() => {
                                        if (!disableLinks)
                                            navigateToModel(compareToModelId);
                                    }}
                                    role='button'
                                    className={
                                        disableLinks
                                            ? 'cursor-default'
                                            : 'cursor-pointer'
                                    }>
                                    <TooltipWrapper
                                        text={
                                            disableLinks
                                                ? comparisonData.name
                                                : `Go to: ${comparisonData.name}`
                                        }>
                                        <span className='flex items-center justify-between'>
                                            <p className='max-w-[145px] overflow-hidden text-ellipsis whitespace-nowrap'>
                                                {comparisonData.name}
                                            </p>
                                            {!disableLinks && <IoMdOpen />}
                                        </span>
                                    </TooltipWrapper>
                                </td>

                                {loading && <SkeletonRow />}

                                {!loading &&
                                    allowedColumns.map((col) => (
                                        <td key={col.column}>
                                            <p>
                                                {formatData(
                                                    comparisonData,
                                                    col.column,
                                                    false,
                                                    currency,
                                                )}
                                            </p>
                                        </td>
                                    ))}
                            </TableBodyRow>

                            <tr className='my-2'>
                                <td
                                    className='h-5'
                                    colSpan={allowedColumns.length + 1}>
                                    <Separator orientation='horizontal' />
                                </td>
                            </tr>
                        </>
                    )}

                    {filteredTableData.map((row) => (
                        <TableBodyRow
                            isHighlighted={!comparisonMode}
                            key={row.id}>
                            <td
                                onClick={() => {
                                    if (!disableLinks) navigateToModel(row.id);
                                }}
                                role='button'
                                className={
                                    disableLinks
                                        ? 'cursor-default'
                                        : 'cursor-pointer'
                                }>
                                <TooltipWrapper
                                    text={
                                        disableLinks
                                            ? row.name
                                            : `Go to: ${row.name}`
                                    }>
                                    <span className='flex items-center justify-between'>
                                        <p className='max-w-[145px] overflow-hidden text-ellipsis whitespace-nowrap'>
                                            {row.name}
                                        </p>
                                        {!disableLinks && <IoMdOpen />}
                                    </span>
                                </TooltipWrapper>
                            </td>

                            {loading && <SkeletonRow />}

                            {!loading &&
                                allowedColumns.map((col) => {
                                    const positiveHighlight =
                                        comparisonMode && row[col.column] > 0;
                                    const negativeHighlight =
                                        comparisonMode && row[col.column] < 0;
                                    return (
                                        <td
                                            key={col.column}
                                            className={cn({
                                                '!border-newDesign-success-light !bg-[#EFFFC7] !bg-opacity-70':
                                                    positiveHighlight,
                                                '!border-newDesign-error-light !bg-[#FFDFD9] !bg-opacity-70':
                                                    negativeHighlight,
                                                'text-newDesign-success-dark':
                                                    row[col.column] > 0,
                                                'text-newDesign-error-dark':
                                                    row[col.column] < 0,
                                            })}>
                                            <div className='flex items-center justify-center gap-0.5'>
                                                <p>
                                                    {formatData(
                                                        row,
                                                        col.column,
                                                        comparisonMode,
                                                        currency,
                                                    )}
                                                </p>
                                                {positiveHighlight && (
                                                    <IoIosArrowRoundUp className='size-5  stroke-[20] text-newDesign-success' />
                                                )}
                                                {negativeHighlight && (
                                                    <IoIosArrowRoundDown className='size-5  stroke-[20] text-newDesign-error' />
                                                )}
                                            </div>
                                        </td>
                                    );
                                })}
                        </TableBodyRow>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const SkeletonHeader = () => {
    const arr = Array.from({ length: 4 }, (_, i) => i);
    return (
        <TableHeadRow>
            <th>
                <p>Scenario</p>
            </th>
            {arr.map((col) => (
                <th key={col}>
                    <Skeleton />
                </th>
            ))}
        </TableHeadRow>
    );
};

const SkeletonRow = () => {
    const arr = Array.from({ length: 4 }, (_, i) => i);
    return arr.map((col) => (
        <td key={col}>
            <Skeleton />
        </td>
    ));
};

function TableHeadRow({ children }: { children: ReactNode }) {
    return (
        <tr
            className={cn(
                'text-newDesign-text-secondary [&_th:first-child]:w-[145px] [&_th:first-child]:bg-[#EDECFF]',
                '[&_th:first-child]:text-center [&_th:first-child]:text-newDesign-primary [&_th:not(:last-child)]:mr-4',
                '[&_th]:h-[44px] [&_th]:min-w-[95px] [&_th]:rounded-md [&_th]:bg-newDesign-background [&_th]:px-2',
                '[&_th]:py-1 [&_th]:text-center [&_th_:first-child]:font-normal',
            )}>
            {children}
        </tr>
    );
}

function TableBodyRow({
    children,
    isHighlighted,
}: {
    children: ReactNode;
    isHighlighted?: boolean;
}) {
    return (
        <tr
            className={cn(
                'text-newDesign-text-secondary [&_td:first-child:hover]:bg-[#EDECFF] [&_td:first-child]:border-newDesign-divider',
                '[&_td:first-child]:bg-white [&_td:first-child]:text-newDesign-primary [&_td:not(:first-child)]:text-center [&_td]:h-[36px]',
                '[&_td]:rounded-md [&_td]:border [&_td]:border-newDesign-divider [&_td]:bg-white [&_td]:p-2',
                {
                    '[&_td:first-child]:border-newDesign-primary':
                        isHighlighted,
                },
            )}>
            {children}
        </tr>
    );
}

export default IndicatorsTable;
