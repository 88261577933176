import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { Datacenter } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { createDeleteParams } from './adapters.ts';

const keyMap = {
    name: 'name',
    delay: 'delay',
};

type DeleteDatacenterParams = {
    modelId: string;
    type: 'model' | 'library';
    data: Datacenter | Datacenter[];
};

const deleteDatacenter = async ({
    modelId,
    type,
    data,
}: DeleteDatacenterParams): Promise<void> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/datacenters`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const dataArray = Array.isArray(data) ? data : [data];

    const payload = createDeleteParams<Datacenter>(keyMap, dataArray);

    const config: AxiosRequestConfig = {
        headers,
        data: payload,
    };

    await AxiosAPIv2.axiosInstance.delete(url, config);
};

type UseDeleteDatacenterOptions = {
    mutationConfig?: MutationConfig<typeof deleteDatacenter>;
};

export const useDeleteDatacenter = ({
    mutationConfig,
}: UseDeleteDatacenterOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: deleteDatacenter,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
