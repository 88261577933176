import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { LibraryUser } from '@bae/data-interface';

import { getLibrariesQueryOptions } from '@/features/library/api/get-libraries.ts';

const addLibraryAccess = (data: LibraryUser): Promise<void> => {
    const { libraryId, readonly, organizationId } = data;

    return AxiosAPIv2.axiosInstance.put(
        `/libraries/${libraryId}/users/${organizationId}`,
        { params: { readonly } },
        { headers: { Authorization: 'Bearer ' + getToken() } },
    );
};

type UseAddLibraryAccessOptions = {
    mutationConfig?: MutationConfig<typeof addLibraryAccess>;
};

export const useAddLibraryAccess = ({
    mutationConfig,
}: UseAddLibraryAccessOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: (...args) => {
            queryClient.invalidateQueries({
                queryKey: getLibrariesQueryOptions().queryKey,
            });
            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: addLibraryAccess,
    });
};
