/**
 * Formats a given timestamp (in milliseconds since the Unix epoch) into a string
 * representing the time of day in the format "HH:MM:SS AM/PM".
 *
 * @param {number} updatedAt - The timestamp to be formatted, in milliseconds since the Unix epoch.
 * @returns {string} - The formatted time string.
 *
 * @example
 * // Assuming the timestamp represents the date and time "2024-06-18T15:20:30Z"
 * const formattedTime = formatUpdatedAt(1718785230000);
 * console.log(formattedTime); // Output: "03:20:30 PM"
 *
 * @remarks
 * This function uses the `toLocaleDateString` method of the `Date` object to format
 * the time part of the given timestamp. The locale is set to 'en-US' to ensure the
 * 12-hour clock format with AM/PM is used. The formatted date string is split on
 * the comma, and the second part (time) is returned.
 */
function formatUpdatedAt(updatedAt: number): string {
    return new Date(updatedAt)
        .toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })
        .split(',')[1];
}

export { formatUpdatedAt };
