import { create } from 'zustand';

import { getNetworkConfigurationMockData } from './mock-data.ts';
import type {
    IAccessPointInput,
    IMistEdgeInput,
    INetworkConfigurationData,
    ISiteInput,
    ISwitchInput,
    IWANEdgeInput,
} from './types.ts';

interface INetworkConfigurationStore extends INetworkConfigurationData {
    setData: () => void;
    setAccessPointInput: (accessPointsInput: IAccessPointInput[]) => void;
    setSwitchesInput: (switchesInput: ISwitchInput[]) => void;
    setMistEdgeInput: (mistEdgeInput: IMistEdgeInput[]) => void;
    setWanEdgeInput: (wanEdgeInput: IWANEdgeInput[]) => void;
    setSitesInput: (sitesInput: ISiteInput[]) => void;
}

export const useNetworkStore = create<INetworkConfigurationStore>()((set) => ({
    accessPointsInput: [],
    switchesInput: [],
    mistEdgeInput: [],
    wanEdgeInput: [],
    sitesInput: [],

    setData: () => {
        const mockData = getNetworkConfigurationMockData();
        set(mockData);
    },

    setAccessPointInput: (accessPointsInput: IAccessPointInput[]) =>
        set({ accessPointsInput }),

    setSwitchesInput: (switchesInput: ISwitchInput[]) => set({ switchesInput }),

    setMistEdgeInput: (mistEdgeInput: IMistEdgeInput[]) =>
        set({ mistEdgeInput }),

    setWanEdgeInput: (wanEdgeInput: IWANEdgeInput[]) => set({ wanEdgeInput }),

    setSitesInput: (sitesInput: ISiteInput[]) => set({ sitesInput }),
}));

export const selectAccessPointsInput = (state: INetworkConfigurationStore) =>
    state.accessPointsInput;

export const selectSwitchesInput = (state: INetworkConfigurationStore) =>
    state.switchesInput;

export const selectMistEdgeInput = (state: INetworkConfigurationStore) =>
    state.mistEdgeInput;

export const selectWanEdgeInput = (state: INetworkConfigurationStore) =>
    state.wanEdgeInput;

export const selectSitesInput = (state: INetworkConfigurationStore) =>
    state.sitesInput;
