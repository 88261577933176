import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ServerCashflow } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { serverCashFlowAdapter } from '../adapters.ts';

type AddServerCashFlowParams = {
    modelId: string;
    type: 'model' | 'library';
    data: ServerCashflow;
};

const addServerCashFlow = async ({
    modelId,
    type,
    data,
}: AddServerCashFlowParams): Promise<ServerCashflow> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/servercfconfig`;

    const payload = serverCashFlowAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return data;
};

type UseAddServerCashFlowOptions = {
    mutationConfig?: MutationConfig<typeof addServerCashFlow>;
};

export const useAddServerCashFlow = ({
    mutationConfig,
}: UseAddServerCashFlowOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: addServerCashFlow,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
