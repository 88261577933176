import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ServiceDemand } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { getDemandZeroTimeseries, serviceDemandAdapter } from '../adapters.ts';
import { useAddServiceDemandDeployment } from './delayed-deployment/add-demand-deployment.ts';

type AddServiceDemandParams = {
    modelId: string;
    type: 'model' | 'library';
    data: ServiceDemand;
};

const addServiceDemand = async ({
    modelId,
    type,
    data,
}: AddServiceDemandParams): Promise<ServiceDemand> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/service_demand`;

    const payload = serviceDemandAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return data;
};

type UseAddServiceDemandOptions = {
    mutationConfig?: MutationConfig<typeof addServiceDemand>;
};

export const useAddServiceDemand = ({
    mutationConfig,
}: UseAddServiceDemandOptions = {}) => {
    const queryClient = useQueryClient();

    const addServiceDemandDeploymentQuery = useAddServiceDemandDeployment({
        invalidate: false,
    });

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: addServiceDemand,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await addServiceDemandDeploymentQuery.mutateAsync({
                modelId,
                type,
                data: getDemandZeroTimeseries(data),
            });

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
