/* eslint-disable camelcase */
import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { AuthAPIRoute, AxiosAPIv2, getToken } from '@bae/api';

import { GetMembershipApiResponse, MembershipData } from '../types.ts';

const membershipAdapter = (d: GetMembershipApiResponse) => {
    return d.memberships.map((d) => ({
        userId: d.user_id === null ? 'None' : d.user_id,
        email: d.email,
        fullName: d.fullname === null ? 'None' : d.fullname,
        organizationAdmin: d.organization_manager,
    }));
};

const getMemberships = async (): Promise<MembershipData[]> => {
    const r = await AxiosAPIv2.axiosInstance.get(
        AuthAPIRoute.membershipCollection(),
        {
            headers: { Authorization: 'Bearer ' + getToken() },
        },
    );
    return membershipAdapter(r.data);
};

export const getMembershipsQueryOptions = () => {
    return queryOptions({
        queryKey: ['memberships'],
        queryFn: () => getMemberships(),
    });
};

type UseMembershipsOptions = {
    queryConfig?: QueryConfig<typeof getMemberships>;
    refetchInterval?: number | false;
};

export const useMemberships = ({
    queryConfig,
    refetchInterval = false,
}: UseMembershipsOptions) => {
    return useQuery({
        ...getMembershipsQueryOptions(),
        ...queryConfig,
        refetchInterval,
    });
};
