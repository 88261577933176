import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { LibraryUser } from '@bae/data-interface';

import { getLibrariesQueryOptions } from './get-libraries.ts';

const deleteLibraryAccess = (data: LibraryUser): Promise<void> => {
    const { libraryId, organizationId } = data;

    return AxiosAPIv2.axiosInstance.delete(
        `/libraries/${libraryId}/users/${organizationId}`,
        { headers: { Authorization: 'Bearer ' + getToken() } },
    );
};

type UseDeleteLibraryAccessOptions = {
    mutationConfig?: MutationConfig<typeof deleteLibraryAccess>;
};

export const useDeleteLibraryAccess = ({
    mutationConfig,
}: UseDeleteLibraryAccessOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: (...args) => {
            queryClient.invalidateQueries({
                queryKey: getLibrariesQueryOptions().queryKey,
            });
            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: deleteLibraryAccess,
    });
};
