import { create } from 'zustand';

interface LibraryPanelState {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    openPanel: () => void;
    closePanel: () => void;
    togglePanel: () => void;
}

export const useLibraryPanelStore = create<LibraryPanelState>((set) => ({
    isOpen: false,
    setIsOpen: (isOpen: boolean) => set({ isOpen }),
    openPanel: () => set({ isOpen: true }),
    closePanel: () => set({ isOpen: false }),
    togglePanel: () => set((state) => ({ isOpen: !state.isOpen })),
}));
