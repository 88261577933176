import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AuthAPIRoute, AxiosAPIv2, getToken } from '@bae/api';

import { getMembershipsQueryOptions } from './get-memberships.ts';

const deleteMembership = ({ email }: { email: string }): Promise<void> => {
    return AxiosAPIv2.axiosInstance.delete(AuthAPIRoute.membershipItem(email), {
        headers: { Authorization: 'Bearer ' + getToken() },
    });
};

type UseDeleteMembershipOptions = {
    mutationConfig?: MutationConfig<typeof deleteMembership>;
};

export const useDeleteMembership = ({
    mutationConfig,
}: UseDeleteMembershipOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: (...args) => {
            queryClient.invalidateQueries({
                queryKey: getMembershipsQueryOptions().queryKey,
            });
            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: deleteMembership,
    });
};
