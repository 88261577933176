import { z } from 'zod';

import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { LibraryUser } from '@bae/data-interface';

const getLibraryUserSchema = z.object({
    library_users: z.array(
        z.object({
            library_id: z.string(),
            user_organization_id: z.string(),
            user_organization_name: z.string(),
            library_description: z.string(),
            readonly: z.boolean(),
        }),
    ),
});

export type IGetLibraryUserApi = z.infer<typeof getLibraryUserSchema>;

const getLibraryUsers = async ({
    libraryId,
}: {
    libraryId: string;
}): Promise<LibraryUser[]> => {
    const response = await AxiosAPIv2.axiosInstance.get<IGetLibraryUserApi>(
        `/libraries/${libraryId}/users`,
        {
            headers: { Authorization: 'Bearer ' + getToken() },
        },
    );

    return response.data.library_users.map((d) => ({
        libraryId: d.library_id,
        organizationId: d.user_organization_id,
        organizationName: d.user_organization_name,
        readonly: d.readonly,
    }));
};

export const getLibraryUsersQueryOptions = (libraryId: string) => {
    return queryOptions({
        queryKey: ['libraries', libraryId, 'users'],
        queryFn: () => getLibraryUsers({ libraryId }),
    });
};

type UseLibraryUsersOptions = {
    libraryId: string;
    queryConfig?: QueryConfig<typeof getLibraryUsersQueryOptions>;
    refetchInterval?: number | false;
    enabled?: boolean;
};

export const useLibraryUsers = ({
    queryConfig,
    refetchInterval,
    libraryId,
    enabled = true,
}: UseLibraryUsersOptions) => {
    return useQuery({
        ...getLibraryUsersQueryOptions(libraryId),
        ...queryConfig,
        refetchInterval,
        enabled,
    });
};
