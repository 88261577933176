import { Skeleton } from '@/components/ui/skeleton.tsx';

import { TableBodyRow, TableHeadRow } from '../../shared/TableComponents';
import {
    NumberInputCell,
    TextInputCell,
} from '../../shared/TableComponents/CellComponents';
import { Server } from '../../shared/types.ts';
import { getCloudVMs, useVirtualMachineComparisonStore } from '../store';

const vmLabels: Record<keyof Server, { label: string; isEditable: boolean }> = {
    serverName: { label: 'VM', isEditable: true },
    vcpu: { label: 'vCPU', isEditable: true },
    memory: { label: 'Memory', isEditable: true },
};

const CloudVMs = () => {
    const cloudVMs = useVirtualMachineComparisonStore(getCloudVMs);
    const updateCloudVM = useVirtualMachineComparisonStore(
        (state) => state.updateCloudVM,
    );
    const loading = cloudVMs.length === 0;

    return (
        <div className='w-full overflow-x-auto'>
            <table className='w-full table-fixed border-separate border-spacing-2 text-sm'>
                <thead>
                    {loading ? (
                        <SkeletonHeader />
                    ) : (
                        <TableHeadRow>
                            {Object.keys(vmLabels).map((key) => (
                                <th key={key} className='text-center'>
                                    <p className='whitespace-nowrap'>
                                        {vmLabels[key as keyof Server].label}
                                    </p>
                                </th>
                            ))}
                        </TableHeadRow>
                    )}
                </thead>
                <tbody>
                    {loading ? (
                        <SkeletonRow />
                    ) : (
                        cloudVMs.map((vm, index) => {
                            return (
                                <TableBodyRow key={index}>
                                    <TextInputCell<Server>
                                        value={vm.serverName}
                                        onChange={updateCloudVM}
                                        accessorKey={'serverName'}
                                        index={index}
                                        isEditable={true}
                                        className='min-w-[200px]'
                                    />
                                    <NumberInputCell<Server>
                                        value={vm.vcpu}
                                        index={index}
                                        accessorKey={'vcpu'}
                                        onChange={updateCloudVM}
                                    />
                                    <NumberInputCell<Server>
                                        value={vm.memory}
                                        index={index}
                                        accessorKey={'memory'}
                                        onChange={updateCloudVM}
                                    />
                                </TableBodyRow>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
};

const SkeletonHeader = () => {
    return (
        <TableHeadRow>
            <th>VM Name</th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
        </TableHeadRow>
    );
};

const SkeletonRow = () => {
    const arr = Array.from({ length: 4 }, (_, i) => i);
    return (
        <TableBodyRow>
            <td>
                <Skeleton />
            </td>
            {arr.map((col) => (
                <td key={col}>
                    <Skeleton />
                </td>
            ))}
        </TableBodyRow>
    );
};

export default CloudVMs;
