import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, CreateModelResponse, getToken } from '@bae/api';

import { getInputQueryOptions } from './get-input.ts';

interface ImporFileResponse {
    model_name: string;
    model_id: string;
}

const importFile = async ({
    modelId,
    file,
}: {
    modelId: string;
    file: File;
}): Promise<CreateModelResponse> => {
    const formData: FormData = new FormData();
    formData.append('model_inputfile', file, file.name);
    formData.append('replace_model_id', modelId);

    const response = await AxiosAPIv2.axiosInstance.post<ImporFileResponse>(
        `models`,
        formData,
        {
            headers: { Authorization: 'Bearer ' + getToken() },
        },
    );

    return {
        id: response.data.model_id,
        name: response.data.model_name,
    };
};

type UseImportModelFileOptions = {
    mutationConfig?: MutationConfig<typeof importFile>;
};

export const useImportModelFile = ({
    mutationConfig,
}: UseImportModelFileOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: async (data, ...args) => {
            await queryClient.invalidateQueries({
                queryKey: getInputQueryOptions(data.id, 'model').queryKey,
            });

            await queryClient.invalidateQueries({
                queryKey: ['model_groups'],
            });

            onSuccess?.(data, ...args);
        },
        ...restConfig,
        mutationFn: importFile,
    });
};
