import { EndPointsInput, EndPointTimeSeriesInput } from '@bae/api';
import { Endpoint, EndpointDeployment } from '@bae/data-interface';
import { isNullOrUndefined } from '@bae/utils';

export const endpointTimeseriesAdapter = (
    data: EndpointDeployment,
): EndPointTimeSeriesInput => {
    return {
        tenantname: data.tenantName,
        endpointname: data.endpointName,

        delay: data.delay,

        quantity: data.quantityInitial,
        terminal_quantity: data.quantityTerminal,
        quantity_growth: data.quantityGrowth,

        users: 0,
        users_growth: 0,
        users_termvalue: null,

        datavolume: 0,
        datavolume_growth: 0,
        datavolume_termvalue: null,
    };
};

export const endpointAdapter = (data: Endpoint): EndPointsInput => {
    return {
        tenantname: data.tenantName,
        endpointname: data.endpointName,
        latitude: data.latitude,
        longitude: data.longitude,
        cpemodel:
            isNullOrUndefined(data.cpeModel) || data.cpeModel.length === 0
                ? null
                : data.cpeModel,
    };
};

export const createDeleteParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
): Record<string, string>[] => {
    return data.map((datum) => {
        const datumParams: Record<string, string> = {};

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams[apiKey] = datum[moduleKey];
        });

        return datumParams;
    });
};
