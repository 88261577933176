import React from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { CiViewList } from 'react-icons/ci';
import { PiUserList } from 'react-icons/pi';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';

import { RouteMap } from '@bae/routes';
import { AlertBoxRedux, Theme } from '@bae/ui';

import SideBar from '@/layout/SideBar.tsx';

import PrivateRoute from '../PrivateRoute.tsx';
import {
    PUBLIC_ROUTES,
    RouteInterface,
    COMMON_ROUTES,
} from '../shared-routes.ts';
import Overview from './pages/Overview/Overview.tsx';
import WhatIfScenarios from './pages/WhatIfScenarios/WhatIfScenarios.tsx';

const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
        <ThemeProvider theme={Theme}>
            <AlertBoxRedux />
            {children}
        </ThemeProvider>
    );
};

const SidebarLayout = ({ children }) => {
    const location = useLocation();
    const isShared = location.pathname.split('/')[1] === 'shared';

    return (
        <div className='flex h-dvh max-h-screen'>
            {!isShared && (
                <SideBar logoNavigateTo={RouteMap.overview.path}>
                    <SideBar.Link
                        to={RouteMap.overview.path}
                        text={RouteMap.overview.label}
                        icon={<AiOutlineHome className='size-6' />}
                    />
                    <SideBar.Link
                        to={RouteMap.scenarios.path}
                        text={RouteMap.scenarios.label}
                        icon={<CiViewList className='size-6' />}
                    />
                    <SideBar.Link
                        to={RouteMap.memberships.path}
                        text={RouteMap.memberships.label}
                        icon={<PiUserList className='size-6' />}
                    />
                </SideBar>
            )}
            <main className='flex w-full flex-1 overflow-auto p-6 pb-1'>
                {children}
            </main>
        </div>
    );
};

const DIGITAL_TWIN_ROUTES: Array<RouteInterface> = [
    {
        id: 'gf-path:/gf-overview',
        path: RouteMap.overview.path,
        exact: true,
        component: Overview,
    },
    {
        id: 'gf-path:/what-if-scenarios',
        path: RouteMap.scenarios.path,
        exact: true,
        component: WhatIfScenarios,
    },
];

export const DigitalTwinRouter = () => {
    return (
        <ThemeWrapper>
            <SidebarLayout>
                <Switch>
                    {PUBLIC_ROUTES.map((route) => (
                        <Route
                            key={route.id}
                            path={route.path}
                            component={route.component}
                        />
                    ))}

                    {COMMON_ROUTES.map((route) => (
                        <PrivateRoute
                            key={route.id}
                            path={route.path}
                            component={route.component}
                            exact={route.exact}
                        />
                    ))}

                    {DIGITAL_TWIN_ROUTES.map((route) => (
                        <PrivateRoute
                            key={route.id}
                            path={route.path}
                            component={route.component}
                            exact={route.exact}
                        />
                    ))}

                    <PrivateRoute
                        path='*'
                        component={() => (
                            <Redirect to={RouteMap.overview.path} />
                        )}
                    />
                </Switch>
            </SidebarLayout>
        </ThemeWrapper>
    );
};
