/* eslint-disable camelcase */
import { cva } from 'class-variance-authority';

import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default:
                    'bg-primary text-primary-foreground hover:bg-primary/90',
                destructive:
                    'bg-destructive text-destructive-foreground hover:bg-destructive/90',
                outline:
                    'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
                secondary:
                    'bg-secondary text-secondary-foreground hover:bg-secondary/80',
                ghost: 'hover:bg-accent hover:text-accent-foreground',
                link: 'text-primary underline-offset-4 hover:underline',
            },
            size: {
                default: 'h-10 px-4 py-2',
                sm: 'h-9 rounded-md px-3',
                lg: 'h-11 rounded-md px-8',
                icon: 'h-10 w-10',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
    className,
    classNames,
    showOutsideDays = true,
    ...props
}: CalendarProps) {
    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={cn('p-3', className)}
            classNames={{
                months: 'flex flex-row',
                month: 'space-y-4',
                month_caption: 'flex pt-1',
                caption_label: 'text-lg font-medium',
                button_previous: cn(
                    buttonVariants({ variant: 'outline' }),

                    'mr-2 mt-3 h-7 w-7 cursor-pointer p-0 opacity-50 hover:opacity-100',
                ),
                button_next: cn(
                    buttonVariants({ variant: 'outline' }),

                    'mr-3 mt-3 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
                ),
                month_grid: 'w-full border-collapse space-y-1',
                weekdays: 'flex gap-2',
                weekday:
                    'text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]',
                week: 'flex w-full gap-2 mt-2',
                day: cn(
                    buttonVariants({ variant: 'outline' }),
                    'relative p-0 text-center text-sm focus-within:relative focus-within:z-20 hover:bg-newDesign-background [&:has([aria-selected])]:bg-newDesign-text-secondary [&:has([aria-selected].day-outside)]:bg-newDesign-text-secondary/50 [&:has([aria-selected].day-range-end)]:rounded-r-md',
                    'h-8 w-8 p-0 font-normal aria-selected:opacity-100',
                    props.mode === 'range'
                        ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
                        : '[&:has([aria-selected])]:rounded-md',
                ),
                range_start: 'day-range-start',
                range_end: 'day-range-end',
                selected:
                    'bg-newDesign-primary text-white hover:bg-newDesign-secondary hover:text-white focus:bg-newDesign-primary focus:text-white',
                today: 'bg-accent text-accent-foreground',
                outside:
                    'day-outside text-muted-foreground opacity-50  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
                disabled: 'text-muted-foreground opacity-50',
                range_middle:
                    'aria-selected:bg-accent aria-selected:text-accent-foreground',
                hidden: 'invisible',
                ...classNames,
            }}
            components={{
                PreviousMonthButton: ({ ...props }) => (
                    <button {...props}>
                        <ChevronLeftIcon className='size-4' />
                    </button>
                ),
                NextMonthButton: ({ ...props }) => (
                    <button {...props}>
                        <ChevronRightIcon className='size-4' />
                    </button>
                ),
            }}
            {...props}
        />
    );
}

Calendar.displayName = 'Calendar';

export { Calendar };
