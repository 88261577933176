import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ServiceSpecs } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import {
    getDefaultSpecs,
    getStandardPlan,
    serviceSpecAdapter,
} from './adapters.ts';
import { useAddServicePlan } from './plans/add-service-plan.ts';

type AddServiceParams = {
    modelId: string;
    type: 'model' | 'library';
    serviceName: string;
};

const addService = async ({
    modelId,
    type,
    serviceName,
}: AddServiceParams): Promise<ServiceSpecs> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/servicespecs`;

    const data = getDefaultSpecs(serviceName);

    const payload = serviceSpecAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return data;
};

type UseAddServiceOptions = {
    mutationConfig?: MutationConfig<typeof addService>;
};

export const useAddService = ({
    mutationConfig,
}: UseAddServiceOptions = {}) => {
    const queryClient = useQueryClient();

    const addServicePlanQuery = useAddServicePlan({
        invalidate: false,
    });

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: addService,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await addServicePlanQuery.mutateAsync({
                modelId,
                type,
                data: getStandardPlan(data.serviceName),
            });

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
