import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { Server } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { serverAdapter } from './adapters.ts';

type EditServerParams = {
    modelId: string;
    type: 'model' | 'library';
    prevData: Server;
    nextData: Server;
};

const editServer = async ({
    modelId,
    type,
    prevData,
    nextData,
}: EditServerParams): Promise<Server> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/servers`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const params = {
        name: prevData.serverName,
        model_id: modelId,
    };

    const config: AxiosRequestConfig = {
        headers,
        params,
    };

    const payload = serverAdapter(nextData);

    await AxiosAPIv2.axiosInstance.patch(url, payload, config);

    return nextData;
};

type UseEditServerOptions = {
    mutationConfig?: MutationConfig<typeof editServer>;
};

export const useEditServer = ({
    mutationConfig,
}: UseEditServerOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: editServer,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
