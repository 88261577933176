import React, { useState } from 'react';
import { IoDocumentTextOutline } from 'react-icons/io5';

import { Button } from '@/components/ui/button.tsx';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
} from '@/components/ui/popover';
import { Textarea } from '@/components/ui/text-area.tsx';
import { cn } from '@/lib/utils';

type NotesCellProps<T> = {
    value: string | null;
    onChange: <K extends keyof T>(index: number, key: K, value: T[K]) => void;
    accessorKey: keyof T;
    index: number;
    className?: string;
};

const NotesCell = <T,>({
    value,
    onChange,
    accessorKey,
    index,
    className,
}: NotesCellProps<T>) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [inputValue, setInputValue] = useState<string>(value || '');

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onChange(index, accessorKey, newValue as T[keyof T]);
    };

    return (
        <td
            className={cn(
                'h-[36px] rounded-md border bg-white p-2 text-center',
                className,
            )}>
            <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant='text'
                        className='h-5 hover:bg-transparent active:bg-transparent'>
                        <IoDocumentTextOutline className='text-newDesign-primary' />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className='w-80'>
                    <div className=''>
                        <p className='mb-1 font-semibold'>Notes</p>
                        <Textarea
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder='Type your notes here'
                            className='h-40'
                        />
                    </div>
                </PopoverContent>
            </Popover>
        </td>
    );
};

export default NotesCell;
