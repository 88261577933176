/* eslint-disable camelcase */
import { z } from 'zod';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AuthAPIRoute, AxiosAPIv2, getToken } from '@bae/api';

import { getMembershipsQueryOptions } from './get-memberships.ts';

export const addMembershipSchema = z.object({
    email: z.string().min(1, 'Required'),
    organization_manager: z.boolean({
        required_error: 'organization_manager is required',
        invalid_type_error: 'organization_manager must be a boolean',
    }),
});

export type AddMembershipInput = z.infer<typeof addMembershipSchema>;

const addMembership = (payload: AddMembershipInput): Promise<void> => {
    return AxiosAPIv2.axiosInstance.post(
        AuthAPIRoute.membershipCollection(),
        null,
        {
            headers: { Authorization: 'Bearer ' + getToken() },
            params: {
                email: payload.email,
                organization_manager: payload.organization_manager,
            },
        },
    );
};

type UseAddMembershipOptions = {
    mutationConfig?: MutationConfig<typeof addMembership>;
};

export const useAddMembership = ({
    mutationConfig,
}: UseAddMembershipOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: (...args) => {
            queryClient.invalidateQueries({
                queryKey: getMembershipsQueryOptions().queryKey,
            });
            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: addMembership,
    });
};
