import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import { getInputQueryOptions } from './get-input.ts';

const resetModel = async ({
    modelId,
}: {
    modelId: string;
}): Promise<string> => {
    await AxiosAPIv2.axiosInstance.delete(`/models/${modelId}/inputs`, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return modelId;
};

type UseResetModelOptions = {
    mutationConfig?: MutationConfig<typeof resetModel>;
};

export const useResetModel = ({ mutationConfig }: UseResetModelOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: async (id, ...args) => {
            await queryClient.invalidateQueries({
                queryKey: getInputQueryOptions(id, 'model').queryKey,
            });
            onSuccess?.(id, ...args);
        },
        ...restConfig,
        mutationFn: resetModel,
    });
};
