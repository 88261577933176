/* eslint-disable camelcase */
import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AuthAPIRoute, AxiosAPIv2, getToken } from '@bae/api';

import { AddMembershipInput } from './add-membership.ts';
import { getMembershipsQueryOptions } from './get-memberships.ts';

const editMembership = (payload: AddMembershipInput): Promise<void> => {
    return AxiosAPIv2.axiosInstance.patch(
        AuthAPIRoute.membershipItem(payload.email),
        null,
        {
            headers: { Authorization: 'Bearer ' + getToken() },
            params: {
                organization_manager: payload.organization_manager,
            },
        },
    );
};

type UseEditMembershipOptions = {
    mutationConfig?: MutationConfig<typeof editMembership>;
};

export const useEditMembership = ({
    mutationConfig,
}: UseEditMembershipOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: (data, ...args) => {
            queryClient.refetchQueries({
                queryKey: getMembershipsQueryOptions().queryKey,
            });
            onSuccess?.(data, ...args);
        },
        ...restConfig,
        mutationFn: editMembership,
    });
};
