import { TenantInput, TenantTimeseriesInput } from '@bae/api';
import { Tenant, TenantDeployment } from '@bae/data-interface';

export const tenantAdapter = (data: Tenant): TenantInput => {
    return {
        name: data.name,
    };
};

export const tenantTimeseriesAdapter = (
    data: TenantDeployment,
): TenantTimeseriesInput => {
    return {
        name: data.name,
        quantity: data.quantityInitial,
        terminal_quantity: data.quantityTerminal,
        quantity_growth: data.quantityGrowth,
        delay: data.delay,
    };
};

export const createDeleteParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
): Record<string, string>[] => {
    return data.map((datum) => {
        const datumParams: Record<string, string> = {};

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams[apiKey] = datum[moduleKey];
        });

        return datumParams;
    });
};
