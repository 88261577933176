import React, { ComponentProps, useEffect, useState } from 'react';
import 'react-datasheet-grid/dist/style.css';

import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx';

import AwsCloudPortInputDataGrid from './Tabs/AwsCloudPortInput.tsx';
import AzureCloudPortInputDataGrid from './Tabs/AzureCloudPortInput.tsx';
import FteInputDataGrid from './Tabs/FteInput.tsx';
import GcpCloudPortInputDataGrid from './Tabs/GcpCloudPortInput.tsx';
import NetworkInputDataGrid from './Tabs/NetworkInput.tsx';
import TabContent from './components/TabContent.tsx';
import { useNetworkStore } from './store.ts';

const NetworkConfiguration = ({
    className,
    buttonProps,
}: {
    className?: ComponentProps<'div'>['className'];
    buttonProps?: ComponentProps<typeof Button>;
}) => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Button {...buttonProps} className={className} onClick={openModal}>
                Global Fabric Configuration
            </Button>

            <Dialog open={showModal} onOpenChange={closeModal}>
                <DialogContent className='max-w-[99dvw]'>
                    <DialogHeader>
                        <DialogTitle>Global Fabric Configuration</DialogTitle>
                    </DialogHeader>
                    <NetworkConfigurationDataGrid />
                    <Separator orientation='horizontal' />
                    <DialogFooter className='items-center'>
                        <Button
                            onClick={closeModal}
                            variant='text'
                            className='text-newDesign-text-secondary'>
                            Cancel
                        </Button>
                        <Button>Save</Button>
                        <Button>Save as</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
};

const NetworkConfigurationDataGrid = () => {
    const setData = useNetworkStore((state) => state.setData);

    useEffect(() => {
        setData();
    }, []);

    return (
        <div className='h-full w-[calc(99dvw-48px)]'>
            <Tabs defaultValue='1'>
                <TabsList>
                    <TabsTrigger value='1'>Network Site Input</TabsTrigger>
                    <TabsTrigger value='2'>AWS Cloud Port Input</TabsTrigger>
                    <TabsTrigger value='3'>Azure Cloud Port Input</TabsTrigger>
                    <TabsTrigger value='4'>GCP Cloud Port Input</TabsTrigger>
                    {/*<TabsTrigger value='5'>Discounts</TabsTrigger>*/}
                    <TabsTrigger value='6'>FTE</TabsTrigger>
                </TabsList>

                <TabContent tabValue='1'>
                    <NetworkInputDataGrid />
                </TabContent>

                <TabContent tabValue='2'>
                    <AwsCloudPortInputDataGrid />
                </TabContent>

                <TabContent tabValue='3'>
                    <AzureCloudPortInputDataGrid />
                </TabContent>

                <TabContent tabValue='4'>
                    <GcpCloudPortInputDataGrid />
                </TabContent>

                <TabContent tabValue='6'>
                    <FteInputDataGrid />
                </TabContent>
            </Tabs>
        </div>
    );
};

export default NetworkConfiguration;
