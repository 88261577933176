import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { EndpointDeployment } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { endpointTimeseriesAdapter } from '../adapters.ts';

type AddEndpointDeploymentParams = {
    modelId: string;
    type: 'model' | 'library';
    data: EndpointDeployment;
};

const addEndpointDeployment = async ({
    modelId,
    type,
    data,
}: AddEndpointDeploymentParams): Promise<EndpointDeployment> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/endpoint_timeseries`;

    const payload = endpointTimeseriesAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return data;
};

type UseAddEndpointDeploymentOptions = {
    mutationConfig?: MutationConfig<typeof addEndpointDeployment>;
    invalidate?: boolean;
};

export const useAddEndpointDeployment = ({
    mutationConfig,
    invalidate = true,
}: UseAddEndpointDeploymentOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: addEndpointDeployment,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            if (invalidate) {
                await queryClient.invalidateQueries({
                    queryKey: getQueryKey(modelId, type),
                });
            }

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
