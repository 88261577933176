function removeLeadingZeros(val: string | number) {
    const numberString = String(val);

    return numberString.replace(
        /^0*(\d+)?(\.\d+)?$/,
        (_: unknown, intPart: string, fracPart: string) => {
            if (intPart) {
                return intPart + (fracPart || '');
            } else if (fracPart) {
                return '0' + fracPart;
            } else {
                return '0';
            }
        },
    );
}

export default removeLeadingZeros;
