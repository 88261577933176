/* eslint-disable camelcase */
import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import { Currency } from '../index.ts';

export interface OrganizationSettings {
    currency: Currency;
    capabilities: string[];
}

export interface OrganizationSettingsApi {
    settings: {
        currency: Currency;
        capabilities: string[];
    };
}

const getOrganizationSettings = async (): Promise<OrganizationSettings> => {
    const response =
        await AxiosAPIv2.axiosInstance.get<OrganizationSettingsApi>(
            '/organizations/current/settings',
            {
                headers: { Authorization: 'Bearer ' + getToken() },
            },
        );

    return response.data.settings;
};

export const getOrganizationSettingsQueryOptions = () => {
    return queryOptions({
        queryKey: ['organizations/current'],
        queryFn: () => getOrganizationSettings(),
    });
};

type UseOrganizationSettingsOptions = {
    queryConfig?: QueryConfig<typeof getOrganizationSettings>;
    enabled?: boolean;
    refetchInterval?: number | false;
};

export const useGetOrganizationSettings = ({
    queryConfig,
    enabled,
    refetchInterval = false,
}: UseOrganizationSettingsOptions) => {
    return useQuery({
        ...getOrganizationSettingsQueryOptions(),
        ...queryConfig,
        refetchInterval,
        enabled,
    });
};
