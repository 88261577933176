import { AppProvider } from '../main-provider.tsx';
import { BAERouter } from './routes.tsx';

const BAEApp = () => {
    return (
        <AppProvider>
            <BAERouter />
        </AppProvider>
    );
};

export default BAEApp;
