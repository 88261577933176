import { Check, ChevronsUpDown } from 'lucide-react';
import React, { ComponentProps, useEffect, useState } from 'react';
import 'react-datasheet-grid/dist/style.css';
import { GoOrganization } from 'react-icons/go';

import { Button } from '@/components/ui/button.tsx';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from '@/components/ui/command.tsx';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx';

import AccessPointsInputDataGrid from './Tabs/AccessPointsInput.tsx';
import MistEdgeDataGrid from './Tabs/MistEdgeInput.tsx';
import SiteDataGrid from './Tabs/SiteInput.tsx';
import SwitchesDataGrid from './Tabs/SwitchesInput.tsx';
import WanEdgesDataGrid from './Tabs/WanEdgesInput.tsx';
import TabContent from './components/TabContent.tsx';
import { SITE_OPTIONS } from './constants.ts';
import { useNetworkStore } from './store.ts';

const NetworkConfiguration = ({
    className,
    buttonProps,
}: {
    className?: ComponentProps<'div'>['className'];
    buttonProps?: ComponentProps<typeof Button>;
}) => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Button {...buttonProps} className={className} onClick={openModal}>
                Mist Configuration
            </Button>

            <Dialog open={showModal} onOpenChange={closeModal}>
                <DialogContent className='max-w-[99dvw]'>
                    <DialogHeader>
                        <DialogTitle>Mist Configuration</DialogTitle>
                    </DialogHeader>
                    <NetworkConfigurationDataGrid />
                    <Separator orientation='horizontal' />
                    <DialogFooter className='items-center'>
                        <Button
                            onClick={closeModal}
                            variant='text'
                            className='text-newDesign-text-secondary'>
                            Cancel
                        </Button>
                        <Button>Save</Button>
                        <Button>Save as</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
};

const NetworkConfigurationDataGrid = () => {
    const setData = useNetworkStore((state) => state.setData);

    useEffect(() => {
        setData();
    }, []);

    return (
        <div className='h-full w-[calc(99dvw-48px)]'>
            <Tabs defaultValue='1'>
                <div className='flex w-full items-center justify-between gap-4'>
                    <TabsList>
                        <TabsTrigger value='1'>Access Points</TabsTrigger>
                        <TabsTrigger value='2'>Switches</TabsTrigger>
                        <TabsTrigger value='3'>WAN Edges</TabsTrigger>
                        <TabsTrigger value='4'>Mist Edge</TabsTrigger>
                        <TabsTrigger value='5'>Sites</TabsTrigger>
                    </TabsList>
                    <ComboboxDemo />
                </div>

                <TabContent tabValue='1'>
                    <AccessPointsInputDataGrid />
                </TabContent>

                <TabContent tabValue='2'>
                    <SwitchesDataGrid />
                </TabContent>

                <TabContent tabValue='3'>
                    <WanEdgesDataGrid />
                </TabContent>

                <TabContent tabValue='4'>
                    <MistEdgeDataGrid />
                </TabContent>

                <TabContent tabValue='5'>
                    <SiteDataGrid />
                </TabContent>
            </Tabs>
        </div>
    );
};

const viewOptions = [
    {
        value: 'all',
        label: 'Entire Org.',
    },
    ...SITE_OPTIONS,
];

export function ComboboxDemo() {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('all');

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    size='sm'
                    variant='outline'
                    role='combobox'
                    aria-expanded={open}
                    className='text-newDesign-text w-[200px] justify-between border-newDesign-black'>
                    {value
                        ? viewOptions.find((option) => option.value === value)
                              ?.label
                        : 'Select site...'}
                    <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
                </Button>
            </PopoverTrigger>
            <PopoverContent className='w-[200px] p-0'>
                <Command>
                    <CommandInput placeholder='Search site...' />
                    <CommandList>
                        <CommandEmpty>No option found.</CommandEmpty>
                        <CommandGroup>
                            {viewOptions.map((option) => {
                                if (option.value === 'all') {
                                    return (
                                        <React.Fragment key={option.value}>
                                            <CommandItem
                                                className='cursor-pointer hover:bg-neutral-100'
                                                value={option.value}
                                                onSelect={(currentValue) => {
                                                    setValue(currentValue);
                                                    setOpen(false);
                                                }}>
                                                <GoOrganization className='mr-2 h-4 w-4' />
                                                {option.label}
                                                {option.value === value && (
                                                    <span className='flex flex-1 justify-end'>
                                                        <Check className='size-4' />
                                                    </span>
                                                )}
                                            </CommandItem>
                                            <CommandSeparator className='my-1 bg-newDesign-divider' />
                                        </React.Fragment>
                                    );
                                }
                                return (
                                    <CommandItem
                                        className='cursor-pointer hover:bg-neutral-100'
                                        key={option.value}
                                        value={option.value}
                                        onSelect={(currentValue) => {
                                            setValue(currentValue);
                                            setOpen(false);
                                        }}>
                                        {option.label}
                                        {option.value === value && (
                                            <span className='flex flex-1 justify-end'>
                                                <Check className='size-4' />
                                            </span>
                                        )}
                                    </CommandItem>
                                );
                            })}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}

export default NetworkConfiguration;
