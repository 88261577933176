import React, { useEffect, useState } from 'react';
import { HiMiniMagnifyingGlass } from 'react-icons/hi2';
import { IoIosClose } from 'react-icons/io';
import { toast } from 'react-toastify';

import { LibraryIcon } from '@bae/icons';
import { ButtonAddLibrary } from '@bae/modules/library-management';
import { EnhancedTooltip } from '@bae/ui';

import { Input } from '@/components/ui/input.tsx';
import { Sheet, SheetContent } from '@/components/ui/sheet.tsx';
import {
    useLibraries,
    useLibraryComponent,
} from '@/features/library/api/get-libraries.ts';
import { cn } from '@/lib/utils.ts';
import SelectStandard from '@/libs/ui/src/lib/SelectStandard';

import { ModelNodeTypes } from '../model-viewer/types';
import ButtonSettings from './ButtonSettings';
import PanelComponentSection, {
    ComponentSection,
} from './PanelComponentSection';
import TooltipLibrary from './TooltipLibrary';
import { useLibraryPanelStore } from './store.ts';

interface LibraryDrawerProps {
    modelInfo: { id: string; name: string };
}

const LibraryDrawer = ({ modelInfo }: LibraryDrawerProps) => {
    const isOpen = useLibraryPanelStore((state) => state.isOpen);
    const setIsOpen = useLibraryPanelStore((state) => state.setIsOpen);

    const [selectedLibraryId, setSelectedLibraryId] = useState<string>('all');
    const [searchText, setSearchText] = useState<string>('');
    const [expanded, setExpanded] = useState<ModelNodeTypes | false>(
        ModelNodeTypes.service,
    );

    const libraries = useLibraries({});

    const services = useLibraryComponent({
        component: 'services',
        enabled: isOpen,
    });
    const resources = useLibraryComponent({
        component: 'applications',
        enabled: isOpen,
    });
    const servers = useLibraryComponent({
        component: 'servers',
        enabled: isOpen,
    });
    const ftes = useLibraryComponent({
        component: 'ftes',
        enabled: isOpen,
    });
    const cpes = useLibraryComponent({
        component: 'cpes',
        enabled: isOpen,
    });

    useEffect(() => {
        if (libraries.isError) {
            toast.error('Error fetching libraries');
        }
    }, [libraries.isError]);

    const tooltipContent = TooltipLibrary();

    const filteredLibraries =
        libraries.data?.filter(
            (d) => selectedLibraryId === 'all' || d.id === selectedLibraryId,
        ) || [];

    const newSections: ComponentSection[] = [
        {
            type: ModelNodeTypes.service,
            title: 'Services',
            loading: services.isLoading || libraries.isFetching,
            groups: filteredLibraries.map((library) => ({
                library,
                items:
                    services.data
                        ?.filter((d) => d.libraryId === library.id)
                        ?.sort((a, b) => a.name.localeCompare(b.name)) || [],
            })),
        },
        {
            type: ModelNodeTypes.application,
            title: 'Resources',
            loading: resources.isLoading || libraries.isFetching,
            groups: filteredLibraries.map((library) => ({
                library,
                items:
                    resources.data
                        ?.filter((d) => d.libraryId === library.id)
                        ?.sort((a, b) => a.name.localeCompare(b.name)) || [],
            })),
        },
        {
            type: ModelNodeTypes.server,
            title: 'Servers',
            loading: servers.isLoading || libraries.isFetching,
            groups: filteredLibraries.map((library) => ({
                library,
                items:
                    servers.data
                        ?.filter((d) => d.libraryId === library.id)
                        ?.sort((a, b) => a.name.localeCompare(b.name)) || [],
            })),
        },
        {
            type: ModelNodeTypes.fte,
            title: 'FTEs',
            loading: ftes.isLoading || libraries.isFetching,
            groups: filteredLibraries.map((library) => ({
                library,
                items:
                    ftes.data
                        ?.filter((d) => d.libraryId === library.id)
                        ?.sort((a, b) => a.name.localeCompare(b.name)) || [],
            })),
        },
        {
            type: ModelNodeTypes.cpe,
            title: 'CPEs',
            loading: cpes.isLoading || libraries.isFetching,
            groups: filteredLibraries.map((library) => ({
                library,
                items:
                    cpes.data
                        ?.filter((d) => d.libraryId === library.id)
                        ?.sort((a, b) => a.name.localeCompare(b.name)) || [],
            })),
        },
    ];

    const options = [{ value: 'all', label: 'All available' }].concat(
        libraries.data?.map((library) => ({
            value: library.id,
            label: library.name,
        })) || [],
    );

    const handleExpand =
        (type: ModelNodeTypes) =>
        (_event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
            setExpanded(isExpanded ? type : false);
        };

    return (
        <Sheet
            open={isOpen}
            onOpenChange={(newOpen) => {
                setIsOpen(newOpen);
                if (!newOpen) {
                    setSearchText('');
                    setSelectedLibraryId('all');
                }
            }}>
            <SheetContent side='right' className='bg-white'>
                <div>
                    <div className='flex items-center justify-between px-4 py-2'>
                        <p className='text-lg text-newDesign-text-primary'>
                            Library
                            <EnhancedTooltip
                                icon={<LibraryIcon />}
                                title='Library'
                                content={tooltipContent}
                            />
                        </p>

                        <div className='flex justify-between'>
                            <ButtonSettings />
                            <ButtonAddLibrary />
                        </div>
                    </div>

                    <div className='cursor-pointer px-4 pb-2'>
                        <SelectStandard
                            className='cursor-pointer'
                            value={selectedLibraryId}
                            options={options}
                            onChange={(newValue) =>
                                setSelectedLibraryId(newValue as string)
                            }
                            name='library'
                        />
                    </div>

                    <div className='px-4 py-2 pb-4'>
                        <span className='relative w-full'>
                            <HiMiniMagnifyingGlass className='absolute left-4 top-1/2 size-5 translate-y-[-50%] text-newDesign-text-secondary' />
                            <Input
                                placeholder='Search component'
                                value={searchText}
                                name='filter-search'
                                className='pl-12'
                                onChange={(event) =>
                                    setSearchText(event.target.value)
                                }
                            />
                            <IoIosClose
                                role='button'
                                tabIndex={0}
                                className={cn(
                                    'absolute right-2 top-1/2 aspect-square size-7 translate-y-[-50%] cursor-pointer rounded-md text-newDesign-text-primary hover:bg-newDesign-text-hover',
                                    {
                                        'pointer-events-none opacity-0':
                                            searchText === '',
                                    },
                                )}
                                onClick={() => setSearchText('')}
                            />
                        </span>
                    </div>

                    <div className='flex flex-col justify-start'>
                        {newSections.map((section) => (
                            <PanelComponentSection
                                modelInfo={modelInfo}
                                key={section.type}
                                section={section}
                                search={searchText}
                                expanded={expanded}
                                onClick={handleExpand}
                            />
                        ))}
                    </div>
                </div>
            </SheetContent>
        </Sheet>
    );
};

export default LibraryDrawer;
