import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ServiceConnectionTimeseries } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { createDeleteParams } from '../../adapters.ts';

type DeleteServiceResourceDeploymentParams = {
    modelId: string;
    type: 'model' | 'library';
    data: ServiceConnectionTimeseries | ServiceConnectionTimeseries[];
};

const keyMap = {
    svcname: 'serviceName',
    appname: 'applicationName',
    location: 'location',
    delay: 'delay',
};

const deleteServiceResourceDeployment = async ({
    modelId,
    type,
    data,
}: DeleteServiceResourceDeploymentParams): Promise<void> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/service_connection_timeseries`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const dataArray = Array.isArray(data) ? data : [data];

    const payload = createDeleteParams<ServiceConnectionTimeseries>(
        keyMap,
        dataArray,
    );

    const config: AxiosRequestConfig = {
        headers,
        data: payload,
    };

    await AxiosAPIv2.axiosInstance.delete(url, config);
};

type UseDeleteServiceResourceDeploymentOptions = {
    mutationConfig?: MutationConfig<typeof deleteServiceResourceDeployment>;
};

export const useDeleteServiceResourceDeployment = ({
    mutationConfig,
}: UseDeleteServiceResourceDeploymentOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: deleteServiceResourceDeployment,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
