import React from 'react';
import { CiViewList } from 'react-icons/ci';
import { HiOutlinePuzzlePiece } from 'react-icons/hi2';
import { LuLibrary } from 'react-icons/lu';
import { PiUserList } from 'react-icons/pi';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';

import LibraryManagement from '@bae/modules/library-management';
import { ScenarioSelect } from '@bae/modules/scenarios';
import { RouteMap } from '@bae/routes';
import { AlertBoxRedux, Theme } from '@bae/ui';

import PrivateRoute from '@/app/PrivateRoute.tsx';
import {
    PUBLIC_ROUTES,
    RouteInterface,
    COMMON_ROUTES,
} from '@/app/shared-routes.ts';
import SideBar from '@/layout/SideBar.tsx';
import TemplateCase from '@/libs/modules/templates/TemplateCase.tsx';
import Templates from '@/libs/modules/templates/Templates.tsx';

import LibraryDesigner from './pages/LibraryDesigner/LibraryDesigner.tsx';

const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
        <ThemeProvider theme={Theme}>
            <AlertBoxRedux />
            {children}
        </ThemeProvider>
    );
};

const SidebarLayout = ({ children }) => {
    const location = useLocation();
    const isShared = location.pathname.split('/')[1] === 'shared';

    return (
        <div className='flex h-dvh max-h-screen'>
            {!isShared && (
                <SideBar logoNavigateTo={RouteMap.scenarios.path}>
                    <SideBar.Link
                        to={RouteMap.scenarios.path}
                        text={RouteMap.scenarios.label}
                        icon={<CiViewList className='size-6' />}
                    />
                    <SideBar.Link
                        to={RouteMap.libraryManagement.path}
                        text={RouteMap.libraryManagement.label}
                        icon={<LuLibrary className='size-6' />}
                    />
                    <SideBar.Link
                        to={RouteMap.templates.path}
                        text={RouteMap.templates.label}
                        icon={<HiOutlinePuzzlePiece className='size-6' />}
                    />
                    <SideBar.Link
                        to={RouteMap.memberships.path}
                        text={RouteMap.memberships.label}
                        icon={<PiUserList className='size-6' />}
                    />
                </SideBar>
            )}
            <main
                className='flex w-full flex-1 overflow-auto p-6 pb-1'
                style={{
                    scrollbarGutter: 'stable',
                }}>
                {children}
            </main>
        </div>
    );
};

const STANDARD_BAE_ROUTES: Array<RouteInterface> = [
    {
        id: 'path:/scenarios',
        path: '/scenarios',
        exact: true,
        component: ScenarioSelect,
    },
    {
        id: 'path:/library-management',
        path: RouteMap.libraryManagement.path,
        exact: true,
        component: LibraryManagement,
    },
    {
        id: 'path:/templates',
        path: RouteMap.templates.path,
        exact: true,
        component: Templates,
    },
    {
        id: 'path:/templates/:useCase',
        path: RouteMap.templateCase.path,
        exact: true,
        component: TemplateCase,
    },
    {
        id: 'path:/library-designer/:libraryId',
        path: RouteMap.libraryDesigner.path,
        component: LibraryDesigner,
    },
];

export const BAERouter = () => {
    return (
        <ThemeWrapper>
            <SidebarLayout>
                <Switch>
                    {PUBLIC_ROUTES.map((route) => (
                        <Route
                            key={route.id}
                            path={route.path}
                            component={route.component}
                        />
                    ))}

                    {COMMON_ROUTES.map((route) => (
                        <PrivateRoute
                            key={route.id}
                            path={route.path}
                            component={route.component}
                            exact={route.exact}
                        />
                    ))}

                    {STANDARD_BAE_ROUTES.map((route) => (
                        <PrivateRoute
                            key={route.id}
                            path={route.path}
                            component={route.component}
                            exact={route.exact}
                        />
                    ))}

                    <PrivateRoute
                        path='*'
                        component={() => <Redirect to='/scenarios' />}
                    />
                </Switch>
            </SidebarLayout>
        </ThemeWrapper>
    );
};
