import React from 'react';
import {
    Column,
    DataSheetGrid,
    floatColumn,
    intColumn,
    keyColumn,
    percentColumn,
    textColumn,
} from 'react-datasheet-grid';

import MemoizedSelectorComponent from '../components/MemoizedSelectorComponent.tsx';
import {
    COUNTRY_OPTIONS,
    PORT_TYPE_OPTIONS,
    VPN_TYPE_OPTIONS,
} from '../constants.ts';
import { selectNetworkSiteInput, useNetworkStore } from '../store.ts';
import type { INetworkSiteInput } from '../types.ts';

const columns: Column<INetworkSiteInput>[] = [
    {
        ...keyColumn<INetworkSiteInput, 'siteName'>('siteName', textColumn),
        title: 'Site Name',
        minWidth: 200,
    },
    {
        ...keyColumn<INetworkSiteInput, 'portType'>('portType', textColumn),
        title: 'Port Type',
        minWidth: 120,
        disableKeys: true,
        component: (props) => (
            <MemoizedSelectorComponent<INetworkSiteInput>
                {...props}
                columnKey='portType'
                options={
                    PORT_TYPE_OPTIONS as unknown as Array<{
                        value: string;
                        label: string;
                    }>
                }
            />
        ),
    },
    {
        ...keyColumn<INetworkSiteInput, 'quantity'>('quantity', intColumn),
        title: 'Quantity',
        minWidth: 120,
    },
    {
        ...keyColumn<INetworkSiteInput, 'redundantAccess'>(
            'redundantAccess',
            textColumn,
        ),
        title: 'Redundant Access, CPE & Ports',
        minWidth: 160,
        component: (props) => (
            <MemoizedSelectorComponent<INetworkSiteInput>
                {...props}
                columnKey='redundantAccess'
                options={[
                    { value: 'true', label: 'TRUE' },
                    { value: 'false', label: 'FALSE' },
                ]}
            />
        ),
    },
    {
        ...keyColumn<INetworkSiteInput, 'country'>('country', textColumn),
        title: 'Country',
        minWidth: 200,
        component: (props) => (
            <MemoizedSelectorComponent<INetworkSiteInput>
                {...props}
                columnKey='country'
                options={
                    COUNTRY_OPTIONS as unknown as Array<{
                        value: string;
                        label: string;
                    }>
                }
            />
        ),
    },
    {
        ...keyColumn<INetworkSiteInput, 'accessCircuitMRC'>(
            'accessCircuitMRC',
            floatColumn,
        ),
        title: 'Access Circuit MRC ($)',
        minWidth: 160,
    },
    {
        ...keyColumn<INetworkSiteInput, 'vpnType'>('vpnType', textColumn),
        title: 'VPN Type',
        minWidth: 120,
        component: (props) => (
            <MemoizedSelectorComponent<INetworkSiteInput>
                {...props}
                columnKey='vpnType'
                options={
                    VPN_TYPE_OPTIONS as unknown as Array<{
                        value: string;
                        label: string;
                    }>
                }
            />
        ),
    },
    {
        ...keyColumn<INetworkSiteInput, 'vpnBWMbs'>('vpnBWMbs', floatColumn),
        title: 'VPN BW (Mbs)',
        minWidth: 120,
    },
    {
        ...keyColumn<INetworkSiteInput, 'vpnBWAnnualGrowthRate'>(
            'vpnBWAnnualGrowthRate',
            percentColumn,
        ),
        title: 'VPN BW Annual Growth Rate',
        minWidth: 160,
    },
    {
        ...keyColumn<INetworkSiteInput, 'internetBWMbps'>(
            'internetBWMbps',
            floatColumn,
        ),
        title: 'Internet BW (Mbps)',
        minWidth: 140,
    },
    {
        ...keyColumn<INetworkSiteInput, 'internetBWAnnualGrowthRate'>(
            'internetBWAnnualGrowthRate',
            percentColumn,
        ),
        title: 'Internet BW Annual Growth Rate',
        minWidth: 180,
    },
    {
        ...keyColumn<INetworkSiteInput, 'elanBWMbps'>(
            'elanBWMbps',
            floatColumn,
        ),
        title: 'ELAN BW (Mbps)',
        minWidth: 130,
    },
    {
        ...keyColumn<INetworkSiteInput, 'elanBWAnnualGrowthRate'>(
            'elanBWAnnualGrowthRate',
            percentColumn,
        ),
        title: 'ELAN BW Annual Growth Rate',
        minWidth: 160,
    },
    {
        ...keyColumn<INetworkSiteInput, 'cpeExpense'>(
            'cpeExpense',
            floatColumn,
        ),
        title: 'CPE Expense ($)',
        minWidth: 160,
    },
];

const createEmptyNewRows = (numberOfRows: number): INetworkSiteInput[] => {
    const arr: INetworkSiteInput[] = [];

    for (let i = 0; i < numberOfRows; i++) {
        arr.push({
            id: '',
            siteName: null,
            quantity: null,
            portType: null,
            redundantAccess: null,
            country: null,
            accessCircuitMRC: null,
            vpnType: null,
            vpnBWMbs: null,
            vpnBWAnnualGrowthRate: null,
            internetBWMbps: null,
            internetBWAnnualGrowthRate: null,
            elanBWMbps: null,
            elanBWAnnualGrowthRate: null,
            cpeExpense: null,
        });
    }
    return arr;
};

const NetworkInputDataGrid = () => {
    const setData = useNetworkStore((state) => state.setNetworkSiteInput);
    const data = useNetworkStore(selectNetworkSiteInput);

    return (
        <DataSheetGrid
            headerRowHeight={50}
            createRow={() => createEmptyNewRows(1)[0]}
            value={data}
            columns={columns}
            onChange={setData}
            rowKey='id'
        />
    );
};

export default NetworkInputDataGrid;
