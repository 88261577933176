import { useCurrency } from '@bae/store';
import { formatCurrencySymbol } from '@bae/utils';

import { Skeleton } from '@/components/ui/skeleton.tsx';

import { TableBodyRow, TableHeadRow } from '../../shared/TableComponents';
import {
    TextInputCell,
    FloatNumberInputCell,
    NumberInputCell,
} from '../../shared/TableComponents/CellComponents';
import { EgressExpense } from '../../shared/types.ts';
import {
    getCloudEgressExpenses,
    useVirtualMachineComparisonStore,
} from '../store';

const getExpenseEgressLabels = (currencySign: string = '$') => ({
    expenseName: { label: 'Cloud Egress Expense', isEditable: false },
    size: { label: 'Max GB Per Month', isEditable: true },
    value: { label: `Cost Per GB (${currencySign})`, isEditable: true },
});

const CloudEgressExpenses = () => {
    const { currency } = useCurrency();
    const cloudEgressExpenses = useVirtualMachineComparisonStore(
        getCloudEgressExpenses,
    );
    const updateCloudEgressExpenses = useVirtualMachineComparisonStore(
        (state) => state.updateEgressExpense,
    );
    const loading = cloudEgressExpenses.length === 0;

    const currencySign = formatCurrencySymbol({
        currency,
        onlySymbol: true,
    });
    const expenseEgressLabels = getExpenseEgressLabels(currencySign);

    return (
        <div className='w-full overflow-x-auto'>
            <table className='w-full table-fixed border-separate border-spacing-2 text-sm'>
                <thead>
                    {loading ? (
                        <SkeletonHeader />
                    ) : (
                        <TableHeadRow>
                            {Object.keys(expenseEgressLabels).map((key) => (
                                <th key={key} className='text-center'>
                                    <p className='whitespace-nowrap'>
                                        {
                                            expenseEgressLabels[
                                                key as keyof EgressExpense
                                            ].label
                                        }
                                    </p>
                                </th>
                            ))}
                        </TableHeadRow>
                    )}
                </thead>
                <tbody>
                    {loading ? (
                        <SkeletonRow />
                    ) : (
                        cloudEgressExpenses.map((expense, index) => {
                            return (
                                <TableBodyRow key={index}>
                                    <TextInputCell<EgressExpense>
                                        value={expense.expenseName}
                                        onChange={updateCloudEgressExpenses}
                                        accessorKey={'expenseName'}
                                        index={index}
                                        isEditable={false}
                                        className='min-w-[200px]'
                                    />
                                    <NumberInputCell
                                        value={expense.size}
                                        onChange={updateCloudEgressExpenses}
                                        accessorKey={'size'}
                                        index={index}
                                    />
                                    <FloatNumberInputCell<EgressExpense>
                                        value={expense.value}
                                        onChange={updateCloudEgressExpenses}
                                        accessorKey={'value'}
                                        index={index}
                                    />
                                </TableBodyRow>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
};

const SkeletonHeader = () => {
    return (
        <TableHeadRow>
            <th>Cloud Egress Expense</th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
        </TableHeadRow>
    );
};

const SkeletonRow = () => {
    const arr = Array.from({ length: 4 }, (_, i) => i);
    return (
        <TableBodyRow>
            <td>
                <Skeleton />
            </td>
            {arr.map((col) => (
                <td key={col}>
                    <Skeleton />
                </td>
            ))}
        </TableBodyRow>
    );
};

export default CloudEgressExpenses;
