import React from 'react';
import {
    Column,
    DataSheetGrid,
    floatColumn,
    keyColumn,
    percentColumn,
    textColumn,
} from 'react-datasheet-grid';

import MemoizedSelectorComponent from '../components/MemoizedSelectorComponent.tsx';
import {
    COUNTRY_OPTIONS,
    GCP_REGION_OPTIONS,
    GF_SERVICE_INTERCONNECTION_OPTIONS,
    PORT_TYPE_OPTIONS,
} from '../constants.ts';
import { selectGcpCloudPortInput, useNetworkStore } from '../store.ts';
import type { IGcpCloudPortInput } from '../types.ts';

const columns: Column<IGcpCloudPortInput>[] = [
    {
        ...keyColumn<IGcpCloudPortInput, 'siteName'>('siteName', textColumn),
        title: 'Site Name',
        minWidth: 200,
    },
    {
        ...keyColumn<IGcpCloudPortInput, 'dataEgressGBPerMonth'>(
            'dataEgressGBPerMonth',
            floatColumn,
        ),
        title: 'Data Egress (GB/Month)',
        minWidth: 120,
    },
    {
        ...keyColumn<IGcpCloudPortInput, 'egressGrowthRate'>(
            'egressGrowthRate',
            percentColumn,
        ),
        title: 'Egress Growth Rate',
        minWidth: 160,
    },
    {
        ...keyColumn<IGcpCloudPortInput, 'portType'>('portType', textColumn),
        title: 'Port Type',
        minWidth: 120,
        disableKeys: true,
        component: (props) => (
            <MemoizedSelectorComponent<IGcpCloudPortInput>
                {...props}
                columnKey='portType'
                options={
                    PORT_TYPE_OPTIONS as unknown as Array<{
                        value: string;
                        label: string;
                    }>
                }
            />
        ),
    },
    {
        ...keyColumn<IGcpCloudPortInput, 'portRedundant'>(
            'portRedundant',
            textColumn,
        ),
        title: 'Port Redundant',
        minWidth: 160,
        component: (props) => (
            <MemoizedSelectorComponent<IGcpCloudPortInput>
                {...props}
                columnKey='portRedundant'
                options={[
                    { value: 'true', label: 'TRUE' },
                    { value: 'false', label: 'FALSE' },
                ]}
            />
        ),
    },
    {
        ...keyColumn<IGcpCloudPortInput, 'country'>('country', textColumn),
        title: 'Country',
        minWidth: 200,
        component: (props) => (
            <MemoizedSelectorComponent<IGcpCloudPortInput>
                {...props}
                columnKey='country'
                options={
                    COUNTRY_OPTIONS as unknown as Array<{
                        value: string;
                        label: string;
                    }>
                }
            />
        ),
    },
    {
        ...keyColumn<IGcpCloudPortInput, 'egressRegion'>(
            'egressRegion',
            textColumn,
        ),
        title: 'Egress Region',
        minWidth: 200,
        component: (props) => (
            <MemoizedSelectorComponent<IGcpCloudPortInput>
                {...props}
                columnKey='egressRegion'
                options={
                    GCP_REGION_OPTIONS as unknown as Array<{
                        value: string;
                        label: string;
                    }>
                }
            />
        ),
    },
    {
        ...keyColumn<IGcpCloudPortInput, 'gfServiceInterconnection'>(
            'gfServiceInterconnection',
            textColumn,
        ),
        title: 'GF Service Interconnection',
        minWidth: 120,
        component: (props) => (
            <MemoizedSelectorComponent<IGcpCloudPortInput>
                {...props}
                columnKey='gfServiceInterconnection'
                options={
                    GF_SERVICE_INTERCONNECTION_OPTIONS as unknown as Array<{
                        value: string;
                        label: string;
                    }>
                }
            />
        ),
    },
];

const createEmptyNewRows = (numberOfRows: number): IGcpCloudPortInput[] => {
    const arr: IGcpCloudPortInput[] = [];

    for (let i = 0; i < numberOfRows; i++) {
        arr.push({
            id: '',
            siteName: null,
            portType: null,
            portRedundant: null,
            country: null,
            egressRegion: null,
            gfServiceInterconnection: null,
        });
    }
    return arr;
};

const GcpCloudPortInputDataGrid = () => {
    const setData = useNetworkStore((state) => state.setGcpCloudPortInput);
    const data = useNetworkStore(selectGcpCloudPortInput);

    return (
        <DataSheetGrid
            headerRowHeight={50}
            createRow={() => createEmptyNewRows(1)[0]}
            value={data}
            columns={columns}
            onChange={setData}
            rowKey='id'
        />
    );
};

export default GcpCloudPortInputDataGrid;
