import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import {
    adaptDiff,
    AxiosAPIv2,
    getToken,
    InputDiffApiResponse,
} from '@bae/api';
import { InputDiff } from '@bae/data-interface';

interface ModelsDiff {
    baseModelId: string;
    compareModelId: string;
}

const getModelsDiff = async ({
    baseModelId,
    compareModelId,
}: ModelsDiff): Promise<InputDiff> => {
    const response = await AxiosAPIv2.axiosInstance.get<InputDiffApiResponse>(
        `inputdiff/${baseModelId}/${compareModelId}`,
        { headers: { Authorization: 'Bearer ' + getToken() } },
    );

    return adaptDiff(response.data);
};

export const getModelsDiffQueryOptions = ({
    baseModelId,
    compareModelId,
}: ModelsDiff) => {
    return queryOptions({
        queryKey: ['inputdiff', baseModelId, compareModelId],
        queryFn: () =>
            getModelsDiff({
                baseModelId,
                compareModelId,
            }),
    });
};

type UseModelsDiffOptions = {
    baseModelId: string;
    compareModelId: string;
    queryConfig?: QueryConfig<typeof getModelsDiffQueryOptions>;
    refetchInterval?: number | false;
    enabled?: boolean;
    select?: (data: InputDiff) => unknown;
};

export const useModelsDiff = ({
    queryConfig,
    refetchInterval,
    baseModelId,
    compareModelId,
    enabled = true,
    select,
}: UseModelsDiffOptions) => {
    return useQuery({
        ...getModelsDiffQueryOptions({
            baseModelId,
            compareModelId,
        }),
        ...queryConfig,
        refetchInterval,
        enabled,
        select,
    });
};
