import { CpeCashflowInput, CpeConfigInput } from '@bae/api';
import { CpeCashflow, CpeSpecs } from '@bae/data-interface';

export const getDefaultSpecs = (cpeName: string): CpeSpecs => ({
    cpeName,
    decommissionTime: 0,
    description: '',
});

export const cpeAdapter = (data: CpeSpecs): CpeConfigInput => {
    return {
        name: data.cpeName,
        decommission_time: data.decommissionTime,
        description: data.description,
    };
};

export const cpeCashFlowAdapter = (data: CpeCashflow): CpeCashflowInput => {
    return {
        cpename: data.cpeName,
        flowname: data.flowName,
        recurring: data.recurring,
        flowtype: data.flowType,
        value_per_unit: data.valuePerUnit,
        value_cagr: data.valueGrowth,
        discount: data.discount,
        active: true,
    };
};

export const createCloneQueryParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
) =>
    data.map((datum) => {
        const datumParams = {
            source: {},
            target: {},
        };

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams.source[apiKey] = datum[moduleKey];
            datumParams.target[apiKey] = `Copy of ${datum[moduleKey]}`;
        });

        return datumParams;
    });

export const createDeleteParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
): Record<string, string>[] => {
    return data.map((datum) => {
        const datumParams: Record<string, string> = {};

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams[apiKey] = datum[moduleKey];
        });

        return datumParams;
    });
};
