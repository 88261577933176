import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ResourceSpecs } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { createCloneQueryParams, getResourceDefaultSpecs } from './adapters.ts';

type CloneResourceParams = {
    modelId: string;
    type: 'model' | 'library';
    names: string | string[];
};

const keyMap = {
    name: 'applicationName',
};

const cloneResource = async ({
    modelId,
    type,
    names,
}: CloneResourceParams): Promise<void> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/copy/application`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const dataArray = Array.isArray(names) ? names : [names];

    const mappedData = dataArray.map(getResourceDefaultSpecs);

    const payload = createCloneQueryParams<ResourceSpecs>(keyMap, mappedData);

    await AxiosAPIv2.axiosInstance.post(url, payload, { headers });
};

type UseCloneResourceOptions = {
    mutationConfig?: MutationConfig<typeof cloneResource>;
};

export const useCloneResource = ({
    mutationConfig,
}: UseCloneResourceOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: cloneResource,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
