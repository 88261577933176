import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

interface ExportFromModelToLibraryApi {
    type: 'service' | 'application' | 'server' | 'fte' | 'cpe';
    name: string;
    libraryId: string;
    modelId: string;
}

const exportFromModelToLibrary = ({
    type: Type,
    name,
    libraryId,
    modelId,
}: ExportFromModelToLibraryApi): Promise<void> => {
    const mapTypeToValidInput = (type: typeof Type) => {
        switch (type) {
            case 'service':
                return 'servicespecs';
            case 'application':
                return 'appspecs';
            case 'server':
                return 'servers';
            case 'fte':
                return 'fte';
            case 'cpe':
                return 'cpeconfig';
            default:
                return 'servicespecs';
        }
    };

    return AxiosAPIv2.axiosInstance.post(
        `/libraries/${libraryId}/inputs/${mapTypeToValidInput(Type)}`,
        {
            name,
            model_id: modelId,
        },
        { headers: { Authorization: 'Bearer ' + getToken() } },
    );
};

type UseExportToLibraryOptions = {
    mutationConfig?: MutationConfig<typeof exportFromModelToLibrary>;
};

export const useExportToLibrary = ({
    mutationConfig,
}: UseExportToLibraryOptions) => {
    return useMutation({
        ...mutationConfig,
        mutationFn: exportFromModelToLibrary,
    });
};
