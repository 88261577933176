import { Skeleton } from '@/components/ui/skeleton.tsx';

import { TableBodyRow, TableHeadRow } from '../../shared/TableComponents';
import {
    NumberInputCell,
    TextInputCell,
} from '../../shared/TableComponents/CellComponents';
import { Server } from '../../shared/types.ts';
import { getColoServers, useCloudRebalancingStore } from '../store';

const coloServerLabels: Record<
    keyof Server,
    { label: string; isEditable: boolean }
> = {
    serverName: { label: 'Server Name', isEditable: false },
    vcpu: { label: 'vCPU', isEditable: true },
    memory: { label: 'Memory', isEditable: true },
};

const ColoServers = () => {
    const coloServers = useCloudRebalancingStore(getColoServers);
    const updateColoServer = useCloudRebalancingStore(
        (state) => state.updateColoServer,
    );
    const loading = coloServers.length === 0;

    return (
        <div className='w-full overflow-x-auto'>
            <table className='w-full table-fixed border-separate border-spacing-2 text-sm'>
                <thead>
                    {loading ? (
                        <SkeletonHeader />
                    ) : (
                        <TableHeadRow>
                            {Object.keys(coloServerLabels).map((key) => (
                                <th key={key} className='text-center'>
                                    <p className='whitespace-nowrap'>
                                        {
                                            coloServerLabels[
                                                key as keyof Server
                                            ].label
                                        }
                                    </p>
                                </th>
                            ))}
                        </TableHeadRow>
                    )}
                </thead>
                <tbody>
                    {loading ? (
                        <SkeletonRow />
                    ) : (
                        coloServers.map((server, index) => (
                            <TableBodyRow key={index}>
                                <TextInputCell<Server>
                                    value={server.serverName}
                                    onChange={updateColoServer}
                                    accessorKey={'serverName'}
                                    index={index}
                                    isEditable={false}
                                    className='min-w-[200px]'
                                />
                                <NumberInputCell<Server>
                                    value={server.vcpu}
                                    index={index}
                                    accessorKey={'vcpu'}
                                    onChange={updateColoServer}
                                />
                                <NumberInputCell<Server>
                                    value={server.memory}
                                    index={index}
                                    accessorKey={'memory'}
                                    onChange={updateColoServer}
                                />
                            </TableBodyRow>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

const SkeletonHeader = () => {
    return (
        <TableHeadRow>
            <th>Server Name</th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
        </TableHeadRow>
    );
};

const SkeletonRow = () => {
    const arr = Array.from({ length: 4 }, (_, i) => i);
    return (
        <TableBodyRow>
            <td>
                <Skeleton />
            </td>
            {arr.map((col) => (
                <td key={col}>
                    <Skeleton />
                </td>
            ))}
        </TableBodyRow>
    );
};

export default ColoServers;
