import { RouteProps } from 'react-router-dom';

import { RouteMap } from '@bae/routes';

import AccountSettings from './shared-pages/AccountSettings/AccountSettings.tsx';
import Comparison from './shared-pages/Comparison/Comparison.tsx';
import MembershipManagement from './shared-pages/MembershipManagement/MembershipManagement.tsx';
import NotAuthorizedPage from './shared-pages/NotAuthorizedPage';
import Scenario from './shared-pages/Scenario/Scenario.tsx';
import Shared from './shared-pages/Shared/Shared.tsx';

export interface RouteInterface extends RouteProps {
    id: string;
    disabled?: boolean;
}

export const PUBLIC_ROUTES: Array<RouteInterface> = [
    { id: 'path:/callback', path: RouteMap.callback.path, component: null },
    {
        id: 'path:/not-authorized',
        path: RouteMap.notAuthorized.path,
        component: NotAuthorizedPage,
    },
    { id: 'path:/login', path: RouteMap.login.path, component: null },
    {
        id: 'gf-path:/shared/:sharedId',
        path: RouteMap.shared.path,
        component: Shared,
    },
];

export const COMMON_ROUTES: Array<RouteInterface> = [
    {
        id: 'gf-path:/account',
        path: RouteMap.accountSettings.path,
        exact: true,
        component: AccountSettings,
    },
    {
        id: 'gf-path:/memberships',
        path: RouteMap.memberships.path,
        exact: true,
        component: MembershipManagement,
    },
    {
        id: 'gf-path:/scenarios/:modelId',
        path: RouteMap.scenario.path,
        component: Scenario,
    },
    {
        id: 'path:/comparison',
        path: RouteMap.comparison.path,
        component: Comparison,
    },
];
