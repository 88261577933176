import {
    AppAvailabilityInput,
    AppCashFlowsConfigInput,
    AppModelsInput,
    AppSpecsInput,
} from '@bae/api';
import {
    ResourceAvailability,
    ResourceCashflow,
    ResourceModel,
    ResourceSpecs,
} from '@bae/data-interface';

export const getResourceDefaultSpecs = (
    applicationName: string,
): ResourceSpecs => ({
    applicationName,
    expirationTime: 0,
    datavolumeUtilization: 1,
    datarateUtilization: 1,
    usersUtilization: 1,
    sessionsUtilization: 1,
    tenantsUtilization: 1,
    endpointsUtilization: 1,
    description: '',
});

export const getResourceStandardModel = (
    applicationName: string,
): ResourceModel => ({
    applicationName,
    name: 'Standard',
    vcpu: 0,
    memory: 0,
    disk: 0,
    kw: 0,
    ru: 0,
    datavolume: null,
    datarate: null,
    users: null,
    sessions: null,
    tenants: null,
    endpoints: null,
});

export const resourceAdapter = (data: ResourceSpecs): AppSpecsInput => {
    return {
        name: data.applicationName,
        expirationtime: data.expirationTime,
        datavolume_utilization: data.datavolumeUtilization,
        datarate_utilization: data.datarateUtilization,
        users_utilization: data.usersUtilization,
        sessions_utilization: data.sessionsUtilization,
        tenants_utilization: data.tenantsUtilization,
        endpoints_utilization: data.endpointsUtilization,
        description: data.description,
    };
};

export const resourceModelAdapter = (data: ResourceModel): AppModelsInput => {
    return {
        appname: data.applicationName,
        appmodel: data.name,
        vcpu: data.vcpu,
        memory: data.memory,
        disk: data.disk,
        kw: data.kw,
        ru: data.ru,
        datavolume: data.datavolume,
        datarate: data.datarate,
        users: data.users,
        sessions: data.sessions,
        tenants: data.tenants,
        endpoints: data.endpoints,
    };
};

export const resourceCashFlowAdapter = (
    data: ResourceCashflow,
): AppCashFlowsConfigInput => {
    return {
        appname: data.applicationName,
        appmodel: data.applicationModel,
        flowname: data.flowName,
        recurring: data.recurring,
        flowtype: data.flowType,
        value: data.value,
        per: data.driver,
        value_cagr: data.valueGrowth,
        discount: data.discount,
        active: true,
    };
};

export const resourceAvailabilityAdapter = (
    data: ResourceAvailability,
): AppAvailabilityInput => {
    return {
        dcname: data.datacenter,
        appname: data.applicationName,
    };
};

export const createDeleteParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
): Record<string, string>[] => {
    return data.map((datum) => {
        const datumParams: Record<string, string> = {};

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams[apiKey] = datum[moduleKey];
        });

        return datumParams;
    });
};

export const createCloneQueryParams = <ModuleDataType>(
    keyMap: Record<string, string>,
    data: ModuleDataType[],
) =>
    data.map((datum) => {
        const datumParams = {
            source: {},
            target: {},
        };

        Object.entries(keyMap).forEach(([apiKey, moduleKey]) => {
            datumParams.source[apiKey] = datum[moduleKey];
            datumParams.target[apiKey] = `Copy of ${datum[moduleKey]}`;
        });

        return datumParams;
    });
