import {
    IFinancialIndicatorsColumn,
    IndicatorsTableData,
} from '@/libs/components/src/lib/FinancialIndicators/types.ts';

const TCO_MODEL_COLUMNS: IFinancialIndicatorsColumn[] = [
    { column: 'capex', label: 'CapEx', caption: 'savings' },
    { column: 'opex', label: 'OpEx', caption: 'savings' },
    {
        column: 'tco',
        label: 'TCO',
        caption: 'savings',
    },
];

const PERIOD_INDICATORS_COLUMNS: IFinancialIndicatorsColumn[] = [
    { column: 'cashflow', label: 'Cash Flow', caption: 'improvement' },
    {
        column: 'revenue',
        label: 'Revenue',
        caption: 'improvement',
    },
    ...TCO_MODEL_COLUMNS,
];

function GET_FULL_TABLE_COLUMNS(
    comparisonMode: boolean,
): IFinancialIndicatorsColumn[] {
    return [
        { column: 'roi', label: 'ROI', caption: 'improvement' },
        { column: 'irr', label: 'IRR', caption: 'improvement' },
        { column: 'npv', label: 'NPV', caption: 'improvement' },
        {
            column: 'payback',
            label: 'Payback',
            caption: comparisonMode ? 'less months' : 'months',
        },
        ...PERIOD_INDICATORS_COLUMNS,
        { column: 'marginAvg', label: 'Avg. Margin', caption: 'improvement' },
    ];
}

export function getColumns(
    showFullTable: boolean,
    modelHasRevenue: boolean,
    comparisonMode: boolean,
    data: IndicatorsTableData,
) {
    const columnsWithoutNulls = data.map((model) => {
        const modelEntries = Object.entries(model);
        return modelEntries
            .filter(
                ([key, value]) =>
                    value !== null &&
                    key !== 'name' &&
                    key !== 'id' &&
                    key !== 'loading',
            )
            .map(([key, value]) => key);
    });

    if (modelHasRevenue && showFullTable)
        return GET_FULL_TABLE_COLUMNS(comparisonMode).filter((col) => {
            return columnsWithoutNulls.some((cols) =>
                cols.includes(col.column),
            );
        });

    if (modelHasRevenue)
        return PERIOD_INDICATORS_COLUMNS.filter((col) => {
            return columnsWithoutNulls.some((cols) =>
                cols.includes(col.column),
            );
        });

    return TCO_MODEL_COLUMNS;
}
