import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

const updateModel = async ({
    modelId,
}: {
    modelId: string;
}): Promise<string> => {
    const response = await AxiosAPIv2.axiosInstance.post<{ model_id: string }>(
        `/models/${modelId}/inputs`,
        null,
        { headers: { Authorization: 'Bearer ' + getToken() } },
    );

    return response.data.model_id;
};

type UseUpdateModelOptions = {
    mutationConfig?: MutationConfig<typeof updateModel>;
};

export const useUpdateModel = ({ mutationConfig }: UseUpdateModelOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: async (...args) => {
            await queryClient.invalidateQueries({
                queryKey: ['model_groups'],
            });

            await queryClient.invalidateQueries({
                queryKey: ['models'],
            });
            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: updateModel,
    });
};
