import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { EndpointDeployment } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { endpointTimeseriesAdapter } from '../adapters.ts';

type EditEndpointTimeSeriesParams = {
    modelId: string;
    type: 'model' | 'library';
    prevData: EndpointDeployment;
    nextData: EndpointDeployment;
};

const editEndpointTimeSeries = async ({
    modelId,
    type,
    prevData,
    nextData,
}: EditEndpointTimeSeriesParams): Promise<EndpointDeployment> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/endpoint_timeseries`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const params = {
        tenantname: prevData.tenantName,
        endpointname: prevData.endpointName,
        delay: prevData.delay,
        model_id: modelId,
    };

    const config: AxiosRequestConfig = {
        headers,
        params,
    };

    const payload = endpointTimeseriesAdapter(nextData);

    await AxiosAPIv2.axiosInstance.patch(url, payload, config);

    return nextData;
};

type UseEditEndpointTimeSeriesOptions = {
    mutationConfig?: MutationConfig<typeof editEndpointTimeSeries>;
    invalidate?: boolean;
};

export const useEditEndpointTimeSeries = ({
    mutationConfig,
    invalidate = true,
}: UseEditEndpointTimeSeriesOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: editEndpointTimeSeries,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            if (invalidate) {
                await queryClient.invalidateQueries({
                    queryKey: getQueryKey(modelId, type),
                });
            }

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
