import { AppTypes } from '@/app-config/appTypes.ts';
import { getAppType } from '@/app-config/featureConfig.ts';

import GlobalFabricNetworkConfiguration from './global-fabric/NetworkConfiguration.tsx';
import JuniperMistNetworkConfiguration from './juniper-mist/NetworkConfiguration.tsx';

function getNetworkConfiguration() {
    const appType = getAppType();

    if (appType === AppTypes.MIST) {
        return JuniperMistNetworkConfiguration;
    }

    return GlobalFabricNetworkConfiguration;
}

const NetworkConfiguration = getNetworkConfiguration();

export { NetworkConfiguration };
