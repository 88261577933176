export type IScenarioData = {
    id: string;
    name: string;
    description: string;
    createdAt: string;
    lastUpdate: string;
    lastUpdateBy: string;
};

export const formatDate = (epochDate: number) => {
    return new Date(epochDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
};

export const scenarioData: IScenarioData[] = [
    {
        // id: 'f2d1e51e-c1c2-4f20-958a-37b09c20a72c', // unstable
        id: '108d691c-56c2-4306-a997-199deb8700cb', // bae-mist stable
        name: 'What-If A',
        description: 'This is scenario A',
        createdAt: '05/16/2024',
        lastUpdate: formatDate(Date.now()),
        lastUpdateBy: 'pfetterolf@acgcc.com ',
    },
    {
        // id: '6c609b4f-83bb-406f-87ee-b8b7996733fd', // unstable
        id: '6f8d705e-d418-44be-888c-52f0c1845319', // bae-mist stable
        name: 'What-If B',
        description: 'This is scenario B',
        createdAt: '05/16/2024',
        lastUpdate: '05/16/2024',
        lastUpdateBy: 'pfetterolf@acgcc.com ',
    },
];
