import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ServiceConnection } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import {
    getResourceZeroTimeseries,
    serviceResourceAdapter,
} from '../adapters.ts';
import { useAddServiceResourceDeployment } from './delayed-deployment/add-resource-deployment.ts';

type AddServiceResourceParams = {
    modelId: string;
    type: 'model' | 'library';
    data: ServiceConnection;
};

const addServiceResource = async ({
    modelId,
    type,
    data,
}: AddServiceResourceParams): Promise<ServiceConnection> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/service_connections`;

    const payload = serviceResourceAdapter(data);

    await AxiosAPIv2.axiosInstance.post(url, payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return data;
};

type UseAddServiceResourceOptions = {
    mutationConfig?: MutationConfig<typeof addServiceResource>;
};

export const useAddServiceResource = ({
    mutationConfig,
}: UseAddServiceResourceOptions = {}) => {
    const queryClient = useQueryClient();

    const addServiceResourceDeploymentQuery = useAddServiceResourceDeployment({
        invalidate: false,
    });

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        mutationFn: addServiceResource,
        onSuccess: async (data, variables, context) => {
            const { modelId, type } = variables;

            await addServiceResourceDeploymentQuery.mutateAsync({
                modelId,
                type,
                data: getResourceZeroTimeseries(data),
            });

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
